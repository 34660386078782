import { useState, useEffect, useCallback, useRef } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { firebase } from '../func/firebase'
import ReCAPTCHA from 'react-google-recaptcha'

export default function TestRecaptcha() {
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    const captchaRef = useRef()
    const test = (recToken) => {
        const functions = firebase.functions('asia-northeast2')
        const cfFunc = functions.httpsCallable('CheckRecaptcha')
        const sendData = { token: recToken }
        if (recToken) {
            cfFunc(sendData)
                .then((body) => {
                    console.log('res body:', body.data)
                    return body.data
                })
                .then((data) => {
                    const result = data.result
                    // console.log(result)
                    if (result.success) {
                        console.log('reCaptcha API Success')
                        window.alert('reCaptcha API Success')
                    } else {
                        throw new Error('reCaptcha API Failed')
                    }
                })
                .catch((error) => {
                    console.error('reCaptcha API Error:', error)
                    window.alert('reCaptcha API Error')
                    // console.log(error)
                })
        } else {
            console.log('token is empty')
        }
    }
    return (
        <div>
            <button
                onClick={(event) => {
                    event.preventDefault()
                    console.log('recaptcha api func button clicked')
                    captchaRef.current.execute()
                }}
            >
                Click me
            </button>
            <ReCAPTCHA
                ref={captchaRef}
                sitekey={siteKey}
                onChange={(token) => {
                    test(token)
                }}
                size='invisible'
            />
        </div>
    )
}
//reCaptcha V3ライブラリ使用
// const YourReCaptchaComponent = () => {
//     const { executeRecaptcha } = useGoogleReCaptcha()
//     const [recToken, SetRecToken] = useState('')
//     // Create an event handler so you can call the verification on button click event or form submit
//     const handleReCaptchaVerify = useCallback(async () => {
//         if (!executeRecaptcha) {
//             console.log('Execute recaptcha not yet available')
//             return
//         }
//         const token = await executeRecaptcha()
//         // console.log(token)
//         SetRecToken(token)
//     }, [executeRecaptcha])

//     // You can use useEffect to trigger the verification as soon as the component being loaded
//     useEffect(() => {
//         handleReCaptchaVerify()
//     }, [handleReCaptchaVerify])

//     const test = () => {
//         const functions = firebase.functions('asia-northeast2')
//         const cfFunc = functions.httpsCallable('CheckRecaptcha')
//         const sendData = { token: recToken }
//         if (recToken) {
//             cfFunc(sendData)
//                 .then((body) => {
//                     console.log('res body:', body.data)
//                     console.log('Create Token Success')
//                     return body.data
//                 })
//                 .then((data) => console.log(data))
//                 .catch((error) => {
//                     console.log('Create Token Error:', error)
//                     // console.log(error)
//                 })
//         } else {
//             console.log('token is empty')
//         }
//     }
//     return (
//         <div className='p-8 flex flex-col gap-8 justify-center items-center'>
//             {/* <button type='button' onClick={handleReCaptchaVerify}>
//                 Verify recaptcha
//             </button>
//             <button type='button' onClick={test}>
//                 cf test
//             </button>
//             <div>hoge</div> */}
//         </div>
//     )
// }
