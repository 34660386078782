import { useFormContext } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { BadgeRequire } from '../atom'

export default function SelectBox({ label, name, id, register, options, firstOption, firstValue, isFirst = true, subText, isRequired, errorText }) {
    // const firstFlg = !!firstOption
    // const defaultIndex = options.findIndex((op) => op.includes(defaultValue))
    // const defaultFlg = defaultIndex !== -1
    // console.log({ defaultIndex, defaultFlg }
    const errorFlg = !!errorText
    const subTextFlg = !!subText
    const firstVal = firstValue ? firstValue : ''
    const normalFormStyle = `
        w-full p-2 rounded-lg bg-gray-200 focus:bg-white border border-gray-400
        focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent
        text-xl
    `
    const errorFormStyle = `${normalFormStyle} ring-2 ring-red-500 forcus:none`
    const formStyle = !errorFlg ? normalFormStyle : errorFormStyle
    return (
        <div className='flex flex-col' id={`${id ? id : name}_parent`}>
            <div className='flex flex-row items-end mb-2'>
                <label htmlFor={name} className='text-lg sm:text-2xl text-gray-600'>
                    {label}
                </label>
                {isRequired && <BadgeRequire />}
            </div>
            <div className='mb-4'>
                <select id={id ? id : name} name={name} className={formStyle} {...register}>
                    {isFirst && <option value={firstVal}>{firstOption}</option>}
                    {options.map((value, index) => (
                        <option key={index} value={value.code ? value.code : value}>
                            {value.display ? value.display : value}
                        </option>
                    ))}
                </select>
                {errorFlg && (
                    <span className='flex flex-row items-center justify-end my-2 text-right text-base font-bold text-red-400'>
                        <ExclamationCircleIcon className='w-5 h-5' />
                        <p>{errorText}</p>
                    </span>
                )}
                {subTextFlg && <span className='text-sm leading-none text-gray-700 whitespace-pre-wrap'>{subText}</span>}
            </div>
        </div>
    )
}
