import { useState, useEffect, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { DocumentAddIcon, BookOpenIcon, SearchIcon, QuestionMarkCircleIcon, MailIcon, BellIcon } from '@heroicons/react/outline'
import { useAuthContext } from '../func'
import { firebase, auth, db } from '../func/firebase'
import { eraYear } from '../func/CommonFunc'
import { Button, TextArea } from '../atom'

export default function Home() {
    const { userData, setUserData } = useAuthContext()
    const style = {
        container: 'w-full h-full px-6 pt-2 pb-4 flex flex-col items-center justify-center text-2xl sm:text-3xl',
        navList: 'mt-8 flex flex-col justify-center items-start',
        navItem: 'py-5 flex flex-row justify-center items-center',
        navIcon: 'mr-2 w-8 h-8 sm:w-10 sm:h-10 text-gray-800',
        newsContainer: 'w-full mt-4 p-4 sm:w-1/2 flex flex-col justify-center items-center bg-gray-200 border border-gray-400 rounded-lg sm:rounded-xl',
        loaderIcon: 'animate-spin h-8 w-8 sm:w-10 sm:h-10 my-4 border-4 border-teal-500 rounded-full ',
        newsHeader: 'w-full py-1 flex items-end text-xl sm:text-2xl text-teal-600',
        newsIcon: 'mr-2 w-6 h-6 sm:w-8 sm:h-8',
        newsTopicContainer: 'w-full pt-2 pb-1 flex flex-col border-b border-gray-300 text-sm sm:text-base text-gray-700 whitespace-pre-wrap',
        newsTopicHeader: 'w-full flex flex-row items-center text-gray-500',
        newsTopicTag: 'ml-2 px-2 bg-gray-300 rounded text-xs text-gray-500',
        newsTopicTitle: 'w-full py-1 flex flex-row text-base',
    }
    const methods = useForm({
        mode: 'onSubmit',
        // reValidateMode: 'onChange',
    })
    const sendApi = useCallback(async () => {
        try {
            const json = document.getElementById('api').value
            const sendData = JSON.parse(json)
            console.log('request body:', sendData)
            const functions = firebase.functions('asia-northeast2')
            const cfFunc = functions.httpsCallable('ConductAxiosTest')
            const res = await cfFunc(sendData)
            console.log('res body:', res.data)
            console.log('データ送信に成功しました')
        } catch (err) {
            console.error('Error:', err)
            console.log('データ送信に失敗しました')
            // throw new Error(error)
        }
    }, [])

    // const dateToStr = useCallback((date) => {
    //     //日付文字列をオブジェクト化
    //     if (!isNaN(new Date(date))) {
    //         const stringToDate = new Date(date)
    //         const year = stringToDate.getFullYear().toString()
    //         const month = stringToDate.getMonth() + 1
    //         const day = stringToDate.getDate()
    //         const wareki = eraYear(year)
    //         const dayOfWeek = stringToDate.getDay() // 曜日(数値)
    //         const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeek] // 曜日(日本語表記)
    //         const format = `${year}/${month}/${day} (${dayOfWeekStr})`
    //         return format
    //     } else return date
    // }, [])
    // const [news, SetNews] = useState([])
    // useEffect(() => {
    //     const getGas = async () => {
    //         try {
    //             const res = await fetch(process.env.REACT_APP_NEWS_GAS_URL, {
    //                 method: 'POST',
    //                 body: JSON.stringify({ req: 1 }),
    //             })
    //             const resTxt = await res.text()
    //             const resObj = JSON.parse(resTxt)
    //             // console.log(resObj)
    //             // const resJson = JSON.stringify(resObj)
    //             // console.log(resJson)
    //             const newsArrAll = resObj.result
    //             newsArrAll.sort((a, b) => (a[0] < b[0] ? 1 : -1)) //日付順にソート
    //             // console.log(newsArrll)
    //             const newsArrSliced = newsArrAll.length >= 5 ? newsArrAll.slice(0, 5) : newsArrAll
    //             // console.log(newsArrSliced)
    //             newsArrSliced.forEach((ele) => (ele[0] = dateToStr(ele[0])))
    //             const newsArrObj = newsArrSliced.map((ele) => {
    //                 const obj = {}
    //                 obj['date'] = ele[0]
    //                 obj['tag'] = ele[1]
    //                 obj['title'] = ele[2]
    //                 obj['content'] = ele[3]
    //                 return obj
    //             })
    //             // console.log(newsArrObj)
    //             SetNews(newsArrObj)
    //         } catch (err) {
    //             console.error(err)
    //         }
    //     }
    //     getGas()
    // }, [dateToStr])
    const sampleJson = {
        disp_id: '',
        shori_div: '3',
        taishosha_id: ['239'],
        url: 'xxx',
    }
    const getFb = async () => {
        try {
            // const ref = db.collection('').doc('').collection('')
            // const ref = db.collectionGroup('shisetsu_id').where('shisetsu_id', '==', '445')
            // const snapshot = await ref.get()
            // const idList = []
            // if (snapshot.empty) console.log('empty')
            // snapshot.forEach((doc) => {
            //     console.log(doc.id)
            //     console.log(doc.data())
            //     idList.push(doc.id)
            // })
            // console.log({ idList })
            //削除済みデータ取得
            // const taishosha_id = '
            // const taishosha_id_info = db.collection('taishosha_id').doc(taishosha_id).collection('disp_id').doc('101')
            // const taishosha_id_doc = await taishosha_id_info.get()
            // const Taishosha_Name = String(taishosha_id_doc.data().taishosha_name)
            // console.log({ Taishosha_Name })
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className={style.container}>
            <ul className={style.navList}>
                <li className={style.navItem}>
                    <DocumentAddIcon className={style.navIcon} />
                    <Link to='/newconsult'>新規相談受付</Link>
                </li>
                <li className={style.navItem}>
                    <BookOpenIcon className={style.navIcon} />
                    <Link to='/consulthistory'>相談履歴</Link>
                </li>
                <li className={style.navItem}>
                    <SearchIcon className={style.navIcon} />
                    <Link to='/pastcasesearch'>過去事例検索</Link>
                </li>
                <li className={style.navItem}>
                    <QuestionMarkCircleIcon className={style.navIcon} />
                    <Link to='/usermanual'>アプリの使い方</Link>
                </li>
                <li className={style.navItem}>
                    <MailIcon className={style.navIcon} />
                    <Link to='/contact'>お問い合わせ</Link>
                </li>
                {/* <li className={style.navItem}>
                    <BellIcon className={style.navIcon} />
                    <Link to='/news'>お知らせ</Link>
                </li> */}
                {['1', '2'].includes(userData.kengen) && (
                    <li className={style.navItem}>
                        {/* <SearchIcon className={style.navIcon} /> */}
                        <Link to='/debug'>デバッグ</Link>
                    </li>
                )}
            </ul>
            {/* <ul className={style.newsContainer}> */}
            {/* <li className={style.newsHeader}>
                    <BellIcon className={style.newsIcon} />
                    <p>お知らせ</p>
                </li> */}
            {
                // news.length > 0 ? (
                //     news.map((ele, i) => (
                //         <li key={i} className={style.newsTopicContainer}>
                //             <div className={style.newsTopicHeader}>
                //                 <p className={''}>{ele.date}</p>
                //                 <p className={style.newsTopicTag}>{ele.tag}</p>
                //             </div>
                //             <p className={style.newsTopicTitle}>{ele.title}</p>
                //             {/* <p className={''}>{ele.content}</p> */}
                //         </li>
                //     ))
                // ) : (
                //     <div className={style.loaderIcon} style={{ borderTopColor: 'transparent' }}></div>
                // )
            }
            {/* </ul> */}
            {/* <FormProvider {...methods}> */}
            {/* <div className='mt-8'>
                    <TextArea
                        label='接続テスト送信JSON'
                        name='api'
                        register={methods.register('api', {})}
                        defaultValue={JSON.stringify(sampleJson, null, 2)}
                        // register={register('api', {})}
                        // placeholder={phAddr}
                        expandSize={true}
                    />
                    <Button value='API接続テスト' onClick={sendApi} outline={true} />
                </div> */}
            {/* </FormProvider> */}
            {/* <Button value='GAS' onClick={getGas} outline={true} /> */}
            {/* <Button value='DB' onClick={getFb} outline={true} /> */}
        </div>
    )
}
