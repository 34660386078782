import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import clonedeep from 'lodash.clonedeep'
import { useAuthContext } from '../func'
import { PersonalInfoRecept, PersonalInfoTarget, PersonalInfoStatus, PersonalInfoIntroduction, PersonalInfoInteraction, PersonalInfoSupport } from '../block'
import { firebase, db, func } from '../func/firebase'
import { inputData, checkBoxToData, dateFormToData, replaceLineCode, getCurrentDate, checkKeyDown, insertByDispId, dataForCf, postToCf, feedBackDisplay } from '../func/CommonFunc'
import { useFbDataContext } from '../page'
import { Dialog, Modal, Button } from '../atom'
import { defaultValues, fieldsByCategory } from '../json'

// let EditPersonalInfoCompoRender = 0
export default function PersonalInfo() {
    // EditPersonalInfoCompoRender++
    // console.log('EditPersonalInfoCompoRender', EditPersonalInfoCompoRender)
    const { userData } = useAuthContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const { fbData, setFbData, setShowDetail, showDetail, setShowEdit } = useFbDataContext()
    const schema = yup.object().shape({
        shokaimendan_date: yup.string().required('初回面談日は必須項目です'),
        taishosha_name_kana: yup
            .string()
            .required('対象者名(フリガナ)は必須項目です')
            .matches(/^[ア-ン゛゜ァ-ォャ-ョー「」。、 　]+$/, 'カタカナを入力してください'),
        taishosha_name: yup.string().required('対象者名(氏名)は必須項目です'),
        // .matches(/([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu, '漢字を入力してください'),
        gender: yup.string().required('対象者(性別)は必須項目です'),
        nenrei: yup
            .string()
            .required('年齢は必須項目です')
            .matches(/^([1-9]\d*|0)$/, '数字のみ入力してください')
            .max(3, '3文字以下にしてください'),
        shiku_name: yup.string().required('住所(市区町村)は必須項目です'),
        shokai_keiro: yup.string().required('紹介経路は必須項目です'),
        yachin: yup.string().matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true }),
        kazoku_nenrei_1: yup
            .string()
            .matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true })
            .max(3, '3文字以下にしてください'),
        kazoku_nenrei_2: yup
            .string()
            .matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true })
            .max(3, '3文字以下にしてください'),
        kazoku_nenrei_3: yup
            .string()
            .matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true })
            .max(3, '3文字以下にしてください'),
        kazoku_nenrei_4: yup
            .string()
            .matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true })
            .max(3, '3文字以下にしてください'),
        kazoku_nenrei_5: yup
            .string()
            .matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true })
            .max(3, '3文字以下にしてください'),
    })
    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: defaultValues.personalInfo,
    })
    const refDataLoad = useRef(true)
    const refShowDetail = useRef(null)
    useEffect(() => {
        // console.log('useEffect Reloaded in PersonalInfo')
        // console.log('fbData in PersonalInfo', fbData)
        // console.log('ref?', refDataLoad)
        const open = showDetail.index === 11
        const close = showDetail.index !== 11 && refShowDetail.current === 11
        if (refDataLoad.current && open) {
            // console.log('data input in PersonalInfo')
            const dispData = { ...fbData['11'], ...fbData['101'] }
            // console.log('disp11', dispData)
            inputData(dispData, methods.setValue)
            if (dispData['update_date'] !== '') {
                refDataLoad.current = false
            }
        } else if (close) {
            // console.log('close and setFbData in PersonalInfo')
            // const cloneData = clonedeep(fbData)
            // console.log('cloneData in PersonalInfo', cloneData)
            // const formData = methods.getValues()
            // console.log('formData in PersonalInfo', formData)
            // cloneData['11'] = insertByDispId('11', formData, cloneData)
            // cloneData['101'] = insertByDispId('101', formData, cloneData)
            //家族リストを抽出してオブジェクトに入れる
            // const keys = Object.keys(formData)
            // const familyInputs = {}
            // const checkNames = ['kazoku_no', 'kazoku_name', 'kazoku_zokugara', 'kazoku_nenrei', 'shuro_shugaku_jokyo', 'kenko_jotai', 'biko_']
            //checkNamesをキー名に含むなら新objに入る
            // for (let key of keys) {
            //     const bool = checkNames.some((name) => key.includes(name))
            //     if (bool) {
            //         familyInputs[key] = formData[key]
            //     }
            // }
            // console.log('familyInputs', familyInputs)
            // Object.assign(cloneData['11'], familyInputs)
            // console.log('cloneData editted in PersonalInfo', cloneData)
            // setFbData(cloneData)
            refDataLoad.current = true
        }
    }, [fbData, setFbData, methods, showDetail])
    useEffect(() => {
        // console.log('showDetail in PersonalInfo :', showDetail)
        refShowDetail.current = showDetail.index
    }, [showDetail])
    const resetText = () => {
        methods.reset()
    }
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backToShowDetail = useCallback(() => {
        const cancelEdit = () => {
            // console.log('yes')
            const pathName = history.location.pathname
            history.replace({ pathname: pathName })
            window.location.reload()
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        setShowDialog({
            ...showDialog,
            show: true,
            message: '相談の詳細・編集画面へ戻り、編集中の内容は消去します。\nよろしいですか？',
            doYes: cancelEdit,
            doNo: hideDialog,
            textYes: 'はい',
            textNo: 'いいえ',
        })
    }, [history, showDialog])
    //ユーザーデータが無い時の処理(送信ボタンを押せなくする、管理者権限閲覧時も同様)
    const [sendable, setSendable] = useState({ send: false, reasonText: '' })
    useEffect(() => {
        const isUserId = 'user_id' in userData
        const isShisetsuId = 'shisetsu_id' in userData
        // console.log({ isUserId, isShisetsuId })
        if (!isUserId) {
            console.error('userIdがありません')
            setSendable({ send: false, reasonText: 'ユーザーIDが無いため' })
        } else if (!isShisetsuId) {
            console.error('shisetsuIdがありません')
            setSendable({ send: false, reasonText: '施設IDが無いため' })
        } else if (isUserId && isShisetsuId) {
            // console.log('userData in EditConsult', userData)
            const uFacilityId = userData.shisetsu_id
            const uKengen = userData.kengen
            const isMatchFacilityId = fbData['10']['shisetsu_id'] === uFacilityId
            const isAdmin = ['1', '2'].includes(uKengen)
            // console.log({ uFacilityId, uKengen, isMatchFacilityId, isAdmin })
            if (isAdmin && !isMatchFacilityId) {
                setSendable({ send: false, reasonText: '確認用権限のため' })
            } else {
                setSendable({ send: true, reasonText: '' })
            }
        }
    }, [userData, fbData])
    const unSendable = useCallback(
        (text) => {
            const hideDialog = () => {
                setShowDialog({ ...showDialog, show: false })
            }
            const reload = () => {
                const pathName = history.location.pathname
                history.replace({ pathname: pathName })
                window.location.reload()
            }
            const message = `${text}現在送信できません\nページをリロードするか(入力内容は消去されます)\nウインドウを閉じてしばらくお待ち下さい\n待っても送信できない時はログインし直してみてください`
            setShowDialog({
                ...showDialog,
                show: true,
                message: message,
                doYes: reload,
                doNo: hideDialog,
                textYes: 'リロード',
                textNo: '閉じる',
            })
        },
        [showDialog, history]
    )
    const onSubmit = (data) => {
        const cloneData = clonedeep(data)
        // console.log('FormData', cloneData)
        const dataPersonal = { ...fbData['11'], ...fbData['101'] }
        Object.assign(dataPersonal, cloneData)
        //マスタデータ(バージョン番号,ユーザーID,施設ID,画面ID,処理区分)を追加
        dataPersonal['user_id'] = userData.user_id
        dataPersonal['shisetsu_id'] = userData.shisetsu_id
        dataPersonal['disp_id'] = '11'
        dataPersonal['shori_div'] = '1'
        //事例番号は消去
        // dataPersonal['jirei_no'] = ''
        delete dataPersonal['jirei_no']
        //改行コードを置換
        //画面11
        dataPersonal['tomentaio'] = replaceLineCode(dataPersonal['tomentaio'])
        dataPersonal['seikatsu_jokyo'] = replaceLineCode(dataPersonal['seikatsu_jokyo'])
        //画面101
        dataPersonal['adr_dtl'] = replaceLineCode(dataPersonal['adr_dtl'])
        dataPersonal['shokai_naiyo'] = replaceLineCode(dataPersonal['shokai_naiyo'])
        //日付を追加,書き換え(obj=>str)
        //画面11
        dataPersonal['shokai_date'] = dateFormToData(dataPersonal['shokai_date'])
        dataPersonal['shokaimendan_date'] = dateFormToData(dataPersonal['shokaimendan_date'])
        //画面101
        dataPersonal['birthday'] = dateFormToData(dataPersonal['birthday'])
        //特殊UIデータを整形
        //家族リストを抽出して配列に入れる
        const splitFamilyList = (obj) => {
            const keys = Object.keys(obj)
            const familyInputs = {}
            const checkNames = ['kazoku_no', 'kazoku_name', 'kazoku_zokugara', 'kazoku_nenrei', 'shuro_shugaku_jokyo', 'kenko_jotai', 'biko_']
            //checkNamesをキー名に含むなら新objに入れ、旧objから削除
            for (let key of keys) {
                const bool = checkNames.some((name) => key.includes(name))
                if (bool) {
                    familyInputs[key] = obj[key]
                    delete obj[key]
                }
            }
            // console.log('familyInputs', familyInputs)
            //家族リストの入れ物(5人まで)
            const familyListTemp = [{}, {}, {}, {}, {}]
            //番号ごとに振り分けて配列に入れ,新objから削除(ループ回数削減のため)
            const splitList = (keyName, num) => {
                if (keyName.includes(String(num))) {
                    const familyObj = familyListTemp[num - 1]
                    const newKey = keyName.replace(`_${num}`, '')
                    Object.assign(familyObj, { ...familyObj, [newKey]: familyInputs[keyName] })
                    delete familyInputs[keyName]
                }
            }
            //家族リストの数x新objキーの数分のループを回して振り分ける
            for (let i = 0; i < familyListTemp.length; i++) {
                const keys = Object.keys(familyInputs)
                for (let key of keys) {
                    splitList(key, i + 1)
                }
            }
            //返り値用の配列
            const familyInfoList = []
            //値が全て空でなければ新しいkazoku_noを付け配列に入れる
            for (let i = 0; i < familyListTemp.length; i++) {
                const vals = Object.values(familyListTemp[i])
                const isFilled = vals.some((v) => v !== '')
                // console.log(`isFilled[${i}]`, isFilled)
                if (isFilled) {
                    familyListTemp[i]['kazoku_no'] = String(i + 1)
                    familyInfoList.push(familyListTemp[i])
                } else {
                    familyListTemp[i]['kazoku_no'] = String(i + 1)
                    familyListTemp[i]['kazoku_name'] = ''
                    familyListTemp[i]['kazoku_zokugara'] = ''
                    familyListTemp[i]['kazoku_nenrei'] = ''
                    familyListTemp[i]['shuro_shugaku_jokyo'] = ''
                    familyListTemp[i]['kenko_jotai'] = ''
                    familyListTemp[i]['biko'] = ''
                    familyInfoList.push(familyListTemp[i])
                    // break
                }
            }
            // console.log('familyListTemp', familyListTemp)
            // console.log('familyInfoList', familyInfoList)
            return familyInfoList
        }
        dataPersonal['kazoku_list'] = splitFamilyList(dataPersonal)
        //重複避けデータを削除
        delete dataPersonal['sodansha_shubetsu_sptc']
        delete dataPersonal['seiho_kbn_sptc']
        delete dataPersonal['sodansha_shubetsu_sonota_sptc']
        delete dataPersonal['todouhuken']
        console.log('disp11 SendData', dataPersonal)
        const response = postToCf(dataPersonal)
        feedBackDisplay(response, setShowModal)
    }
    return (
        <div className='w-full flex flex-col items-center'>
            <Modal showModal={showModal} />
            <Dialog showDialog={showDialog} />
            <FormProvider {...methods}>
                <form
                    className='w-full mb-4 px-2 sm:max-w-2xl sm:mx-8 flex flex-col items-center'
                    name='EditConsultReceptForm'
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    <PersonalInfoRecept />
                    <PersonalInfoTarget />
                    <PersonalInfoStatus />
                    <PersonalInfoIntroduction />
                    <PersonalInfoInteraction />
                    <PersonalInfoSupport />
                    <div className='pt-6 pb-4 max-w-xl ml-auto flex flex-col items-end sm:flex-row sm:justify-end flex-wrap gap-x-4 gap-y-3'>
                        {sendable.send ? <Button type='submit' value='送信' /> : <Button type='button' value='送信' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />}
                        <Button type='button' value='キャンセル' onClick={backToShowDetail} outline={true} />
                    </div>
                </form>
                {/* <DevTool control={methods.control} /> */}
            </FormProvider>
        </div>
    )
}
