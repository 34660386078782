import { LoginIcon } from '@heroicons/react/solid'
import { useAuthContext } from '../func'

export default function Drawer({ children, isOpen, setIsOpen }) {
    const { currentUser, userData } = useAuthContext()
    // console.log('Drawer UserData', userData)
    return (
        <main
            className={
                ' fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
                (isOpen ? 'transition-opacity opacity-100 duration-500 -translate-x-0' : 'transition-all delay-500 opacity-0 -translate-x-full')
            }
        >
            <section
                className={
                    'absolute w-2/3 sm:w-1/2 h-full max-w-lg left-0 bg-gray-50 shadow-xl text-gray-600 delay-400 duration-500 ease-in-out transition-all transform' +
                    (isOpen ? ' -translate-x-0 ' : ' -translate-x-full ')
                }
            >
                <article className='relative max-w-lg mx-4 pb-10 flex flex-col space-y-6 overflow-y-scroll h-full'>
                    <header className='h-11 border-b border-gray-500'>
                        <div className='flex flex-row justify-between py-2 font-bold sm:text-lg'>
                            <LoginIcon
                                className='w-8 h-8'
                                onClick={() => {
                                    setIsOpen(false)
                                }}
                            />
                            <p className='flex items-end text-base font-light'>{`${userData.shisetsu_name} 様`}</p>
                        </div>
                    </header>
                    {children}
                </article>
            </section>
            <section
                className=' w-screen h-full cursor-pointer '
                onClick={() => {
                    setIsOpen(false)
                }}
            ></section>
        </main>
    )
}
