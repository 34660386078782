export default function Button({ type, name, value, onClick, outline, className = '', color = 'rose-500', size = 'middle', icon, iconClassName }) {
    const bgColor = `bg-${color}`
    const styleBase = `flex items-center rounded-lg text-center`
    const styleSize = size === 'middle' ? 'py-2 px-6 text-lg sm:text-2xl' : size === 'small' ? 'py-1 px-4 sm:px-6 text-md sm:text-2xl' : ''
    const styleFill = `
        ${className} ${styleBase} ${styleSize} ${bgColor} text-gray-50 border-${color}
        hover:bg-opacity-70 border-2 hover:border-transparent`
    const styleOutline = `
        ${className} ${styleBase} ${styleSize} bg-gray-50 text-${color} border-${color}
        hover:bg-gray-500 border-2 hover:bg-opacity-10 font-bold`
    const style = outline ? styleOutline : styleFill
    const styleIcon = `${iconClassName} w-6 h-6 sm:w-8 sm:h-8 mr-1`
    return (
        <>
            <button id={name} className={style} type={type} name={name} onClick={onClick}>
                {icon && <div className={styleIcon}>{icon}</div>}
                {value}
            </button>
        </>
    )
}
