import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import clonedeep from 'lodash.clonedeep'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { SearchIcon, ExclamationCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import { firebase, db } from '../func/firebase'
import { useAuthContext } from '../func'
import { PastCaseModal } from '../container'
import { dateFormToData, dateDataToForm, checkKeyDown, codeToDisplay, genderMatch, ageToString } from '../func/CommonFunc'
import { selectionOptions } from '../json'
import { CheckBox, InputDate, Button } from '../atom'

// let pastCaseSearchCompoRender = 0
export default function PastCaseSearch() {
    // pastCaseSearchCompoRender++
    // console.log('pastCaseSearchCompoRender', pastCaseSearchCompoRender)
    const setai_options = selectionOptions.setai_jokyo_options
    const shubetsu_options = selectionOptions.sodansha_shubetsu_options
    const keyword_options = selectionOptions.sodansha_keyword_options
    const shushi_options = selectionOptions.shushi_jokyo_options
    const style = {
        form: 'w-full flex flex-col my-1 bg-gray-200 rounded-lg sm:rounded-3xl border border-gray-400',
        searchList: 'px-4 pt-2 mb-2',
        searchListDate: 'px-4 pt-2 pb-2',
        searchListDisplay: 'flex justify-between items-start',
        searchListButton: 'px-4 pt-2 pb-4 flex justify-end gap-x-4',
        searchListTitleContainer: 'flex flex-row items-center border-b border-gray-400',
        searchListTitle: 'pl-2 mb-2 text-md sm:text-2xl text-gray-600 border-l-4 border-rose-400',
        queryContainer: 'w-full mt-2 text-xs sm:text-lg text-gray-700',
        queryDate: 'mb-1 py-1 px-2 rounded bg-gray-200 text-gray-500',
        queryWordContainer: 'flex flex-row flex-wrap mb-2 text-xs',
        queryWord: 'mr-1 mb-1 py-1 px-2 rounded bg-gray-200 text-gray-500',
        chevronIcon: 'w-8 h-8 sm:w-10 sm:h-10 ml-1',
        exCircleIcon: 'w-8 h-8 sm:w-10 sm:h-10 mr-2',
        searchIcon: 'w-6 h-6 sm:w-8 sm:h-8 mr-1',
        searchDetailContainer: 'bg-gray-100 px-4 border-b border-r border-l border-gray-300',
        searchDateContainer: 'flex flex-row items-start text-lg text-gray-600',
        button: 'py-1 px-4 sm:px-6 flex rounded-lg text-center text-md sm:text-2xl bg-teal-600 hover:bg-opacity-70 text-white border-2 border-teal-600 hover:border-transparent',
        cellList: 'w-full grid grid-cols-16 items-center bg-gray-200 text-left border-b border-gray-400',
        cellShort: 'col-span-4 px-2 py-2 whitespace-pre-wrap',
        cellLong: 'col-span-4 px-1 py-2 whitespace-pre-wrap',
        cellError: 'w-full flex justify-center items-center p-2 bg-gray-50 text-rose-400 text-base',
    }
    const { currentUser, userData } = useAuthContext()
    const methods = useForm({
        mode: 'onBlur',
    })
    const [searchedData, setSearchedData] = useState({ error: false, data: [] })
    const [ele, setEle] = useState([])
    const [showSearch, setShowSearch] = useState(true)
    const [searchQueries, setSearchQueries] = useState({ keyword: [], date: '' })
    const [targetData, setTargetData] = useState({ display: false, data: {} })
    const perPage = 10
    const [offset, setOffset] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const searchDisplay = useCallback(() => {
        setShowSearch((prev) => !prev)
    }, [setShowSearch])
    //コード番号で記録されている項目をGUI表示名に変換する処理
    const codeToDisplay = useCallback((obj) => {
        const object = {}
        obj.forEach((item) => (object[item.code] = item['display']))
        return object
    }, [])
    const setaiMatch = useMemo(() => codeToDisplay(setai_options), [codeToDisplay, setai_options])
    const shubetsuMatch = useMemo(() => codeToDisplay(shubetsu_options), [codeToDisplay, shubetsu_options])
    const keywordMatch = useMemo(() => codeToDisplay(keyword_options), [codeToDisplay, keyword_options])
    const shushiMatch = useMemo(() => codeToDisplay(shushi_options), [codeToDisplay, shushi_options])
    const arrayCodeToDisp = useCallback((items, match, catName) => {
        const array = []
        for (let i = 0; i < items.length; i++) {
            const code = items[i]
            if (code === false) continue
            else if (code === '999') array.push(`${catName}:${match[code]}`)
            else if (!!code) array.push(match[code])
        }
        return array
    }, [])
    const searchDb = async (data) => {
        try {
            // console.log('form data', data)
            const searchKeyword = async () => {
                // console.log('shisetsu_id', shisetsu_id)
                let queryKeyword = db.collection('taishosha_id')
                const searchWords = []
                // クエリ追加処理
                //権限によって検索範囲を分岐(kengen:0=>自施設のみ,1,2=>全て)
                const { shisetsu_id, kengen } = await userData
                // console.log({ shisetsu_id, kengen })
                if (kengen === '0') {
                    queryKeyword = queryKeyword.where(`shisetsu_id`, '==', shisetsu_id)
                }
                const addQueries = (array, searchName) => {
                    const flg = !array.every((item) => item === false)
                    // console.log('flg', flg)
                    if (flg) {
                        array.forEach((item) => {
                            if (item) {
                                queryKeyword = queryKeyword.where(`${searchName}_${item}`, '==', true)
                                searchWords.push(`${searchName}_${item}`)
                            }
                        })
                    }
                }
                addQueries(data['setai_jokyo_search'], 'setai_jokyo', queryKeyword)
                addQueries(data['sodansha_shubetsu_search'], 'sodansha_shubetsu', queryKeyword)
                addQueries(data['sodansha_keyword_search'], 'sodansha_keyword', queryKeyword)
                addQueries(data['shushi_jokyo_search'], 'shushi_jokyo', queryKeyword)
                // console.log({ searchWords, queryKeyword })
                const docsKeyword = []
                const snapshotKeyword = await queryKeyword.limit(2000).get()
                snapshotKeyword.forEach((doc) => {
                    // console.log('searchedKeyword', doc.id, ' => ', doc.data())
                    docsKeyword.push(doc.data())
                })
                return docsKeyword
            }
            const searchDate = async () => {
                let queryDate = db.collection('taishosha_id')
                let startDate = data['start_date_search']
                let endDate = data['end_date_search']
                const isStartDate = !!startDate
                const isEndDate = !!endDate
                if (isStartDate && isEndDate) {
                    // console.log('start ok end ok')
                } else if (isStartDate && !isEndDate) {
                    endDate = new Date()
                    // console.log('start ok end ng')
                } else if (!isStartDate && isEndDate) {
                    startDate = new Date('1868/01/01')
                    // console.log('start ng end ok')
                } else if (!isStartDate && !isEndDate) {
                    const pastYear = new Date().getFullYear() - 5
                    startDate = new Date(`${pastYear}/01/01`)
                    endDate = new Date()
                    // console.log('start ng end ng')
                }
                // console.log({ startDate, endDate })
                queryDate = queryDate.orderBy('shokaimendan_date', 'asc').startAt(startDate).endAt(endDate)
                const docsDate = []
                const snapshotDate = await queryDate.get()
                snapshotDate.forEach((doc) => {
                    // console.log('searchedDate', doc.id, ' => ', doc.data())
                    docsDate.push(doc.data())
                })
                return { docsDate, startDate, endDate }
            }
            const docsKeyword = await searchKeyword()
            // console.log('docsKeyword', docsKeyword)
            const searchedDate = await searchDate()
            const docsDate = searchedDate['docsDate']
            // console.log('docsDate', docsDate)
            const setQueriesData = () => {
                const setaiArr = arrayCodeToDisp(data['setai_jokyo_search'], setaiMatch, '世帯')
                const shubetsuArr = arrayCodeToDisp(data['sodansha_shubetsu_search'], shubetsuMatch, '種別')
                const keywordArr = arrayCodeToDisp(data['sodansha_keyword_search'], keywordMatch, 'キーワード')
                const shushiArr = arrayCodeToDisp(data['shushi_jokyo_search'], shushiMatch, '収支')
                const mergedArray = [...setaiArr, ...shubetsuArr, ...keywordArr, ...shushiArr]
                // console.log({ setaiArr, shubetsuArr, keywordArr, shushiArr })
                // console.log(mergedArray)
                const genDateStr = (date) => {
                    if (typeof date === 'object') {
                        const dateStr = dateFormToData(date)
                        const warekiYear = eraYear(Number(dateStr.slice(0, 4)))
                        // console.log({ dateStr, warekiYear })
                        return `${warekiYear}(${dateStr.slice(0, 4)})年${dateStr.slice(4, 6)}月${dateStr.slice(6)}日`
                    } else if (typeof date === 'string') {
                        return date
                    } else if (!!date) {
                        return ''
                    }
                }
                const startDate = genDateStr(searchedDate['startDate'])
                const endDate = genDateStr(searchedDate['endDate'])
                // console.log({ startDate, endDate })
                const whetDate = `${startDate} ～ ${endDate}`
                setSearchQueries({ ...searchQueries, keyword: mergedArray, date: whetDate })
            }
            setQueriesData()
            const outputSearchResult = () => {
                //重複する対象者IDを抽出(キーワード+日付のAND検索ができないので代替)
                const targetIdsKeyword = docsKeyword.map((v) => v['taishosha_id'])
                const targetIdsDate = docsDate.map((v) => v['taishosha_id'])
                const commonIds = _.intersection(targetIdsKeyword, targetIdsDate)
                // const commonIds = targetIdsKeyword
                // console.log({ targetIdsKeyword, targetIdsDate, commonIds })
                //結果を配列に入れる
                const searchResults = []
                commonIds.forEach((value) => {
                    const index = docsKeyword.findIndex((el) => el['taishosha_id'] === value)
                    // const date = docsKeyword[index]['shokaimendan_date']
                    // const dateObj = date ? date.toDate() : ''
                    // const dateStr = dateFormToData(dateObj)
                    // docsKeyword[index]['shokaimendan_date'] = dateStr
                    if (index !== -1) searchResults.push(docsKeyword[index])
                })
                //エラーを待たず検索ウインドウを閉じる
                setShowSearch(false)
                //エラーハンドリング
                // console.log({ docsKeyword, docsDate, searchResults })
                if (docsKeyword.length === 0) {
                    throw new Error('検索キーワードが見つかりません')
                } else if (docsDate.length === 0) {
                    throw new Error('指定した日付のデータが見つかりません')
                } else if (searchResults.length === 0) {
                    throw new Error('指定した条件のデータが見つかりません')
                }
                //日付順(新=>旧)に並び替え
                searchResults.sort((a, b) => {
                    return a['shokaimendan_date'] > b['shokaimendan_date'] ? -1 : 1
                })
                return searchResults
            }
            // console.log('searchResults', outputSearchResult())
            setSearchedData({ ...searchedData, error: false, data: outputSearchResult() })
        } catch (error) {
            console.error(error)
            const errorTypeFlg = error instanceof Error
            console.log(errorTypeFlg, error.message)
            if (errorTypeFlg) {
                setSearchedData({ ...searchedData, error: true, data: [error.message] })
            }
        }
    }
    const getData = useCallback(
        async (id) => {
            try {
                const refDoc = db.collection('taishosha_id').doc(id)
                const refSubCol = refDoc.collection('disp_id')
                const snapSubCol = await refSubCol.get()
                const docsObj = {}
                snapSubCol.forEach((doc) => {
                    // console.log(doc.id, '=>', doc.data())
                    docsObj[doc.id] = doc.data()
                })
                // console.log('taishosha_Docs', docsObj)
                setTargetData({ ...targetData, display: true, data: docsObj })
            } catch (error) {
                console.log(error)
            }
        },
        [targetData]
    )
    const eraYear = useCallback((year) => {
        const eraHandler = (yearNow) => {
            const generate = (era, startYear) => {
                let yearDsp = yearNow - startYear + 1
                if (yearDsp === 1) {
                    yearDsp = '元'
                } else {
                    yearDsp = ('00' + yearDsp).slice(-2)
                }
                return `${era}${yearDsp}`
            }
            if (yearNow >= 2019) {
                return generate('令和', 2019)
            }
            if (yearNow >= 1989) {
                return generate('平成', 1989)
            }
            if (yearNow >= 1926) {
                return generate('昭和', 1926)
            }
            if (yearNow >= 1912) {
                return generate('大正', 1912)
            }
            if (yearNow >= 1868) {
                return generate('明治', 1868)
            }
        }
        return eraHandler(year)
    }, [])

    const titleTruncate = useCallback((title) => {
        return title.length <= 23 ? title : title.substr(0, 23) + '...'
    }, [])
    const shubetsuMatchGui = useCallback(
        (item) => {
            const shubetsuItems = []
            for (let i = 0; i < item.length; i++) {
                if (i > 3) {
                    shubetsuItems.push('...')
                    break
                }
                const code = item[i]
                shubetsuItems.push(shubetsuMatch[code])
            }
            return shubetsuItems.join('、')
        },
        [shubetsuMatch]
    )
    const dataToGui = useCallback(
        (data) => {
            const guiData = []
            data.forEach((item) => {
                const date = item['shokaimendan_date']
                if (typeof date === 'object') {
                    const dateStr = dateFormToData(date.toDate())
                    const warekiYear = eraYear(Number(dateStr.slice(0, 4)))
                    // console.log({ dateStr, warekiYear })
                    item['shokaimendan_date'] = `${warekiYear}年\n(${dateStr.slice(0, 4)}年)\n${dateStr.slice(4, 6)}月${dateStr.slice(6)}日`
                } else if (typeof date === 'string') {
                    item['shokaimendan_date'] = date
                } else if (!!date) {
                    item['shokaimendan_date'] = ''
                }
                item['gender'] = !!item['gender'] ? genderMatch(item['gender']) : ''
                item['nenrei'] = !!item['nenrei'] ? ageToString(Number(item['nenrei'])) : ''
                item['shien_title'] = !!item['shien_title'] ? titleTruncate(item['shien_title']) : 'タイトルなし'
                const shubetsuFlg = !!item['sodansha_shubetsu_list'] && item['sodansha_shubetsu_list'].length > 0
                item['sodansha_shubetsu'] = shubetsuFlg ? shubetsuMatchGui(item['sodansha_shubetsu_list']) : ''
                guiData.push(item)
            })
            return guiData
        },
        [eraYear, titleTruncate, shubetsuMatchGui]
    )
    useEffect(() => {
        const genElement = () => {
            // console.log('searchedData', searchedData)
            const data = clonedeep(searchedData['data'])
            const guiData = dataToGui(data)
            // console.log('guiData',guiData)
            const slice = guiData.slice((offset - 1) * perPage, offset * perPage)
            // console.log('sliced data in this page', slice)
            const postEle = slice.map((item, index) => (
                // <li key={`${item['taishosha_id']}_container`}>
                <li key={`${index}_container`} onClick={() => getData(item['taishosha_id'])}>
                    <ul className={style.cellList}>
                        <li className={style.cellShort}>
                            <p>{item['shokaimendan_date']}</p>
                        </li>
                        {/* <li className={styleCell}>{item.shokaimendan_date}</li> */}
                        <li className={style.cellShort}>
                            {item['gender']}
                            {item['nenrei']}
                        </li>
                        <li className={style.cellLong}>{item['shien_title']}</li>
                        <li className={style.cellLong}>{item['sodansha_shubetsu']}</li>
                    </ul>
                </li>
            ))
            setEle(postEle)
            setPageCount(Math.ceil(data.length / perPage))
        }
        const genErrorElement = () => {
            const errorEle = (
                <div className={style.cellError}>
                    <ExclamationCircleIcon className={style.exCircleIcon} />
                    <p>{searchedData['data'][0]}</p>
                </div>
            )
            setEle(errorEle)
            setPageCount(0)
        }
        if (searchedData['error'] === false) genElement()
        else if (searchedData['error'] === true) genErrorElement()
    }, [searchedData, dataToGui, getData, offset, style.cellList, style.cellLong, style.cellShort, style.cellError, style.exCircleIcon])
    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage + 1)
    }
    return (
        <div className='w-full h-full flex flex-col justify-start items-center'>
            <div className='w-full sm:max-w-2xl pt-1 px-2 sm:mx-8'>
                {/* <div className={style.searchListDisplay}> */}
                {/* <div className='flex items-center'>
                        <h2 className='pl-1 text-xl sm:text-3xl text-gray-600 font-medium border-l-4 sm:border-l-8 border-rose-400'>過去事例検索</h2>
                    </div> */}
                {/* </div> */}
                <FormProvider {...methods}>
                    <form className={style.form} name='PastCaseSearchForm' onSubmit={methods.handleSubmit(searchDb)} onKeyDown={(e) => checkKeyDown(e)}>
                        <>
                            <div className='w-full flex items-center px-2 pt-2 pb-1' onClick={searchDisplay}>
                                <div className='flex items-center text-teal-700'>
                                    <SearchIcon className={style.searchIcon} />
                                    <h3 className='text-lg sm:text-2xl'>検索</h3>
                                    {showSearch ? <ChevronUpIcon className={style.chevronIcon} /> : <ChevronDownIcon className={style.chevronIcon} />}
                                </div>
                            </div>
                            {showSearch ? (
                                <div className='w-full bg-gray-100 rounded-b-lg sm:rounded-b-3xl'>
                                    <ul>
                                        <li className={style.searchList}>
                                            <div
                                                className={style.searchListTitleContainer}
                                                // onClick={() => detailDisplay(0)}
                                            >
                                                {/* {showDetail.index === 0 ? <ChevronUpIcon className={style.chevronIcon} /> : <ChevronDownIcon className={style.chevronIcon} />} */}
                                                <p className={style.searchListTitle}>世帯状況</p>
                                            </div>
                                            {/* <div className={showDetail.index === 0 ? style.searchDetailContainer : 'hidden'}> */}
                                            <CheckBox name='setai_jokyo_search' id='setai_jokyo_search' options={setai_options} isSmall={true} />
                                            {/* </div> */}
                                        </li>
                                        <li className={style.searchList}>
                                            <div
                                                className={style.searchListTitleContainer}
                                                // onClick={() => detailDisplay(1)}
                                            >
                                                {/* {showDetail.index === 1 ? <ChevronUpIcon className={style.chevronIcon} /> : <ChevronDownIcon className={style.chevronIcon} />} */}
                                                <p className={style.searchListTitle}>相談者種別</p>
                                            </div>
                                            {/* <div className={showDetail.index === 1 ? style.searchDetailContainer : 'hidden'}> */}
                                            <CheckBox name='sodansha_shubetsu_search' id='sodansha_shubetsu_search' options={shubetsu_options} isSmall={true} />
                                            {/* </div> */}
                                        </li>
                                        <li className={style.searchList}>
                                            <div
                                                className={style.searchListTitleContainer}
                                                // onClick={() => detailDisplay(2)}
                                            >
                                                {/* {showDetail.index === 2 ? <ChevronUpIcon className={style.chevronIcon} /> : <ChevronDownIcon className={style.chevronIcon} />} */}
                                                <p className={style.searchListTitle}>相談者/相談内容キーワード</p>
                                            </div>
                                            {/* <div className={showDetail.index === 2 ? style.searchDetailContainer : 'hidden'}> */}
                                            <CheckBox name='sodansha_keyword_search' id='sodansha_keyword_search' options={keyword_options} isSmall={true} />
                                            {/* </div> */}
                                        </li>
                                        <li className={style.searchList}>
                                            <div
                                                className={style.searchListTitleContainer}
                                                // onClick={() => detailDisplay(3)}
                                            >
                                                {/* {showDetail.index === 3 ? <ChevronUpIcon className={style.chevronIcon} /> : <ChevronDownIcon className={style.chevronIcon} />} */}
                                                <p className={style.searchListTitle}>収支状況</p>
                                            </div>
                                            {/* <div className={showDetail.index === 3 ? style.searchDetailContainer : 'hidden'}> */}
                                            <CheckBox name='shushi_jokyo_search' id='shushi_jokyo_search' options={shushi_options} isSmall={true} />
                                            {/* </div> */}
                                        </li>
                                        <li className={style.searchListDate}>
                                            <label className={style.searchListTitle}>検索期間</label>
                                            <div className={style.searchDateContainer}>
                                                <InputDate name='start_date_search' control={methods.control} startDate={''} placeholder='' />
                                                <p className='h-full px-1'>～</p>
                                                <InputDate name='end_date_search' control={methods.control} startDate={''} placeholder='' />
                                            </div>
                                        </li>
                                        <li className={style.searchListButton}>
                                            <Button type='button' value='リセット' onClick={() => methods.reset()} outline={true} color='teal-600' size='small' icon={<RefreshIcon />} />
                                            <Button type='submit' value='検索' color='teal-600' size='small' icon={<SearchIcon />} />
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    </form>
                </FormProvider>
                <div className={style.queryContainer}>
                    {searchQueries['date'] ? <p className={style.queryDate}>{searchQueries['date']}</p> : ''}
                    <ul className={style.queryWordContainer}>
                        {searchQueries['keyword'].map((item, index) => (
                            <li key={`${index}_query`} className=''>
                                <p className={style.queryWord}>{item}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='w-full mt-2 table-fixed text-xs sm:text-lg text-gray-700'>
                    <div className='mb-1 text-base sm:text-lg text-gray-500'>
                        {`${(offset - 1) * perPage + 1}-${searchedData['data'].length > offset * perPage ? offset * perPage : searchedData['data'].length}件 / ${searchedData['data'].length}`} 件中
                    </div>
                    <ul className='w-full grid grid-cols-16 bg-gray-400 text-left font-bold'>
                        <li className={style.cellShort}>初回日付</li>
                        <li className={style.cellShort}>対象者</li>
                        <li className={style.cellLong}>支援タイトル</li>
                        <li className={style.cellLong}>相談種別</li>
                    </ul>
                    <ul>{ele}</ul>
                </div>
                {/* <Button onClick={searchDb} value='searchDb' outline={true} />
                <Button onClick={add} value='Add' outline={true} /> */}
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
                {targetData.display && <PastCaseModal targetData={targetData} setTargetData={setTargetData} />}
            </div>
        </div>
    )
}
