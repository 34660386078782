import { useFormContext } from 'react-hook-form'
import { InputText, RadioButton, FormBlock, AutoInputZipToAddress, AutoInputBirthdayToAge } from '../atom'
import { selectionOptions } from '../json'

// let NCTBlockrender = 0
export default function NewConsultTarget(props) {
    // NCTBlockrender++
    // console.log('NCTBlockrender', NCTBlockrender)
    const {
        register,
        formState: { errors },
        control,
    } = useFormContext()
    const todayDate = new Date()
    const gender_options = selectionOptions.gender_options
    const contents = [
        <InputText
            label='フリガナ'
            name='taishosha_name_kana'
            type='text'
            register={register('taishosha_name_kana', {})}
            placeholder='カナ'
            subText='例) ヤマダタロウ'
            isRequired={true}
            maxLength='80'
            errorText={errors.taishosha_name_kana?.message}
        />,
        <InputText
            label='氏名'
            name='taishosha_name'
            type='text'
            register={register('taishosha_name', {})}
            placeholder='漢字'
            subText='例) 山田太郎'
            isRequired={true}
            maxLength='80'
            errorText={errors.taishosha_name?.message}
        />,
        <RadioButton label='性別' name='gender' id='gender_newconsult' options={gender_options} register={register('gender', {})} errorText={errors.gender?.message} isRequired={true} />,
        <AutoInputBirthdayToAge name='birthdayAndAge' nameBirthday='birthday' nameAge='nenrei' />,
        <AutoInputZipToAddress name='personAddress' nameZip='post_no' namePref='todouhuken' nameCity='shiku_name' nameTown='adr_dtl' printDisplay={{ display: true, countByLine: 23, lines: 1 }} />,
        <InputText label='電話番号' name='tel' type='text' register={register('tel', {})} errorText={errors.tel?.message} placeholder='' maxLength='12' />,
        <InputText label='携帯番号' name='tel_keitai' type='text' register={register('tel_keitai', {})} errorText={errors.tel_keitai?.message} placeholder='' maxLength='13' />,
    ]
    return (
        <>
            <FormBlock categoryName='対象者' formItems={contents} />
        </>
    )
}
