import { useFormContext } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { BadgeRequire } from '../atom'

// let radiorender = 0
export default function RadioButton({ label, name, id, options, subText, control, isRequired, errorText }) {
    // radiorender++
    // console.log('radiorender', radiorender)
    const { register } = useFormContext()
    const errorFlg = !!errorText
    const subTextFlg = !!subText
    const styleOption = `
        px-6 py-2 rounded-xl shadow-b4 active:shadow-b2
        bg-gray-200 text-lg sm:text-xl font-light text-gray-600
        transform active:translate-y-1
        peer-checked:bg-teal-600 peer-checked:text-gray-50 peer-checked:translate-y-1 peer-checked:shadow-b2
        z-0
    `
    const optionElements = options.map((option, index) => (
        <li key={index} className='min-w-0 mr-4 mb-6 flex items-center justify-center'>
            <input type='radio' id={`${id ? id : name}-${index}`} value={option.code ? option.code : option} className='w-0 h-0 opacity-0 peer' {...register(name, {})} />
            <label htmlFor={`${id ? id : name}-${index}`} className={styleOption}>
                <span className='text-center break-words'>{option.display ? option.display : option}</span>
            </label>
        </li>
    ))
    const normalContainerStyle = `p-2`
    const errorContainerStyle = `${normalContainerStyle} ring-2 ring-red-500 forcus:none`
    const containerStyle = !errorFlg ? normalContainerStyle : errorContainerStyle
    return (
        <div className='flex flex-col text-gray-800 mb-4 ' id={`${id ? id : name}_parent`}>
            <div className='flex flex-row items-end mb-2'>
                <label className='text-lg sm:text-2xl text-gray-600'>{label}</label>
                {isRequired && <BadgeRequire />}
            </div>
            <div className={containerStyle}>
                <ul className='flex flex-row flex-wrap'>{optionElements}</ul>
                {errorFlg && (
                    <span className='flex flex-row items-center justify-end my-2 text-right text-base font-bold text-red-400'>
                        <ExclamationCircleIcon className='w-4 h-4' />
                        {errorText}
                    </span>
                )}
            </div>
            {subTextFlg && <span className='py-2 text-sm sm:text-lg leading-snug text-teal-700 whitespace-pre-wrap'>{subText}</span>}
        </div>
    )
}
