import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { ChevronDownIcon, ChevronUpIcon, ExternalLinkIcon } from '@heroicons/react/solid'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router'
import { firebase, db } from '../func/firebase'
import { useAuthContext } from '../func'
import { dateFormToData } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let consultHistoryCompoRender = 0
export default function ConsultHistory() {
    // consultHistoryCompoRender++
    // console.log('consultHistoryCompoRender', consultHistoryCompoRender)
    const { currentUser, userData } = useAuthContext()
    const [fbData, setFbData] = useState([])
    const [ele, setEle] = useState([])
    const perPage = 10
    const [offset, setOffset] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [showDetail, setShowDetail] = useState({ index: '' })
    // const sampleJson = useMemo(() => sampleJson_ForConsultHistory(), [])
    const styleCell = 'col-span-3 flex items-center border-b border-gray-400 px-2 py-5'
    const styleDetailBlock = 'grid grid-cols-12 mx-4 border-b border-gray-300'
    const styleDetailItemL = 'col-span-4 sm:col-span-3 text-right py-2 pl-4'
    const styleDetailItemR = 'col-span-8 sm:col-span-9 py-2 pl-4'
    const styleChevron = 'w-8 h-8'
    //コード番号で記録されている項目をGUI表示名に変換する処理
    const codeAndDisplayForMatch = useCallback((obj) => {
        const object = {}
        obj.forEach((item) => (object[item.code] = item['display']))
        return object
    }, [])
    const shikuNameMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['shiku_name_options']), [codeAndDisplayForMatch])
    const kongoMikomiMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['kongo_mikomi_options']), [codeAndDisplayForMatch])
    const shubetsuMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['sodansha_shubetsu_options']), [codeAndDisplayForMatch])
    //対象者種別は配列のためループ処理
    const shubetsuMatchList = useCallback(
        (item) => {
            const shubetsuItems = item['sodansha_shubetsu_list'].map((value) => shubetsuMatch[value])
            return shubetsuItems.join('、')
        },
        [shubetsuMatch]
    )
    const toggle = useCallback(
        (index) => {
            if (index !== showDetail.index) {
                setShowDetail({ ...showDetail, index: index })
            } else {
                setShowDetail({ ...showDetail, index: '' })
            }
        },
        [showDetail]
    )
    const history = useHistory()
    const jumpToEditPage = useCallback(
        (id) => {
            history.push(`/editconsult/${id}`)
        },
        [history]
    )
    const eraYear = useCallback((year) => {
        const eraHandler = (yearNow) => {
            const generate = (era, startYear) => {
                let yearDsp = yearNow - startYear + 1
                if (yearDsp === 1) {
                    yearDsp = '元'
                } else {
                    yearDsp = ('00' + yearDsp).slice(-2)
                }
                return `${era}${yearDsp}`
            }
            if (yearNow >= 2019) {
                return generate('令和', 2019)
            }
            if (yearNow >= 1989) {
                return generate('平成', 1989)
            }
            if (yearNow >= 1926) {
                return generate('昭和', 1926)
            }
            if (yearNow >= 1912) {
                return generate('大正', 1912)
            }
            if (yearNow >= 1868) {
                return generate('明治', 1868)
            }
        }
        return eraHandler(year)
    }, [])
    const shubetsuMatchGui = useCallback(
        (item) => {
            const shubetsuItems = []
            for (let i = 0; i < item.length; i++) {
                if (i > 3) {
                    shubetsuItems.push('...')
                    break
                }
                const code = item[i]
                shubetsuItems.push(shubetsuMatch[code])
            }
            return shubetsuItems.join('、')
        },
        [shubetsuMatch]
    )
    useEffect(() => {
        const getData = async () => {
            const getFacilityId = () => {
                return new Promise((resolve, reject) => {
                    userData?.shisetsu_id ? resolve({ shisetsu_id: userData.shisetsu_id, kengen: userData.kengen }) : reject(new Error('施設IDが取得できていません'))
                })
            }
            await getFacilityId()
                .then(({ shisetsu_id, kengen }) => {
                    // console.log({ shisetsu_id, kengen })
                    // const snapshot = db.collectionGroup('disp_id').where('shisetsu_id', '==', id).get()
                    if (shisetsu_id) {
                        let query = db.collection('taishosha_id')
                        if (!['1', '2'].includes(userData.kengen)) {
                            query = query.where('shisetsu_id', '==', shisetsu_id)
                            return query.get()
                        } else if (['1', '2'].includes(userData.kengen)) {
                            return query.orderBy('shokaimendan_date', 'desc').limit(2000).get()
                        }
                    }
                })
                .then((snapshot) => {
                    const organaizeDoc = () => {
                        const docs = []
                        snapshot.forEach((doc) => {
                            // console.log(doc.id, ' => ', doc.data())
                            docs.push(doc.data())
                        })
                        // console.log('Docs', docs)
                        //対象者IDを抽出して重複削除
                        // const nums = docs.map((item) => item['taishosha_id'])
                        // const uniqueNums = [...new Set(nums)]
                        // //対象者ID毎に必要なデータを抽出
                        const organizedDocs = []
                        // uniqueNums.forEach((num) => {
                        //     // console.log(num)
                        //     const objArr = docs.filter((doc) => doc['taishosha_id'] === num)
                        //     const obj = Object.assign({}, ...objArr)
                        //     // console.log('obj', obj)
                        //     const keyNames = ['taishosha_id', 'kanri_no', 'jirei_no', 'uketsuke_date', 'kongo_mikomi', 'csw_name', 'taishosha_name', 'shiku_name', 'sodansha_shubetsu_list']
                        //     const filteredData = Object.assign({}, ...keyNames.map((key) => ({ [key]: obj[key] })))
                        //     const dateStr = filteredData['uketsuke_date']
                        //     filteredData['uketsuke_date'] = `${dateStr.slice(0, 4)}/${dateStr.slice(4, 6)}/${dateStr.slice(6)}`
                        //     organizedDocs.push(filteredData)
                        // })
                        // console.log('organizedDocs:', organizedDocs)
                        docs.forEach((item) => {
                            const date = item['shokaimendan_date']
                            if (typeof date === 'object') {
                                const dateStr = dateFormToData(date.toDate())
                                const warekiYear = eraYear(Number(dateStr.slice(0, 4)))
                                // console.log({ dateStr, warekiYear })
                                item['shokaimendan_date'] = `${dateStr.slice(0, 4)}/${dateStr.slice(4, 6)}/${dateStr.slice(6)}`
                            } else if (typeof date === 'string') {
                                item['shokaimendan_date'] = date
                            } else if (!date) {
                                item['shokaimendan_date'] = '－'
                            }
                            // item['gender'] = !!item['gender'] ? genderMatch(item['gender']) : ''
                            item['nenrei'] = !!item['nenrei'] ? item['nenrei'] : ''
                            const shubetsuFlg = !!item['sodansha_shubetsu_list'] && item['sodansha_shubetsu_list'].length > 0
                            item['sodansha_shubetsu'] = shubetsuFlg ? shubetsuMatchGui(item['sodansha_shubetsu_list']) : ''
                            organizedDocs.push(item)
                        })
                        //日付順に並び替え
                        organizedDocs.sort((a, b) => {
                            const aDate = a['shokaimendan_date'] !== '－' ? a['shokaimendan_date'] : '1800/01/01'
                            const bDate = b['shokaimendan_date'] !== '－' ? b['shokaimendan_date'] : '1800/01/01'
                            // // console.log({ aDate, bDate })
                            // return aDate > bDate ? -1 : 1
                            return aDate > bDate ? -1 : 1
                        })
                        return organizedDocs
                    }
                    // console.log('organizedDocs:', organaizeDoc())
                    setFbData(organaizeDoc())
                })
                .catch((error) => console.log('Error:', error))
        }
        getData()
    }, [userData, eraYear, shubetsuMatchGui])
    //レイアウト確認用サンプル
    // const sampleJson = useMemo(() => sampleJson_ForConsultHistory(), [])
    useEffect(() => {
        const genElement = () => {
            const data = fbData
            //多量データによるレイアウト確認用
            // const data = sampleJson
            // console.log(data)
            const slice = data.slice((offset - 1) * perPage, offset * perPage)
            const postEle = slice.map((item, index) => (
                <li key={`${item.taishosha_id}_container`}>
                    <ul onClick={() => toggle(index)} className='w-full grid grid-cols-11'>
                        <li className={styleCell}>
                            <p>{item.shokaimendan_date}</p>
                        </li>
                        {/* <li className={styleCell}>{item.shokaimendan_date}</li> */}
                        <li className={styleCell}>{item.taishosha_name}</li>
                        <li className={styleCell}>{item.csw_name}</li>
                        <li className='col-span-2 border-b border-gray-400 px-2 py-5 text-gray-600'>
                            {showDetail.index === index ? <ChevronUpIcon className={styleChevron} /> : <ChevronDownIcon className={styleChevron} />}
                        </li>
                    </ul>
                    <ul className={showDetail.index === index ? 'bg-gray-50' : 'hidden'}>
                        <li className={styleDetailBlock}>
                            <p className={styleDetailItemL}>住所</p>
                            <p className={styleDetailItemR}>大阪府{shikuNameMatch[item.shiku_name]}</p>
                        </li>
                        <li className={styleDetailBlock}>
                            <p className={styleDetailItemL}>状態</p>
                            <p className={styleDetailItemR}>{kongoMikomiMatch[item.kongo_mikomi]}</p>
                        </li>
                        <li className={styleDetailBlock}>
                            <p className={styleDetailItemL}>相談者種別</p>
                            <p className={styleDetailItemR}>{item['sodansha_shubetsu']}</p>
                        </li>
                        <li className={styleDetailBlock}>
                            <p className={styleDetailItemL}>事例No</p>
                            <p className={styleDetailItemR}>{item.jirei_no}</p>
                        </li>
                        <li className={styleDetailBlock}>
                            <p className={styleDetailItemL}>管理番号</p>
                            <p className={styleDetailItemR}>{item.kanri_no}</p>
                        </li>
                        <li className='w-full px-4 py-3 flex flex-row justify-end items-end text-teal-600 sm:text-lg' onClick={() => jumpToEditPage(item.taishosha_id)}>
                            <ExternalLinkIcon className='w-8 h-8' />
                            <p>編集ページへ</p>
                        </li>
                    </ul>
                </li>
            ))
            setEle(postEle)
            setPageCount(Math.ceil(data.length / perPage))
        }
        genElement()
    }, [kongoMikomiMatch, shikuNameMatch, shubetsuMatchList, fbData, offset, showDetail, toggle, jumpToEditPage])
    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage + 1)
    }
    return (
        <div className='w-full h-full flex flex-col justify-start items-center'>
            <div className='w-full sm:max-w-2xl pt-4 px-2 sm:mx-8'>
                {/* <div className='cursor-pointer py-1 pl-1 text-xl sm:text-3xl text-gray-600 font-medium' onClick={() => jumpToEditPage('hoge')}>
                    LINK
                </div> */}
                <h2 className='pl-1 text-xl sm:text-3xl text-gray-600 font-medium border-l-4 sm:border-l-8 border-rose-400'>相談履歴</h2>
                <div className='w-full mt-6 table-fixed bg-gray-200 text-sm sm:text-lg text-gray-700'>
                    <ul className='w-full grid grid-cols-11 bg-gray-400 text-left font-bold'>
                        <li className='col-span-3 px-2 py-2'>初回日付</li>
                        <li className='col-span-3 px-2 py-2'>対象者</li>
                        <li className='col-span-3 px-2 py-2'>CSW名</li>
                        <li className='col-span-2 px-2 py-2'>詳細</li>
                    </ul>
                    <ul>{ele}</ul>
                </div>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    )
}
