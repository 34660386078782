import React, { useState, useEffect, useCallback, useRef, createContext } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useHistory } from 'react-router'
import clonedeep from 'lodash.clonedeep'
import { useAuthContext } from '../func'
import { firebase, db, func } from '../func/firebase'
import { NewConsultMasked, NewConsultRecept, NewConsultTarget, NewConsultIntroduction, NewConsultDetail } from '../block'
import { inputData, replaceLineCode, dataToCheckBox, checkBoxToData, dateDataToForm, dateFormToData, getCurrentDate, checkKeyDown, postToCf, feedBackDisplay, genErrorText } from '../func/CommonFunc'
import { Dialog, Modal, Button } from '../atom'
import { defaultValues, fieldsByCategory } from '../json'

// let NewConsultCompoRender = 0
export default function NewConsult(props) {
    // NewConsultCompoRender++
    // console.log('NewConsultCompoRender', NewConsultCompoRender)
    const { userData } = useAuthContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const schema = yup.object().shape({
        uketsuke_date: yup.string().required('受付日は必須項目です'),
        uketsuke_hoho: yup.string().required('受付方法は必須項目です'),
        taishosha_name_kana: yup
            .string()
            .required('対象者名(フリガナ)は必須項目です')
            .matches(/^[ア-ン゛゜ァ-ォャ-ョー「」。、 　]+$/, 'カタカナを入力してください'),
        taishosha_name: yup.string().required('対象者名(氏名)は必須項目です'),
        // .matches(/([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu, '漢字を入力してください'),
        gender: yup.string().required('対象者(性別)は必須項目です'),
        nenrei: yup
            .string()
            .required('年齢は必須項目です')
            .matches(/^([1-9]\d*|0)$/, '0以上の整数を入力してください')
            .max(3, '3文字以下にしてください'),
        shiku_name: yup.string().required('住所(市区町村)は必須項目です'),
        shokai_keiro: yup.string().required('紹介経路は必須項目です'),
        kongo_mikomi: yup.string().required('今後の見込みは必須項目です'),
    })
    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: defaultValues.reception,
    })
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backToHome = useCallback(() => {
        const cancelEdit = () => {
            // console.log('yes')
            // const pathName = history.location.pathname
            // history.replace({ pathname: pathName })
            history.push('/')
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        setShowDialog({
            ...showDialog,
            show: true,
            message: 'ホーム画面へ戻り、編集中の内容は消去します。\nよろしいですか？',
            doYes: cancelEdit,
            doNo: hideDialog,
            textYes: 'はい',
            textNo: 'いいえ',
        })
    }, [history, showDialog])

    //ユーザーデータが無い時の処理
    useEffect(() => {
        const isUserId = 'user_id' in userData
        const isShisetsuId = 'shisetsu_id' in userData
        // console.log({ isUserId, isShisetsuId })
        if (!isUserId) {
            console.error('userIdがありません')
            setSendable(false)
        } else if (!isShisetsuId) {
            console.error('shisetsuIdがありません')
            setSendable(false)
        } else if (isUserId && isShisetsuId) {
            // console.log('userData in NewConsult', userData)
            setSendable({ send: true, reasonText: '' })
        }
    }, [userData])
    const [sendable, setSendable] = useState(false)
    const unSendable = useCallback(() => {
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        const reload = () => {
            window.location.reload()
        }
        const message =
            'ユーザー情報が無いため現在送信できません\nページをリロードするか(入力内容は消去されます)\nウインドウを閉じてしばらくお待ち下さい\n待っても送信できない時はログインし直してみてください'
        setShowDialog({
            ...showDialog,
            show: true,
            message: message,
            doYes: reload,
            doNo: hideDialog,
            textYes: 'リロード',
            textNo: '閉じる',
        })
    }, [showDialog])

    //モーダルウインドウ表示チェック用
    // useEffect(() => {
    // setShowModal({
    //     show: true,
    //     text: '送信中...',
    //     state: 'pending',
    // }
    // }, [])
    const onSubmit = (data) => {
        const cloneData = clonedeep(data)
        const dataForCf = () => {
            const dataReception = fieldsByCategory.reception
            Object.assign(dataReception, cloneData)
            //マスタデータ(バージョン番号,ユーザーID,施設ID,画面ID,処理区分)を追加
            dataReception['version_no'] = '1.001'
            dataReception['user_id'] = userData.user_id
            dataReception['shisetsu_id'] = userData.shisetsu_id
            dataReception['disp_id'] = '10'
            dataReception['shori_div'] = '0'
            //管理番号,対象者IDはCloudFunctionsで処理するので、今は仮番号をつけているが整えば ""にする
            const kanriNum = ('000000' + Math.floor(Math.random() * 1000000)).slice(-6)
            dataReception['kanri_no'] = `99${kanriNum}`
            const taishoshaNum = ('000' + Math.floor(Math.random() * 1000)).slice(-3)
            dataReception['taishosha_id'] = `00${taishoshaNum}`
            //事例番号は消去
            // dataReception['jirei_no'] = ''
            delete dataReception['jirei_no']
            //都道府県を削除(大阪で固定、送信しない)
            if ('todouhuken' in dataReception) delete dataReception['todouhuken']
            //日付を追加,書き換え(初回面談日＝受付日)
            dataReception['update_date'] = getCurrentDate()
            dataReception['uketsuke_date'] = dateFormToData(dataReception['uketsuke_date'])
            dataReception['birthday'] = dateFormToData(dataReception['birthday'])
            dataReception['shokaimendan_date'] = dataReception['uketsuke_date']
            //CheckBoxデータを整形 sodansha_shubetsu_listに入れ、元のプロパティを消去
            const items = cloneData['sodansha_shubetsu']
            dataReception['sodansha_shubetsu_list'] = checkBoxToData(items, 'sodansha_shubetsu')
            delete dataReception['sodansha_shubetsu']
            //改行コードを整形
            dataReception['shokaitaio'] = replaceLineCode(dataReception['shokaitaio'])
            dataReception['adr_dtl'] = replaceLineCode(dataReception['adr_dtl'])
            dataReception['shokai_naiyo'] = replaceLineCode(dataReception['shokai_naiyo'])
            // const mergeData = { data: dataReception }
            // console.log('mergedData: ', mergeData)
            // return mergeData
            console.log('dataReception: ', dataReception)
            return dataReception
        }
        const postToCf = async () => {
            const sendData = dataForCf()
            const functions = firebase.functions('asia-northeast2')
            const cfFunc = functions.httpsCallable('AcceptFromSmartphone_and_SendToConduct_v1')
            const response = cfFunc(sendData)
                .then((body) => {
                    const res = body.data
                    console.log('res from cf:', res)
                    const flg = res === 'FirestoreWriteOK'
                    // console.log('res judge', flg)
                    if (flg) {
                        console.log('Data Post Success')
                    } else {
                        console.error('Data Post Failed', res)
                    }
                    return res
                })
                .catch((error) => {
                    console.error('PostToCf Error:', error)
                    return new Error(error)
                })
            return response
        }
        //commitToFBで書き込んだ際のフィードバックをモーダルウインドウに表示する
        const feedBackFromFb = async () => {
            //モーダルウインドウComponent(進行状況を示す)にプロパティを渡して表示
            setShowModal({
                show: true,
                text: '送信中...',
                state: 'pending',
            })
            await postToCf()
                .then((res) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const flg = res === 'FirestoreWriteOK'
                            // console.log('res judge', flg)
                            if (flg) {
                                // console.log('res is OK')
                                resolve(res)
                            } else {
                                // console.error('res is NG')
                                reject(res)
                            }
                        }, 1500)
                    })
                })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        console.log('送信完了')
                        setShowModal({
                            show: true,
                            text: '送信しました',
                            state: 'fulfilled',
                        })
                        resolve()
                        // reject()
                    })
                })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            setShowModal({
                                show: false,
                            })
                            history.push('/')
                            resolve()
                            // reject()
                        }, 1500)
                    })
                })
                .catch((error) => {
                    console.error('res is NG :', error)
                    console.error('res is NG :', error)
                    const closeModal = () => {
                        setShowModal({ show: false })
                    }
                    setShowModal({
                        show: true,
                        text: '送信できませんでした',
                        errorText: genErrorText(error),
                        state: 'rejected',
                        doOk: closeModal,
                    })
                    // setShowModal({
                    //     show: true,
                    //     text: '送信できませんでした',
                    //     errorText: genErrorText(error),
                    //     state: 'rejected',
                    // })
                    // setTimeout(() => {
                    //     setShowModal({
                    //         show: false,
                    //     })
                    //     // onFocus()
                    // }, 5000)
                })
        }
        // createDocumentID()
        // dataForCf()
        // postToCf()
        feedBackFromFb()
    }
    return (
        <div
            className='
            w-full
            flex flex-col items-center
        '
        >
            <Modal showModal={showModal} />
            <Dialog showDialog={showDialog} />
            {/* <Button name='modalButton' type='button' value='モーダル表示' onClick={toggleModal} /> */}
            <FormProvider {...methods}>
                <form
                    className='w-full mb-4 px-2 sm:max-w-2xl sm:mx-8 flex flex-col items-center'
                    name='NewConsultReceptForm'
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    {/* <NewConsultMasked /> */}
                    <NewConsultRecept />
                    <NewConsultTarget />
                    <NewConsultIntroduction />
                    <NewConsultDetail />
                    <div className='pt-6 pb-4 max-w-xl ml-auto flex flex-col items-end sm:flex-row sm:justify-end flex-wrap gap-x-4 gap-y-3'>
                        {/* <Button name='sampleButton' type='button' value='サンプルデータを入力' onClick={inputSampleData} outline={true} color='teal-600' /> */}
                        {/* <Button type='button' value='リセット' onClick={''} outline={true} color='teal-600' /> */}
                        {sendable ? <Button type='submit' value='送信' /> : <Button type='button' value='送信' color='gray-400' onClick={unSendable} />}
                        <Button type='button' value='キャンセル' onClick={backToHome} outline={true} />
                    </div>
                </form>
                {/* <DevTool control={methods.control} /> */}
            </FormProvider>
        </div>
    )
}
