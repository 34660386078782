import React from 'react'

export default function FormBlock({ formItems, categoryName, display = true }) {
    if (formItems.length === 0) return null
    const listItems = formItems.map((item, index) => (
        <React.Fragment key={index}>
            <li
                key={`list_${index}`}
                className='
                    mt-2 border-solid border-b border-light-blue-500 last:border-b-0
                '
            >
                <>{item}</>
            </li>
        </React.Fragment>
    ))
    const style = `
        mt-4 w-full p-2 grid grid-cols-1
        rounded-lg border border-gray-400 bg-gray-50
    `
    const blockStyle = display ? style : `${style} hidden`
    return (
        <div className={blockStyle}>
            {!!categoryName ? (
                <div
                    className='
                    flex justify-start items-center text-left
                    p-2  border-b border-gray-500
                    text-2xl sm:text-3xl font-bold text-gray-700  break-all
            '
                >
                    {categoryName}
                </div>
            ) : (
                ''
            )}
            <ul
                className='
                p-4 text-2xl
            '
            >
                {listItems}
            </ul>
        </div>
    )
}
