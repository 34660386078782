import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CheckBox, InputText, TextArea, RadioButton, FormBlock } from '../atom'
import { SwitchDisplay } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let SptCDBlockRender = 0
export default function SupportContentsDetail(props) {
    // SptCDBlockRender++
    // console.log('SptCDBlockRender', SptCDBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const sodansha_shubetsu_options = selectionOptions.sodansha_shubetsu_options
    const seiho_kbn_options = selectionOptions.seiho_kbn_options
    const sodansha_keyword_options = selectionOptions.sodansha_keyword_options
    const shushi_jokyo_options = selectionOptions.shushi_jokyo_options
    const yokaigodo_options = selectionOptions.yokaigodo_options
    const contents = [
        <CheckBox
            label='相談者種別(複数選択可)'
            name='sodansha_shubetsu_sptc'
            id='sodansha_shubetsu_sptc'
            options={sodansha_shubetsu_options}
            // errorText={errors.sodansha_shubetsu_sptc?.message}
            // isRequired={true}
        />,
        <RadioButton
            label='相談者種別-生活保護'
            name='seiho_kbn_sptc'
            id='seiho_kbn_sptc'
            register={register('seiho_kbn_sptc', {})}
            options={seiho_kbn_options}
            // errorText={errors.seiho_kbn_sptc?.message}
            // isRequired={true}
        />,
        <InputText
            label='相談者種別-その他'
            name='sodansha_shubetsu_sonota_sptc'
            id='sodansha_shubetsu_sonota_sptc'
            register={register('sodansha_shubetsu_sonota_sptc', {})}
            type='text'
            // errorText={errors.sodansha_shubetsu_sonota_sptc_etc?.message}
            placeholder=''
            maxLength='500'
        />,
        <CheckBox
            label='相談者・内容に関連するキーワード'
            name='sodansha_keyword'
            id='sodansha_keyword'
            options={sodansha_keyword_options}
            // errorText={errors.sodansha_keyword?.message}
            // isRequired={true}
        />,
        <CheckBox
            label='収支状況'
            name='shushi_jokyo'
            id='shushi_jokyo'
            options={shushi_jokyo_options}
            // errorText={errors.shushi_jokyo?.message}
            // isRequired={true}
            subText={`※この項目で「生活保護」は選択できません。\n先頭にある「相談者種別(複数選択可)」の「生活保護」項目を選択するとこちらも自動で選択されます。`}
        />,
        <RadioButton
            label='収支状況-生活保護'
            name='seiho_kbn_shushi'
            id='seiho_kbn_shushi'
            register={register('seiho_kbn_shushi', {})}
            options={seiho_kbn_options}
            // errorText={errors.seiho_kbn_shushi?.message}
            // isRequired={true}
            subText={`※この項目は選択できません。\n「相談者種別-生活保護」項目を選択するとこちらも自動で選択されます。`}
        />,
        <InputText
            label='収支状況-その他'
            name='shushi_jokyo_sonota'
            id='shushi_jokyo_sonota'
            register={register('shushi_jokyo_sonota', {})}
            type='text'
            // errorText={errors.shushi_jokyo_sonota_etc?.message}
            placeholder=''
            maxLength='500'
        />,
        <TextArea
            label='仕事'
            name='job'
            id='job'
            register={register('job', {})}
            errorText={errors.job?.message}
            // placeholder='タッチすると拡大表示します'
            // isRequired={true}
            // expandSize={true}
            printDisplay={{ display: true, countByLine: 20, lines: 9 }}
        />,
        <TextArea
            label='月の収入・支出'
            name='shunyu_shishutsu_free'
            id='shunyu_shishutsu_free'
            register={register('shunyu_shishutsu_free', {})}
            errorText={errors.shunyu_shishutsu_free?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            printDisplay={{ display: true, countByLine: 43, lines: 5 }}
        />,
    ]
    const shubetsu_defaultValue = sodansha_shubetsu_options.map((v) => false)
    const shubetsu_watch = useWatch({
        control,
        name: 'sodansha_shubetsu_sptc',
        defaultValue: shubetsu_defaultValue,
    })
    const shushi_defaultValue = shushi_jokyo_options.map((v) => false)
    const shushi_watch = useWatch({
        control,
        name: 'shushi_jokyo',
        defaultValue: shushi_defaultValue,
    })
    const seiho_watch = useWatch({ control, name: 'seiho_kbn_sptc', defaultValue: seiho_kbn_options })
    const seiho_shushi_watch = useWatch({ control, name: 'seiho_kbn_shushi', defaultValue: seiho_kbn_options })
    useEffect(() => {
        // console.log('生保状況 effect loaded')
        //「種別」から「収支状況」へ、生活保護の状態をコピー
        //同期は無理なので一方向からのコピーのみとする
        const shubetsuFlg = shubetsu_watch?.includes('009')
        const shushiFlg = shushi_watch?.includes('005')
        if (shubetsuFlg && !shushiFlg) {
            const newValueShushi = getValues('shushi_jokyo')
            newValueShushi[4] = '005'
            setValue('shushi_jokyo', newValueShushi)
        } else if (!shubetsuFlg && shushiFlg) {
            const newValueShushi = getValues('shushi_jokyo')
            newValueShushi[4] = false
            setValue('shushi_jokyo', newValueShushi)
        }
    }, [shubetsu_watch, shushi_watch, getValues, setValue])
    useEffect(() => {
        // console.log('生保区分 effect loaded')
        //「種別-生保区分」から「収支状況-生保区分」へ、状態をコピー
        const walfareFlg = seiho_watch !== seiho_shushi_watch
        if (walfareFlg) {
            setValue('seiho_kbn_shushi', seiho_watch)
        }
    }, [seiho_watch, seiho_shushi_watch, getValues, setValue])
    useEffect(() => {
        // console.log('SwitchDisplay effect loaded')
        // console.log('shubetsu_watch', shubetsu_watch)
        const shubetsuWelfareArg = {
            watchValue: shubetsu_watch,
            targetName: 'seiho_kbn_sptc',
            targetId: 'seiho_kbn_sptc',
            checkValue: '009',
            // focusMethod: setFocus, //setFocusできないため省略
            setValueMethod: setValue,
        }
        // const shubetsuCommentArg = {
        //     watchValue: shubetsu_watch,
        //     targetName: 'sodansha_shubetsu_sonota_sptc',
        //     targetId: 'sodansha_shubetsu_sonota_sptc',
        //     checkValue: '999',
        //     // focusMethod: setFocus,
        //     setValueMethod: setValue,
        // }
        SwitchDisplay(shubetsuWelfareArg)
        // SwitchDisplay(shubetsuCommentArg)
    }, [shubetsu_watch, getValues, setValue, setFocus])
    useEffect(() => {
        // console.log('shushi_watch', shushi_watch)
        const shushiWelfareArg = {
            watchValue: shushi_watch,
            targetName: 'seiho_kbn_shushi',
            targetId: 'seiho_kbn_shushi',
            checkValue: '005',
            // focusMethod: setFocus, //setFocusできないため省略
            setValueMethod: setValue,
        }
        // const shushiCommentArg = {
        //     watchValue: shushi_watch,
        //     targetName: 'shushi_jokyo_sonota',
        //     targetId: 'shushi_jokyo_sonota',
        //     checkValue: '999',
        //     // focusMethod: setFocus,
        //     setValueMethod: setValue,
        // }
        SwitchDisplay(shushiWelfareArg)
        // SwitchDisplay(shushiCommentArg)
    }, [shushi_watch, getValues, setValue, setFocus])

    return (
        <>
            <FormBlock categoryName='相談内容の詳細' formItems={contents} />
        </>
    )
}
