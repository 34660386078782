import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import PrivateRoute from './auth/PrivateRoute'
// import { AuthProvider } from './auth/AuthProvider'
import { PrivateRoute, AuthProvider } from './func'
import { Top, Login } from './page'

function App() {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <PrivateRoute exact path='/' component={Top} />
                    <Route exact path='/login' component={Login} />
                </Switch>
            </Router>
        </AuthProvider>
    )
}

export default App
/* eslint-enable */
