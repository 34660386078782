import React, { useEffect, useMemo } from 'react'
import { useFormState, useFormContext, useWatch } from 'react-hook-form'
import { InputText, SelectBox, TextArea } from '.'
import { selectionOptions } from '../json'

let zipAddrRender = 0
export default function AutoInputZipToAddress({ name, nameZip, namePref, nameCity, nameTown, phZip, phAddr, printDisplay = { display: false, countByLine: '', lines: '' } }) {
    zipAddrRender++
    // console.log('zipAddrRender', zipAddrRender)
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        control,
    } = useFormContext()
    const { dirtyFields } = useFormState({ control })
    // console.log('Zip is Dirty?:', dirtyFields[nameZip])
    const nameZipWatch = useWatch({ control, name: nameZip, defaultValue: '' })
    const getZipcode = nameZipWatch ? getValues(nameZip) : ''
    const cityOptions = selectionOptions.shiku_name_options

    useEffect(() => {
        const inputAddress = async () => {
            // console.log({ zipcode: getZipcode })
            // fetch('https://jsonplaceholder.typicode.com/todos/1')
            // .then((response) => response.json())
            // .then((json) => setValue(nameTown, JSON.stringify(json)))
            const selectCity = (city) => {
                const index = cityOptions.findIndex((ele) => ele.display === city)
                return index !== -1 ? cityOptions[index].code : ''
            }
            const url = 'https://api.zipaddress.net/?zipcode='
            await fetch(url + getZipcode)
                .then((res) => {
                    return res.json()
                })
                .then((json) => {
                    setValue(namePref, json.data?.pref)
                    // setValue(nameCity, json.data.city)
                    setValue(nameCity, selectCity(json.data?.city))
                    setValue(nameTown, json.data?.town)
                    // setFocus(nameTown)
                })
                .catch((error) => console.error(error))
        }
        // console.log('zipcode is Dirty?', dirtyFields[nameZip])
        if (getZipcode.length > 6 && dirtyFields[nameZip] === true) {
            inputAddress()
        }
    }, [cityOptions, nameZipWatch, getZipcode, nameCity, namePref, nameTown, nameZip, setValue, dirtyFields])
    return (
        <div id={`${name}_parent`}>
            <InputText
                label='郵便番号'
                name={nameZip}
                register={register(nameZip, { minLength: 7 })}
                type='text'
                // errorText={errors.zipcode?.message}
                placeholder={phZip}
                subText={'例)542-0065\n※郵便番号を入れると住所が町名まで自動入力されます'}
                // onChange={hoge()}
                control={control}
                maxLength='8'
                // isRequired={true}
            />
            <InputText
                label='都道府県'
                name={namePref}
                register={register(namePref, {})}
                type='text'
                // errorText={errors.address?.message}
                placeholder={phAddr}
                isReadOnly={true}
                // isRequired={true}
            />
            <SelectBox
                label='市区町村'
                name={nameCity}
                register={register(nameCity, {})}
                options={cityOptions}
                firstOption='--- 選択してください ---'
                errorText={errors[nameCity]?.message}
                placeholder={phAddr}
                isRequired={true}
            />
            <TextArea
                label='番地以降'
                name={nameTown}
                register={register(nameTown, {})}
                // errorText={errors.address?.message}
                placeholder={phAddr}
                expandSize={false}
                printDisplay={printDisplay.display && { display: printDisplay.display, countByLine: printDisplay.countByLine, lines: printDisplay.lines }}
                // isRequired={true}
            />
        </div>
    )
}
