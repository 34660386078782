import React, { useState, useContext } from 'react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline'

export default function Modal({ showModal }) {
    const style = {
        container: 'justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none',
        windowHeader: 'w-full h-4 rounded-t-lg xborder-0 outline-none focus:outline-none',
        windowContainer: 'w-full relative flex flex-col border-0 rounded-lg shadow-lg  bg-gray-50 outline-none focus:outline-none',
        contentsContainer: 'px-5 py-3 sm:px-10 sm:py-6 flex flex-col items-center text-lg sm:text-2xl',
        loaderIcon: 'loader ease-linear rounded-full border-4 border-t-4 border-gray-200 w-10 h-10 sm:w-14 sm:h-14 mb-4',
        checkedIcon: 'w-12 h-12 sm:w-16 sm:h-16 text-teal-400 mb-4',
        exCircleIcon: 'w-12 h-12 sm:w-16 sm:h-16 text-rose-400 mb-4',
        text: 'relative flex-auto text-gray-500 font-bold',
        errorContainer: 'relative pt-2 text-center',
        errorText: 'text-rose-400 text-sm sm:text-lg whitespace-pre-wrap',
        errorButton: 'mt-4 px-8 sm:px-12 py-1 sm:py-2 rounded-lg text-center font-bold text-lg sm:text-2xl bg-rose-400 text-gray-50',
        background: 'opacity-25 fixed inset-0 z-40 bg-black',
    }
    const showIcon = () => {
        if (showModal.state === 'pending') {
            return <div className={style.loaderIcon}></div>
        } else if (showModal.state === 'fulfilled') {
            return <CheckCircleIcon className={style.checkedIcon} />
        } else if (showModal.state === 'rejected') {
            return <ExclamationCircleIcon className={style.exCircleIcon} />
        } else {
            return
        }
        // アイコンチェック用
        // return (
        //     <>
        //         <div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 w-14 h-14 mb-4'></div>
        //         <CheckCircleIcon className='w-16 h-16 text-teal-400 mb-4' />
        //         <ExclamationCircleIcon className='w-16 h-16 text-rose-400 mb-4' />
        //     </>
        // )
    }
    return (
        <>
            {/* <button
                className='bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                type='button'
                onClick={() => setShowModal(true)}
            >
                Open small modal
            </button> */}
            {showModal.show ? (
                <>
                    <div className={style.container}>
                        <div className='relative w-3/4 sm:max-w-sm'>
                            {/*content*/}
                            <div className={style.windowContainer}>
                                {/* header */}
                                {<span className={showModal.state === 'rejected' ? `${style.windowHeader} bg-rose-400` : `${style.windowHeader} bg-teal-400`}></span>}
                                {/*body*/}
                                <div className={style.contentsContainer}>
                                    {/* <div class='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4'></div>
                                    <CheckCircleIcon className='w-16 h-16 text-teal-400 mb-4' />
                                    <ExclamationCircleIcon className='w-16 h-16 text-rose-400 mb-4' /> */}
                                    {showIcon()}
                                    <div className={style.text}>{showModal.text}</div>
                                    {showModal.errorText ? (
                                        <div className={style.errorContainer}>
                                            <p className={style.errorText}>{showModal.errorText}</p>
                                            {showModal.doOk && (
                                                <button type='button' className={style.errorButton} onClick={() => showModal.doOk()}>
                                                    OK
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.background}></div>
                </>
            ) : null}
        </>
    )
}
