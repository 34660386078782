import React, { useState, useEffect, useCallback } from 'react'
import { ClipboardCopyIcon, CheckCircleIcon } from '@heroicons/react/outline'

export default function PastCaseCopyBlock({ dispId, field, value, copyFlg }) {
    const [isCopy, setIsCopy] = useState(false)
    // console.log({ dispId, field, value })
    const style = {
        container: 'col-span-2 sm:col-span-2 flex flex-col pr-1 items-center text-xs sm:text-sm',
        copyIcon: 'w-7 h-7 sm:w-9 sm:h-9  text-gray-400',
        checkIcon: 'w-7 h-7 sm:w-9 sm:h-9 text-teal-600',
    }
    useEffect(() => {
        if (isCopy) {
            const json = localStorage.getItem('pastCaseData')
            const obj = json ? JSON.parse(json) : {}
            // console.log({ json, obj })
            // console.log('dispid in obj?', obj[dispId])
            // console.log(!!json, !obj[dispId])
            if (!obj[dispId]) obj[dispId] = {}
            obj[dispId][field] = value
            localStorage.setItem('pastCaseData', JSON.stringify(obj))
            // console.log('Data Copied To LocalStorage')
        }
    }, [isCopy, dispId, field, value])
    const copyText = useCallback(() => {
        if (value) setIsCopy(true)
    }, [value])

    return (
        <div className={style.container} onClick={() => copyText()}>
            {!isCopy ? <ClipboardCopyIcon className={style.copyIcon} /> : <CheckCircleIcon className={style.checkIcon} />}
        </div>
    )
}
