import React, { useEffect, useState, useContext } from 'react'
import { firebase, db, func, auth } from '../func/firebase'
import { Modal } from '../atom'

const AuthContext = React.createContext()

const useAuthContext = () => {
    return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const delay = (ms) =>
        new Promise((resolve, reject) => {
            // console.log('待機中...')
            setTimeout(() => {
                resolve()
                // reject()
            }, ms)
        })
    //モーダル表示確認
    // useEffect(() => {
    //     setShowModal({
    //         show: true,
    //         text: 'ログイン中',
    //         state: 'fulfilled',
    //         errorText: 'ユーザー名またはパスワードが一致しません',
    //     })
    // }, [])
    const login = async (uid, password, history) => {
        try {
            setShowModal({
                show: true,
                text: 'ログイン中...',
                state: 'pending',
            })
            // console.log('ログイン中...')
            const functions = firebase.functions('asia-northeast2')
            const authFunc = functions.httpsCallable('Auth')
            const isIdMatch = (array, id) => array.some((v) => id.includes(v))
            //1秒待機後ログイン処理開始
            await delay(1000)
                .then(() => {
                    //テスト用
                    // const kymIds = ['kyotomarc1', 'kyotomarc2', 'dxsupport1', 'syakouken']
                    // const conIds = [...Array(9)].map((v, i) => `conduct${('00' + (i + 1)).slice(-3)}`)
                    // conIds.push('conknrtest')
                    // const testIds = [...Array(13)].map((v, i) => `test${('0' + (i + 1)).slice(-2)}`)
                    //メンテ用
                    // const kymIds = ['kyotomarc1', 'kyotomarc2', 'syakouken']
                    // const conIds = ['conduct01', 'cndct_user', 'conduct2']
                    // const testIds = ['test12', 'test13']
                    // // console.log('Developer Account?',isIdMatch([...kymIds, ...conIds],uid))
                    // // console.log('Test Account?',isIdMatch(testIds,uid))
                    // if (isIdMatch([...kymIds, ...conIds, ...testIds], uid)) {
                    //     const authFuncRes = authFunc({ uid: uid, password: password })
                    //     // console.log('response: ', authFuncRes)
                    //     return authFuncRes
                    //     // } else if (isIdMatch(testIds, uid)) {
                    //     //     throw new Error('モニターテスト用アカウントでは現在ログインできません')
                    // } else {
                    //     throw new Error('ログイン許可されていないアカウントです')
                    // }
                    //本番用
                    const testIds = ['test04', 'test12', 'test13']
                    if (isIdMatch([...testIds], uid)) {
                        throw new Error('モニターテスト用アカウントでは現在ログインできません')
                    } else {
                        const authFuncRes = authFunc({ uid: uid, password: password })
                        // console.log('response: ', authFuncRes)
                        return authFuncRes
                    }
                })
                .then((customToken) => {
                    console.log('ログイン成功')
                    // console.log('CustomToken: ', customToken)
                    setShowModal({
                        show: true,
                        text: 'ログインしました',
                        state: 'fulfilled',
                    })
                    auth.signInWithCustomToken(customToken.data)
                })
                .then(() => delay(1500))
                .then(() => {
                    setShowModal({
                        show: false,
                    })
                    const lastTouchedTime = new Date()
                    window.localStorage.setItem('lastTouchedTime', lastTouchedTime)
                    auth.onAuthStateChanged((user) => setCurrentUser(user))
                    history.push('/')
                    return new Promise((resolve, reject) => {
                        resolve()
                    })
                })
        } catch (error) {
            const errorCode = error?.code
            const errorMessage = error?.message
            console.error({ ErrorCode: errorCode, ErrorMessage: errorMessage })
            setShowModal({
                show: true,
                text: 'ログインできませんでした',
                state: 'rejected',
                errorText: errorMessage,
            })
            setTimeout(() => {
                setShowModal({
                    show: false,
                })
            }, 2000)
        }
    }
    //初回アクセス時に認証済みかチェック
    useEffect(() => {
        //DBからユーザー情報をget
        const getUserData = async (user) => {
            const getUserId = () => {
                return new Promise((resolve, reject) => {
                    user?.uid ? resolve(user.uid) : reject(new Error('ユーザーIDが取得できていません'))
                })
            }
            getUserId()
                .then((id) => {
                    // console.log('uId', id)
                    const ref = db.collectionGroup('shisetsu_id')
                    const snapshot = ref.where('login_id', '==', id).get()
                    if (id) return snapshot
                })
                .then((snapshot) => {
                    const userDoc = {}
                    snapshot.forEach((doc) => {
                        // console.log(doc.id, ' => ', doc.data())
                        userDoc['shisetsu_id'] = doc.id
                        userDoc['kengen'] = doc.data()['kengen']
                        userDoc['user_id'] = doc.data()['login_id']
                        userDoc['shisetsu_name'] = doc.data()['shisetsu_name']
                    })
                    // console.log('userData', userDoc)
                    setUserData(userDoc)
                })
                .catch((error) => {
                    console.log('Error:', error)
                    console.log('ユーザーIDと一致するドキュメントが見つかりません')
                })
        }
        const unsubscribed = auth.onAuthStateChanged((user) => {
            // console.log('User: ', user)
            if (user) {
                setCurrentUser(user)
                getUserData(user)
            }
            //setCurrentUserの後にLoadingを置く
            setLoading(false)
        })
        return () => {
            unsubscribed()
        }
    }, [])

    return (
        <>
            <Modal showModal={showModal} />
            <AuthContext.Provider value={{ login, currentUser, setCurrentUser, userData, setUserData }}>{!loading && children}</AuthContext.Provider>
        </>
    )
}

export { useAuthContext, AuthProvider }
