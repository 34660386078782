import { useState, useEffect, useCallback } from 'react'
import Div100vh from 'react-div-100vh'
import { BrowserRouter, HashRouter, Route, Switch, Link, NavLink, useParams, useRouteMatch, useLocation, useHistory, Redirect } from 'react-router-dom'
import { HomeIcon, DocumentAddIcon, BookOpenIcon, SearchIcon, QuestionMarkCircleIcon, MailIcon, BellIcon } from '@heroicons/react/outline'
import { firebase, auth } from '../func/firebase'
import { useAuthContext } from '../func'
import { Home, NewConsult, ConsultHistory, EditConsult, PastCaseSearch, UserManual, Contact, Debug, News } from '.'
import { Header, Footer } from '../container'
import { Drawer, Modal, Button, TextArea } from '../atom'
import TestRecaptcha from '../test/TestRecaptcha'
// let TopPageRender = 0
export default function Top() {
    // TopPageRender++
    // console.log('TopPageRender', TopPageRender)
    //CloudFunctions動作チェック
    // const cfCheck = async () => {
    //     const latestControlNumber = func.httpsCallable('createControlNumber')
    //     const controlNumber = await latestControlNumber()
    //     console.log(controlNumber)
    // }
    const { userData, setUserData } = useAuthContext()
    const [isOpen, setIsOpen] = useState(false)
    const [showTab, setShowTab] = useState(true)
    const [tabIndex, setTabIndex] = useState(0)
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const history = useHistory()
    const location = useLocation()
    const handleLogout = () => {
        auth.signOut()
        history.push('/login')
        window.localStorage.removeItem('lastTouchedTime')
        window.localStorage.removeItem('pastCaseData')
        console.log('ログアウトしました')
    }
    //自動ログアウト(最後にページ遷移した時間を参照)
    useEffect(() => {
        const autoLogout = () => {
            const lastTouchedTime = window.localStorage.getItem('lastTouchedTime')
            // console.log('最後のログイン時間/データ型', new Date(lastTouchedTime), typeof lastTouchedTime)
            let expirationTime = new Date(lastTouchedTime)
            //有効期限時間を設定(20分、デバッグ用6時間/30秒)
            expirationTime = new Date(expirationTime.setMinutes(expirationTime.getMinutes() + 20))
            // expirationTime = new Date(expirationTime.setHours(expirationTime.getHours() + 6))
            // expirationTime = new Date(expirationTime.setHours(expirationTime.getSeconds() + 30))
            const expirationFlg = !!lastTouchedTime && new Date() > expirationTime
            // console.log('ログイン有効期限', expirationTime)
            // console.log('期限を超過している？', expirationFlg)
            if (expirationFlg) {
                console.log('ログアウトしました')
                setShowModal({
                    show: true,
                    text: '自動ログアウトしました',
                    state: 'rejected',
                    errorText: '最後の操作から20分経過したため、ログイン有効期限を過ぎました',
                })
                setTimeout(() => {
                    setShowModal({
                        show: false,
                    })
                    auth.signOut()
                    history.push('/login')
                    window.localStorage.removeItem('lastTouchedTime')
                    window.localStorage.removeItem('pastCaseData')
                    // window.location.reload()
                }, 5000)
            }
        }
        autoLogout()
    }, [history, location])
    useEffect(() => {
        const lastTouchTime = () => {
            const nowDate = new Date()
            window.localStorage.setItem('lastTouchedTime', nowDate)
            // const lastTouchedTime = window.localStorage.getItem('lastTouchedTime')
            // console.log({ nowDate, lastTouchedTime })
        }
        const tabDisplay = () => {
            // console.log('Location:', location)
            const hashPath = location.hash
            const dispFlg = hashPath === '#/' || hashPath === '#/pastcasesearch' || hashPath === '#/usermanual' || hashPath === '#/contact' || hashPath === '#/debug'
            // console.log('dispFlg:', dispFlg)
            if (dispFlg) {
                // console.log('tabIndex', tabIndex)
                setTabIndex(0)
                setShowTab(false)
            } else {
                setShowTab(true)
            }
        }
        tabDisplay()
        lastTouchTime()
    }, [location])
    const reloadHistory = useCallback(() => {
        setUserData({ ...userData })
    }, [userData, setUserData])
    return (
        <HashRouter>
            <Div100vh>
                <Modal showModal={showModal} />
                <div className={showTab ? 'page' : 'page-notab'}>
                    <Header setIsOpen={setIsOpen} />
                    <nav className=''>
                        <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
                            <ul className='flex flex-col items-end space-y-8 sm:space-y-12 text-xl sm:text-3xl '>
                                <li>
                                    <Link to='/' className='flex flex-row justify-center items-center'>
                                        <HomeIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' />
                                        <div onClick={() => setIsOpen(false)}>ホーム</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/newconsult' className='flex flex-row justify-center items-center'>
                                        <DocumentAddIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' />
                                        <div onClick={() => setIsOpen(false)}>新規相談受付</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/consulthistory' className='flex flex-row justify-center items-center'>
                                        <BookOpenIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' />
                                        <div
                                            onClick={() => {
                                                setIsOpen(false)
                                                reloadHistory()
                                            }}
                                        >
                                            相談履歴
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/pastcasesearch' className='flex flex-row justify-center items-center'>
                                        <SearchIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' />
                                        <div onClick={() => setIsOpen(false)}>過去事例検索</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/usermanual' className='flex flex-row justify-center items-center'>
                                        <QuestionMarkCircleIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' />
                                        <div onClick={() => setIsOpen(false)}>アプリの使い方</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/contact' className='flex flex-row justify-center items-center'>
                                        <MailIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' />
                                        <div onClick={() => setIsOpen(false)}>お問い合わせ</div>
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to='/news' className='flex flex-row justify-center items-center'>
                                        <BellIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' />
                                        <div onClick={() => setIsOpen(false)}>お知らせ</div>
                                    </Link>
                                </li> */}
                                {['1', '2'].includes(userData.kengen) && (
                                    <li>
                                        <Link to='/debug' className='flex flex-row justify-center items-center'>
                                            {/* <SearchIcon className='mr-2 w-6 h-6 sm:mr-4 sm:w-8 sm:h-8' /> */}
                                            <div onClick={() => setIsOpen(false)}>デバッグ</div>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                            <div id='logoutButton' className={`pt-4 border-t border-gray-600 text-right text-xl text-rose-600 font-bold`} onClick={() => handleLogout()}>
                                ログアウト
                            </div>
                        </Drawer>
                    </nav>
                    <main className='pb-8'>
                        <div className={tabIndex === 0 ? 'w-full h-full' : 'hidden'}>
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route path='/newconsult' component={NewConsult} />
                                <Route path='/consulthistory' component={ConsultHistory} />
                                <Route path='/pastcasesearch' component={PastCaseSearch} />
                                <Route path='/editconsult/:id' component={EditConsult} />
                                <Route path='/usermanual' component={UserManual} />
                                <Route path='/contact' component={Contact} />
                                <Route path='/news' component={News} />
                                {['1', '2'].includes(userData.kengen) && <Route path='/debug' component={Debug} />}
                                <Route path='/rec' component={TestRecaptcha} />
                                <Route>
                                    <NotFound />
                                </Route>
                            </Switch>
                        </div>
                        {showTab && (
                            <div className={tabIndex === 1 ? 'w-full h-full' : 'hidden'}>
                                <PastCaseSearch />
                            </div>
                        )}
                        {/* <div className={tabIndex === 2 ? 'w-full h-full' : 'hidden'}>
                            <div className='w-full h-full px-12 flex justify-center items-center text-3xl'>相談内容に関連する社会資源情報</div>
                        </div> */}
                    </main>
                    {showTab && <Footer tabIndex={tabIndex} setTabIndex={setTabIndex} />}
                </div>
            </Div100vh>
        </HashRouter>
    )
}

function NotFound() {
    return (
        <div className='w-full h-full flex flex-col justify-center items-center text-3xl'>
            <h2>Not Found Page</h2>
            <div className='w-0 h-0 bg-rose-500'></div>
        </div>
    )
}
