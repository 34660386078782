import { useFormContext, useWatch } from 'react-hook-form'
import { InputDate, InputText, TextArea, FormBlock } from '../atom'

// let SptRTBlockRender = 0
export default function SupportRecordTerm(props) {
    // SptRTBlockRender++
    // console.log('SptRTBlockRender', SptRTBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const style = {
        label: 'text-lg sm:text-2xl text-gray-600',
        inputParent: 'mt-2 mb-4 flex flex-row items-end',
        input: 'w-20 p-2 rounded-lg bg-gray-200 border border-gray-400 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent',
        nextText: 'pl-2 pr-4 text-lg',
        error: 'text-right',
        errorText: 'text-sm sm:text-base text-rose-400',
    }
    const supportTermEle = (
        <div>
            <label className={style.label}>支援期間</label>
            <div className={style.inputParent}>
                <input id='shien_months' name='shien_months' className={style.input} type='text' placeholder='' maxLength='3' inputMode='numeric' control={control} {...register('shien_months', {})} />
                <p className={style.nextText}>ヶ月</p>
                <input id='shien_days' name='shien_days' className={style.input} type='text' placeholder='' maxLength='3' inputMode='numeric' control={control} {...register('shien_days', {})} />
                <p className={style.nextText}>日</p>
            </div>
            {errors.shien_months?.message && (
                <div className={style.error}>
                    <p className={style.errorText}>{errors.shien_months?.message}</p>
                </div>
            )}
            {errors.shien_days?.message && (
                <div className={style.error}>
                    <p className={style.errorText}>{errors.shien_days?.message}</p>
                </div>
            )}
        </div>
    )
    const contents = [
        <InputDate
            label='支援開始日'
            name='shien_start_date'
            control={control}
            startDate=''
            // errorText={errors.shien_start_date?.message}
            placeholder='日付を入力してください'
            // isRequired={true}
        />,
        <InputDate
            label='支援終了日'
            name='shien_end_date'
            control={control}
            startDate=''
            // errorText={errors.shien_end_date?.message}
            placeholder='日付を入力してください'
            // isRequired={true}
        />,
        supportTermEle,
        <InputText
            label='訪問回数'
            name='shien_visit_count'
            id='shien_visit_count'
            register={register('shien_visit_count', {})}
            type='text'
            placeholder=''
            nextText='回'
            width='w-20'
            maxLength='3'
            inputMode='numeric'
            errorText={errors.shien_visit_count?.message}
        />,
        <InputText
            label='同行支援回数'
            name='shien_doko_count'
            id='shien_doko_count'
            register={register('shien_doko_count', {})}
            type='text'
            placeholder=''
            nextText='回'
            width='w-20'
            maxLength='3'
            inputMode='numeric'
            errorText={errors.shien_doko_count?.message}
        />,
        <InputText
            label='合計支援時間'
            name='shien_total_time'
            id='shien_total_time'
            register={register('shien_total_time', {})}
            type='text'
            placeholder=''
            prevText='約'
            nextText='時間'
            width='w-20'
            maxLength='6'
            inputMode='numeric'
            errorText={errors.shien_total_time?.message}
        />,
        <TextArea
            label='今回の支援の自己評価'
            name='konkai_shien_jikohyoka'
            register={register('konkai_shien_jikohyoka', {})}
            // errorText={errors.konkai_shien_jikohyoka?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
    ]
    return (
        <>
            <FormBlock categoryName='支援期間' formItems={contents} />
        </>
    )
}
