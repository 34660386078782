import { useState, useEffect, useCallback } from 'react'
import { firebase, db } from '../func/firebase'
import ImageGallery from 'react-image-gallery'
import { GetFbImageUrl } from '../func/CommonFunc'

export default function UserManual() {
    const style = {
        image: 'w-full',
        thumbnail: '',
    }
    const [images, setImages] = useState([])
    useEffect(() => {
        const fbImgPaths = (num) => {
            const pathes = []
            for (let i = 0; i < num; i++) {
                const page = ('00' + (i + 1)).slice(-2)
                const fileName = `manual_${page}.png`
                pathes.push({ name: fileName, folder: 'manual' })
            }
            return pathes
        }
        const getImgUrls = async (resources) => {
            // リソースを同時に取得する
            const promises = []
            resources.forEach((resource) => {
                const url = GetFbImageUrl(resource.name, resource.folder)
                if (url) promises.push(url)
            })
            // すべてのリソースが取得できるまで待つ
            // Promise.allは [ResponseA, ResponseB] のように結果が配列となる
            const responses = await Promise.all(promises)
            const urls = responses.filter((ele) => ele)
            // console.log(urls)
            return urls
        }
        getImgUrls(fbImgPaths(30))
            .then((results) => {
                // console.log(results)
                const imageInfos = results.map((ele) => ({ original: ele, thumbnail: ele }))
                const hoge = [
                    {
                        original: 'https://picsum.photos/id/1018/1000/600/',
                        thumbnail: 'https://picsum.photos/id/1018/250/150/',
                    },
                    {
                        original: 'https://picsum.photos/id/1015/1000/600/',
                        thumbnail: 'https://picsum.photos/id/1015/250/150/',
                    },
                    {
                        original: 'https://picsum.photos/id/1019/1000/600/',
                        thumbnail: 'https://picsum.photos/id/1019/250/150/',
                    },
                ]
                setImages([...imageInfos])
            })
            .catch((err) => console.error(err))
        // const hoge = []
        // GetFirestoreData('mydata', '2').then((res) => hoge.push(res))
        // console.log(hoge)
    }, [])
    return (
        <div className='w-full h-full'>
            <div className='w-full h-full sm:max-w-2xl pt-4 px-2 sm:mx-8 flex flex-col items-start text-base sm:text-xl text-gray-800'>
                {/* <div className='w-full flex flex-col pb-4'>
                    <p>アプリの使い方手引</p>
                </div> */}
                <div className='w-full sm:h-5/6 pb-4'>
                    <ImageGallery
                        originalClass={style.image}
                        originalWidth=''
                        originalHeight=''
                        thumbnailPosition='right'
                        items={images}
                        loading='lazy'
                        showBullets={false}
                        showPlayButton={false}
                        showIndex={true}
                        preventDefaultTouchmoveEvent={true}
                    />
                </div>
            </div>
        </div>
    )
}
