import { BadgeRequire } from '../atom'
import { useFormContext } from 'react-hook-form'

let checkBoxRender = 0
export default function CheckBox({ label, name, id, options, subText, errorText, isRequired, isSmall = false }) {
    const { register, setValue, control } = useFormContext()
    // useWatch({ control, name: name })
    checkBoxRender++
    // console.log('checkBox Render', checkBoxRender)
    const errorFlg = !!errorText
    const subTextFlg = !!subText
    const styleContainer = isSmall ? 'mr-2 mb-2 sm:mr-3 sm:mb-3' : 'mr-4 mb-4'
    const styleBox = `${isSmall ? 'w-4 h-4 rounded-md sm:w-5 sm:h-5' : 'w-5 h-5 rounded-md'} mr-1 bg-gray-200 border-gray-400 text-teal-600`
    const styleLabel = `${isSmall ? 'text-sm sm:text-xl' : 'text-lg sm:text-2xl'}  text-gray-600`
    const optionElements = options.map((option, index) => (
        <li key={index} className={styleContainer}>
            <input
                type='checkbox'
                id={`${id ? id : name}.${index}`}
                value={option.code ? option.code : option}
                className={styleBox}
                // onClick={(e) => setRadioValue(index)}
                {...register(`${name}.${index}`, {})}
            />
            <label htmlFor={`${id ? id : name}.${index}`}>
                <span className={styleLabel}>{option.display ? option.display : option}</span>
            </label>
        </li>
    ))
    return (
        <div className='flex flex-col mb-4' id={`${name}_parent`}>
            <div className='flex flex-row items-end mb-2'>
                <label className='text-lg sm:text-2xl text-gray-600'>{label}</label>
                {isRequired && <BadgeRequire />}
            </div>
            <ul className='flex flex-row flex-wrap'>{optionElements}</ul>
            {subTextFlg && <p className='py-2 text-sm sm:text-lg leading-normal text-teal-700 whitespace-pre-wrap'>{subText}</p>}
        </div>
    )
}
