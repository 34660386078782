import { useFormContext } from 'react-hook-form'
import { TextArea, FormBlock } from '../atom'

// let SptREBlockRender = 0
export default function SupportRecordEnding(props) {
    // SptREBlockRender++
    // console.log('SptREBlockRender', SptREBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const contents = [
        <TextArea
            label='終結理由'
            name='shuketsu_riyu'
            id='shuketsu_riyu'
            register={register('shuketsu_riyu', {})}
            // errorText={errors.shuketsu_riyu?.message}
            placeholder='タッチすると拡大表示します'
            // subText=''
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
        <TextArea
            label='残された課題'
            name='zan_kadai'
            id='zan_kadai'
            register={register('zan_kadai', {})}
            // errorText={errors.zan_kadai?.message}
            placeholder='タッチすると拡大表示します'
            // subText=''
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
    ]
    return (
        <>
            <FormBlock categoryName='終結記録' formItems={contents} />
        </>
    )
}
