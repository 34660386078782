import { firebase, db, func, storage } from './firebase'
import clonedeep from 'lodash.clonedeep'
import { errorCode } from '../json'

export const inputData = (data, setValueMethod) => {
    for (let key in data) {
        if (data[key] !== '') {
            setValueMethod(key, data[key], { shouldDirty: false })
            // console.log('data setted', key, data[key])
        } else {
            continue
        }
    }
}
//ChexkBoxデータをフォーム用に整形(該当番号orfalseをBox数分の配列に入れる)
export const dataToCheckBox = (ref, data) => {
    const nums = ref.map((v) => v['code'])
    const arr = []
    nums.forEach((v, i) => {
        data.includes(nums[i]) ? arr.push(nums[i]) : arr.push(false)
    })
    // console.log('arr', arr)
    return arr
}
//CheckBoxフォーム入力をデータ用に整形
export const checkBoxToData = (items, keyName, padding = true) => {
    const format = []
    const flg = items.every((v) => v === false)
    // console.log('items flg', keyName, !flg)
    if (!flg) {
        items.forEach((item) => {
            const obj = {}
            if (padding) {
                obj[keyName] = ('000' + item).slice(-3)
                if (item) format.push(obj)
            } else {
                obj[keyName] = String(item)
                if (item) format.push(obj)
            }
        })
    } else if (flg) {
        const obj = {}
        obj[keyName] = ''
        format.push(obj)
    }
    return format
}
//データ(yyyymmdd)文字列をフォーム用日付オブジェクトに変換
export const dateDataToForm = (dateStr) => {
    const slashDate = `${dateStr.slice(0, 4)}/${dateStr.slice(4, 6)}/${dateStr.slice(6)}`
    if (!isNaN(Date.parse(slashDate))) {
        return new Date(Date.parse(slashDate))
    } else {
        return ''
    }
}
//フォーム入力の日付オブジェクトをデータ用文字列に整形(yyyyMMdd)
export const dateFormToData = (date) => {
    //日付文字列をオブジェクト化
    const stringToDate = (date) => {
        if (!isNaN(Date.parse(date))) {
            return new Date(Date.parse(date))
        }
    }
    const year = stringToDate(date)?.getFullYear().toString()
    const month = ('00' + (stringToDate(date)?.getMonth() + 1)).slice(-2)
    const day = ('00' + stringToDate(date)?.getDate()).slice(-2)
    // dateStringObj[`${keyName}YY`] = year
    // dateStringObj[`${keyName}MM`] = month
    // dateStringObj[`${keyName}DD`] = day
    // return dateStringObj
    const format = !isNaN(Date.parse(date)) ? year + month + day : ''
    return format
}
//現在時間を取得(年月日時分秒(コンマ以下1桁)yyyyMMddhhmmssd)
export const getCurrentDate = () => {
    const date = new Date()
    const yyyy = date.getFullYear().toString()
    const MM = ('00' + (date.getMonth() + 1)).slice(-2)
    const dd = ('00' + date.getDate()).slice(-2)
    const hh = ('00' + date.getHours()).slice(-2)
    const mm = ('00' + date.getMinutes()).slice(-2)
    const ss = ('00' + date.getSeconds()).slice(-2)
    const d = date.getMilliseconds().toString().slice(0, 1)
    return yyyy + MM + dd + hh + mm + ss + d
}
//Enterキーで誤送信しない
export const checkKeyDown = (e) => {
    // console.log('event target type', e.target.tagName)
    if (e.code === 'Enter' && e.target.tagName !== 'TEXTAREA') {
        e.preventDefault()
    }
}
//画面IDオブジェクトにフォーム入力を挿入して返す(編集ページのカテゴリを非表示にする時)
export const insertByDispId = (dispId, formData, dbData) => {
    const keys = Object.keys(dbData[dispId])
    const obj = {}
    for (const key in formData) {
        const value = formData[key]
        if (keys.includes(key)) {
            obj[key] = value
        } else {
            continue
        }
    }
    return obj
}
//日付オブジェクトをコピーして挿入()
export const copyAndInsertDateObj = (obj) => {
    const copyDateObj = (obj, dispId, keyName) => {
        const flg = Boolean(obj[dispId][keyName])
        if (flg) obj[dispId][keyName] = new Date(obj[dispId][keyName].valueOf())
    }
    copyDateObj(obj, '10', 'uketsuke_date')
    copyDateObj(obj, '11', 'shokai_date')
    copyDateObj(obj, '11', 'shokaimendan_date')
    copyDateObj(obj, '50', 'shien_start_date')
    copyDateObj(obj, '50', 'shien_end_date')
    copyDateObj(obj, '101', 'birthday')
}
//改行コードを置換(\n=>\r\n),二重に置換しないよう否定後読みの正規表現
export const replaceLineCode = (data) => {
    //iOSブラウザで否定後読み使えない
    // const regExp = /(?<!\r)\n/g
    // return data.replace(regExp,'\r\n')
    //文字列を逆にして否定先読み=>戻す
    // const text = '1\n 2\r\n 3\n'
    const text = data
    const reverseText = text.split('').reverse().join('')
    const regExpNotForward = /\n(?!\r)/g
    const replacedText = reverseText.replace(regExpNotForward, '\n\r')
    const orderText = replacedText.split('').reverse().join('')
    // console.log({reverse,replacedText,orderText})
    return orderText
}
//送信データ加工の共通処理
export const dataForCf = (data) => {
    const cloneData = clonedeep(data)
    //改行コードを置換(画面40は日付置換と同時に行う)
    //画面10
    cloneData['shokaitaio'] = replaceLineCode(cloneData['shokaitaio'])
    //画面11
    cloneData['tomentaio'] = replaceLineCode(cloneData['tomentaio'])
    cloneData['seikatsu_jokyo'] = replaceLineCode(cloneData['seikatsu_jokyo'])
    //画面12
    cloneData['job'] = replaceLineCode(cloneData['job'])
    cloneData['shunyu_shishutsu_free'] = replaceLineCode(cloneData['shunyu_shishutsu_free'])
    cloneData['riyochu_seido_biko'] = replaceLineCode(cloneData['riyochu_seido_biko'])
    cloneData['shinshin_jokyo'] = replaceLineCode(cloneData['shinshin_jokyo'])
    cloneData['shakaikankei_jokyo'] = replaceLineCode(cloneData['shakaikankei_jokyo'])
    //画面20
    cloneData['seikatsukankyo_shien_hitsuyo'] = replaceLineCode(cloneData['seikatsukankyo_shien_hitsuyo'])
    cloneData['chiryo_shien_hitsuyo'] = replaceLineCode(cloneData['chiryo_shien_hitsuyo'])
    cloneData['keizai_enjo_hitsuyo'] = replaceLineCode(cloneData['keizai_enjo_hitsuyo'])
    cloneData['kento_fukushi_seido'] = replaceLineCode(cloneData['kento_fukushi_seido'])
    cloneData['shakaikoken_shien_mokuhyo'] = replaceLineCode(cloneData['shakaikoken_shien_mokuhyo'])
    //画面50
    cloneData['honnin_henka'] = replaceLineCode(cloneData['honnin_henka'])
    cloneData['shuketsu_riyu'] = replaceLineCode(cloneData['shuketsu_riyu'])
    cloneData['zan_kadai'] = replaceLineCode(cloneData['zan_kadai'])
    cloneData['kikan_adr'] = replaceLineCode(cloneData['kikan_adr'])
    cloneData['konkai_shien_jikohyoka'] = replaceLineCode(cloneData['konkai_shien_jikohyoka'])
    //画面101
    cloneData['adr_dtl'] = replaceLineCode(cloneData['adr_dtl'])
    cloneData['shokai_naiyo'] = replaceLineCode(cloneData['shokai_naiyo'])
    //日付を追加,書き換え(obj=>str)
    //画面共通
    cloneData['update_date'] = getCurrentDate()
    //画面10
    cloneData['uketsuke_date'] = dateFormToData(cloneData['uketsuke_date'])
    //画面101
    cloneData['birthday'] = dateFormToData(cloneData['birthday'])
    //画面11
    cloneData['shokai_date'] = dateFormToData(cloneData['shokai_date'])
    cloneData['shokaimendan_date'] = dateFormToData(cloneData['shokaimendan_date'])
    //画面40支援経過リストの日付を整形,改行コードを置換
    cloneData['enjokeika_list'].forEach((v, i) => {
        const dateStr = dateFormToData(cloneData['enjokeika_list'][i]['enjo_date'])
        cloneData['enjokeika_list'][i]['enjo_date'] = dateStr
        const replacedCode = replaceLineCode(cloneData['enjokeika_list'][i]['enjo_keika_naiyo'])
        cloneData['enjokeika_list'][i]['enjo_keika_naiyo'] = replacedCode
    })
    //画面50
    cloneData['shien_start_date'] = dateFormToData(cloneData['shien_start_date'])
    cloneData['shien_end_date'] = dateFormToData(cloneData['shien_end_date'])
    //CheckBoxデータを整形 元のプロパティを消去
    //画面102
    const shubetsuItems = cloneData['sodansha_shubetsu']
    cloneData['sodansha_shubetsu_list'] = checkBoxToData(shubetsuItems, 'sodansha_shubetsu')
    delete cloneData['sodansha_shubetsu']
    //画面12
    //相談者キーワード(整形の際に0埋めをしない)
    const keyWordItems = cloneData['sodansha_keyword']
    cloneData['sodansha_keyword_list'] = checkBoxToData(keyWordItems, 'sodansha_keyword', false)
    delete cloneData['sodansha_keyword']
    const shushiItems = cloneData['shushi_jokyo']
    cloneData['shushi_jokyo_list'] = checkBoxToData(shushiItems, 'shushi_jokyo')
    delete cloneData['shushi_jokyo']
    //画面50
    const enjogoItems = cloneData['enjogo_seikatsu']
    cloneData['enjogo_seikatsu_list'] = checkBoxToData(enjogoItems, 'enjogo_seikatsu')
    delete cloneData['enjogo_seikatsu']
    const renkeiItems = cloneData['renkei_kikan']
    cloneData['renkei_kikan_list'] = checkBoxToData(renkeiItems, 'renkei_kikan')
    delete cloneData['renkei_kikan']
    //特殊UIデータを整形
    //家族リストを抽出して配列に入れる
    const splitFamilyList = (obj) => {
        const keys = Object.keys(obj)
        const familyInputs = {}
        const checkNames = ['kazoku_no', 'kazoku_name', 'kazoku_zokugara', 'kazoku_nenrei', 'shuro_shugaku_jokyo', 'kenko_jotai', 'biko_']
        //checkNamesをキー名に含むなら新objに入れ、旧objから削除
        for (let key of keys) {
            const bool = checkNames.some((name) => key.includes(name))
            if (bool) {
                familyInputs[key] = obj[key]
                delete obj[key]
            }
        }
        // console.log('familyInputs', familyInputs)
        //家族リストの入れ物(5人まで)
        const familyListTemp = [{}, {}, {}, {}, {}]
        //番号ごとに振り分けて配列に入れ,新objから削除(ループ回数削減のため)
        const splitList = (keyName, num) => {
            if (keyName.includes(String(num))) {
                const familyObj = familyListTemp[num - 1]
                const newKey = keyName.replace(`_${num}`, '')
                Object.assign(familyObj, { ...familyObj, [newKey]: familyInputs[keyName] })
                delete familyInputs[keyName]
            }
        }
        //家族リストの数x新objキーの数分のループを回して振り分ける
        for (let i = 0; i < familyListTemp.length; i++) {
            const keys = Object.keys(familyInputs)
            for (let key of keys) {
                splitList(key, i + 1)
            }
        }
        //返り値用の配列
        const familyInfoList = []
        //値が全て空でなければ新しいkazoku_noを付け配列に入れる
        for (let i = 0; i < familyListTemp.length; i++) {
            const vals = Object.values(familyListTemp[i])
            const isFilled = vals.some((v) => v !== '')
            // console.log(`isFilled[${i}]`, isFilled)
            if (isFilled) {
                familyListTemp[i]['kazoku_no'] = String(i + 1)
                familyInfoList.push(familyListTemp[i])
            } else {
                familyListTemp[i]['kazoku_no'] = String(i + 1)
                familyListTemp[i]['kazoku_name'] = ''
                familyListTemp[i]['kazoku_zokugara'] = ''
                familyListTemp[i]['kazoku_nenrei'] = ''
                familyListTemp[i]['shuro_shugaku_jokyo'] = ''
                familyListTemp[i]['kenko_jotai'] = ''
                familyListTemp[i]['biko'] = ''
                familyInfoList.push(familyListTemp[i])
                // break
            }
        }
        // console.log('familyListTemp', familyListTemp)
        // console.log('familyInfoList', familyInfoList)
        return familyInfoList
    }
    cloneData['kazoku_list'] = splitFamilyList(cloneData)
    // const mergeData = { data: cloneData }
    //重複避けデータを削除
    delete cloneData['sodansha_shubetsu_sptc']
    delete cloneData['seiho_kbn_sptc']
    delete cloneData['sodansha_shubetsu_sonota_sptc']
    delete cloneData['todouhuken']

    //version番号に0.001追加,1000倍して小数点以下切り上げ=>桁数指定で小数点以下を加えた文字列に
    // const currentVerNum = Number(cloneData['version_no'])
    // const calc = Math.ceil(currentVerNum * 1000 + 1) / 1000
    // const calcStr = calc.toFixed(3)
    // cloneData['version_no'] = calcStr
    // console.log({ calc, calcStr })

    // console.log('dataForCF/rawData: ', data)
    console.log('dataForCF/edittedData: ', cloneData)
    return cloneData
}
export const postToCf = async (data) => {
    const functions = firebase.functions('asia-northeast2')
    const cfFunc = functions.httpsCallable('AcceptFromSmartphone_and_SendToConduct_v1')
    const response = cfFunc(data)
        .then((body) => {
            const res = body.data
            console.log('res from cf:', res)
            const flg = res === 'FirestoreWriteOK'
            // console.log('res judge', flg)
            if (flg) {
                console.log('Data Post Success')
            } else {
                console.error('Data Post Failed', res)
            }
            return res
        })
        .catch((error) => {
            console.error('PostToCf Error:', error)
            return new Error(error)
        })
    return response
}
//commitToFBで書き込んだ際のフィードバックをモーダルウインドウに表示する
export const feedBackDisplay = async (response, setShowModalMethod) => {
    setShowModalMethod({
        show: true,
        text: '送信中...',
        state: 'pending',
    })
    response
        .then((res) => {
            // console.log('res in feedBackFunc', res)
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const flg = res === 'FirestoreWriteOK'
                    // console.log('res judge', flg)
                    if (flg) {
                        // console.log('res is OK')
                        resolve(res)
                    } else {
                        // console.error('res is NG')
                        reject(res)
                    }
                }, 1500)
            })
        })
        .then((res) => {
            return new Promise((resolve, reject) => {
                setShowModalMethod({
                    show: true,
                    text: '送信しました',
                    state: 'fulfilled',
                })
                resolve()
                // reject()
            })
        })
        .then((res) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    setShowModalMethod({
                        show: false,
                    })
                    window.location.reload()
                    console.log('page reloaded')
                    resolve()
                    // reject()
                }, 1500)
            })
        })
        .catch((error) => {
            console.error('res is NG :', error)
            const closeModal = () => {
                setShowModalMethod({ show: false })
            }
            setShowModalMethod({
                show: true,
                text: '送信できませんでした',
                errorText: genErrorText(error),
                state: 'rejected',
                doOk: closeModal,
            })
            // setTimeout(() => {
            //     setShowModalMethod({
            //         show: false,
            //     })
            //     // onFocus()
            // }, 5000)
        })
}
//エラー表示用
export const genErrorText = (error) => {
    if (typeof error === 'object') {
        const errorCode = 'ErrorCode' in error ? error.ErrorCode : 'エラーコードなし'
        const errorDisplay = errorCode[errorCode] ? errorCode[errorCode] : '不明なエラーコード'
        if (errorCode === '1005') {
            return `エラー${errorCode} : ${errorDisplay}\n他ユーザが対象データを更新しました。再度情報取得後、更新処理を行ってください。`
        } else if (errorCode.substr(0, 2) === '10') {
            return `エラー${errorCode} : ${errorDisplay}\nPCシステムの予期せぬエラーです。社会貢献推進室まで連絡下さい。`
        } else if (errorCode === '2000') {
            return `エラー${errorCode} : ${errorDisplay}\n他ユーザが対象データを更新しました。再度情報取得後、更新処理を行ってください。`
        } else if (errorCode.substr(0, 2) === '20') {
            return `エラー${errorCode} : ${errorDisplay}\nスマホシステムの予期せぬエラーです。社会貢献推進室まで連絡下さい。`
        } else if (Object.keys(error).length === 0) {
            return `${error} : エラーの中身が空です。\n社会貢献推進室まで連絡下さい。`
        } else {
            return `エラー${errorCode} : ${errorDisplay} : 不明なエラーです。\n社会貢献推進室まで連絡下さい。`
        }
    } else if (error === '' || error === null || error === undefined) {
        return `エラー${error} : 不明なエラーです。\n社会貢献推進室まで連絡下さい。`
    } else {
        return `エラー${error} : 不明なエラーです。\n社会貢献推進室まで連絡下さい。`
    }
}
//西暦=>和暦変換
export const eraYear = (year) => {
    const eraHandler = (yearNow) => {
        const generate = (era, startYear) => {
            let yearDsp = yearNow - startYear + 1
            if (yearDsp === 1) {
                yearDsp = '元'
            } else {
                yearDsp = ('00' + yearDsp).slice(-2)
            }
            return `${era}${yearDsp}`
        }
        if (yearNow >= 2019) {
            return generate('令和', 2019)
        }
        if (yearNow >= 1989) {
            return generate('平成', 1989)
        }
        if (yearNow >= 1926) {
            return generate('昭和', 1926)
        }
        if (yearNow >= 1912) {
            return generate('大正', 1912)
        }
        if (yearNow >= 1868) {
            return generate('明治', 1868)
        }
    }
    return eraHandler(year)
}
//文字数オーバーで省略
export const textTruncate = (text, count) => {
    return text.length <= count ? text : text.substr(0, count) + '...'
}
//コード番号で記録されている項目をGUI表示名に変換する処理
export const codeToDisplay = (obj) => {
    const object = {}
    obj.forEach((item) => (object[item.code] = item['display']))
    return object
}
export const genderMatch = (genderCode) => {
    if (genderCode === '0') {
        return '男性'
    } else if (genderCode === '1') {
        return '女性'
    } else if (genderCode === '2') {
        return 'その他'
    }
}
export const ageToString = (age) => {
    const cut = Math.floor(age / 10) * 10
    return `(${cut}代)`
}
export const listMatch = (item, obj) => {
    if (!item) return
    const filterdItems = item.filter((v) => Boolean(v))
    const textItems = []
    for (let i = 0; i < filterdItems.length; i++) {
        const code = filterdItems[i]
        textItems.push(obj[code])
    }
    return textItems.join('、')
}
export const GetFbImageUrl = (name, folder) => {
    return new Promise((resolve, reject) => {
        const folderPath = Boolean(folder) ? `${folder}/` : ''
        storage
            .ref()
            .child(`${folderPath}${name}`)
            .getDownloadURL()
            .then((url) => {
                resolve(url)
            })
            .catch((error) => {
                if (error.message.includes('storage/object-not-found')) {
                    resolve('')
                } else {
                    reject(error)
                }
            })
    })
}
export const GetFirestoreData = (collection, document) => {
    return new Promise((resolve) => {
        //特定のdocumentを取得
        db.collection(collection)
            .doc(document)
            .get()
            .then((doc) => resolve(doc.data()))
            .catch((error) => {
                resolve(error)
            })

        // const array = []

        //全てのdocumentを取得
        // db.collection(collection)
        //     .get()
        //     .then((querySnapshot) => {
        //         querySnapshot.forEach((doc) => {
        //             array.push(doc.data())
        //             resolve(array)
        //         })
        //     })
        //     .catch((error) => {
        //         resolve(error)
        //     })

        //検索条件をつけて取得
        // db.collection(collection)
        //     .where('age', '>', 25)
        //     .get()
        //     .then((querySnapshot) => {
        //         querySnapshot.forEach((doc) => {
        //             array.push(doc.data())
        //             resolve(array)
        //         })
        //     })
        //     .catch((error) => {
        //         resolve(error)
        //     })
    })
}
//watchValueによって対象フォームの値を監視し、変更されるたびにtargetNameの表示チェックをする
//選択した値によって表示を切り替える(tailwindのhiddenを使用してCSSのdisplay:noneを表現)
//対象はblock内のelementの親とする(input/label/element/subtext等の親)
//ReactHookFormのsetValue,setFocusを使いたければ引数で渡す
//setFocusは対象フォームにフォーカスする
//setValueは非表示時に値をクリアする(そのままだと非表示フォームの値が送信されるため)
export const SwitchDisplay = ({ watchValue, targetName, targetId, checkValue, focusMethod = null, setValueMethod = null }) => {
    const parentElement = document.getElementById(`${targetId}_parent`)
    const classes = parentElement.classList
    // console.log(watchValue)
    if (watchValue?.includes(checkValue)) {
        classes.remove('hidden')
        // console.log(checkValue)
        if (focusMethod) {
            // console.log('focusMethod', focusMethod)
            // focusMethod(targetName)
        }
    } else {
        if (Array.from(classes).includes('hidden')) {
            //何もしない
        } else {
            classes.add('hidden')
            if (setValueMethod) {
                setValueMethod(targetName, '')
                // console.log('setValueMethod')
            }
        }
    }
}
