import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { InputDate, SelectBox, CheckBox, InputText, TextArea, RadioButton, FormBlock } from '../atom'
import { SwitchDisplay } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let SptCMBlockRender = 0
export default function SupportContentsMedical(props) {
    // SptCMBlockRender++
    // console.log('SptCMBlockRender', SptCMBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const iryo_hokensho_options = selectionOptions.iryo_hokensho_options
    const shogai_techo_options = selectionOptions.shogai_techo_options
    const yokaigodo_options = selectionOptions.yokaigodo_options
    const shogaiteido_kubun_options = selectionOptions.shogaiteido_kubun_options
    const contents = [
        <RadioButton
            label='医療保険証'
            name='iryo_hokensho'
            id='iryo_hokensho'
            register={register('iryo_hokensho', {})}
            options={iryo_hokensho_options}
            // errorText={errors.iryo_hokensho?.message}
            // isRequired={true}
        />,
        <RadioButton
            label='障がい者手帳'
            name='shogai_techo'
            id='shogai_techo'
            register={register('shogai_techo', {})}
            options={shogai_techo_options}
            // errorText={errors.shogai_techo?.message}
            // isRequired={true}
        />,
        <SelectBox
            label='要介護度'
            name='yokaigodo'
            id='yokaigodo'
            register={register('yokaigodo', {})}
            // errorText={errors.yokaigodo?.message}
            options={yokaigodo_options}
            isFirst={false}
            // firstOption='--- 選択してください ---'
            // isRequired={true}
        />,
        <SelectBox
            label='障がい程度区分'
            name='shogaiteido_kubun'
            id='shogaiteido_kubun'
            register={register('shogaiteido_kubun', {})}
            // errorText={errors.shogaiteido_kubun?.message}
            options={shogaiteido_kubun_options}
            isFirst={false}
            // firstOption='--- 選択してください ---'
            // isRequired={true}
        />,
        <InputText
            label='その他障がい情報'
            name='sonota_shogaijoho'
            id='sonota_shogaijoho'
            register={register('sonota_shogaijoho', {})}
            type='text'
            // errorText={errors.sodansha_shubetsu_sonota_etc?.message}
            placeholder=''
            subText='※身体・精神・知的障がい手帳の有無等の情報を入力してください'
            maxLength='80'
        />,
        <TextArea
            label='備考欄'
            name='riyochu_seido_biko'
            register={register('riyochu_seido_biko', {})}
            // errorText={errors.riyochu_seido_biko?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            printDisplay={{ display: true, countByLine: 43, lines: 4 }}
        />,
    ]

    // const sodansha_shubetsu_defaultValue = yokaigodo_options.map((v) => false)
    // const sodansha_shubetsu_watch = useWatch({
    //     control,
    //     name: 'sodansha_shubetsu',
    //     defaultValue: sodansha_shubetsu_defaultValue,
    // })
    // // console.log(sodansha_shubetsu_watch)
    // const sodansha_shubetsu_values = sodansha_shubetsu_watch ? getValues('sodansha_shubetsu') : ''
    // // console.log('sodansha_shubetsu', sodansha_shubetsu_values)
    // useEffect(() => {
    //     const welfareArg = {
    //         watchValue: sodansha_shubetsu_values,
    //         targetName: 'iryo_hokensho',
    //         targetId: 'iryo_hokensho',
    //         checkValue: '009',
    //         // focusMethod: setFocus, //setFocusできないため省略
    //         setValueMethod: setValue,
    //     }
    //     const commentArg = {
    //         watchValue: sodansha_shubetsu_values,
    //         targetName: 'sodansha_shubetsu_sonota',
    //         targetId: 'sodansha_shubetsu_sonota',
    //         checkValue: '999',
    //         // focusMethod: setFocus,
    //         setValueMethod: setValue,
    //     }
    //     SwitchDisplay(welfareArg)
    //     SwitchDisplay(commentArg)
    //     // if (sodansha_shubetsu_values?.includes('999')) {
    //     //     setFocus('sodansha_shubetsu_sonota')
    //     // }
    // }, [sodansha_shubetsu_values, setValue, setFocus])

    return (
        <>
            <FormBlock categoryName='利用中の医療・介護・福祉制度' formItems={contents} />
        </>
    )
}
