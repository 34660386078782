import { useFormContext } from 'react-hook-form'
import { InputText, FormBlock } from '../atom'

// let PISptBlockrender = 0
export default function PersonalInfoSupport(props) {
    // PISptBlockrender++
    // console.log('PISptBlockrender', PISptBlockrender)
    const {
        register,
        formState: { errors },
    } = useFormContext()
    const contents = [
        <InputText
            label='支援協力者-フリガナ'
            name='kyoryokusha_name_kana'
            type='text'
            register={register('kyoryokusha_name_kana', {})}
            placeholder='カナ'
            subText='例) ヤマダタロウ'
            maxLength='80'
            errorText={errors.kyoryokusha_name_kana?.message}
        />,
        <InputText
            label='支援協力者-氏名'
            name='kyoryokusha_name'
            type='text'
            register={register('kyoryokusha_name', {})}
            placeholder='漢字'
            subText='例) 山田太郎'
            maxLength='80'
            errorText={errors.kyoryokusha_name?.message}
        />,
        <InputText
            label='支援協力者-電話番号'
            name='kyoryokusha_tel'
            type='text'
            register={register('kyoryokusha_tel', {})}
            // errorText={errors.kyoryokusha_tel?.message}
            placeholder=''
            maxLength='13'
        />,
        <InputText
            label='支援協力者の対象者との関係'
            name='kyoryokusha_kankei'
            type='text'
            register={register('kyoryokusha_kankei', {})}
            // errorText={errors.kyoryokusha_kankei?.message}
            placeholder=''
            maxLength='80'
        />,
    ]
    return (
        <>
            <FormBlock categoryName='主たる支援協力者' formItems={contents} />
        </>
    )
}
