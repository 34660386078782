import { useFormContext, useWatch } from 'react-hook-form'
import { TextArea, FormBlock } from '../atom'

// let SptCSBlockRender = 0
export default function SupportContentsStatus(props) {
    // SptCSBlockRender++
    // console.log('SptCSBlockRender', SptCSBlockRender)
    const {
        register,
        formState: { errors },
    } = useFormContext()
    const contents = [
        <TextArea
            label='心身の状況'
            name='shinshin_jokyo'
            register={register('shinshin_jokyo', {})}
            // errorText={errors.shinshin_jokyo?.message}
            placeholder='タッチすると拡大表示します'
            subText='※既往症・現在の病気を含む'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
        <TextArea
            label='社会関係の状況'
            name='shakaikankei_jokyo'
            register={register('shakaikankei_jokyo', {})}
            // errorText={errors.shakaikankei_jokyo?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
    ]
    return (
        <>
            <FormBlock categoryName='相談者の状況' formItems={contents} />
        </>
    )
}
