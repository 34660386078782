import { useState, useEffect, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { ClipboardIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { Dialog, BadgeRequire } from '../atom'

export default function TextArea({
    label,
    name,
    id,
    register,
    placeholder,
    subText,
    errorText,
    isRequired,
    expandSize,
    printDisplay = { display: false, countByLine: '', lines: '' },
    pasTable = false,
    defaultValue = '',
}) {
    // const rows = useContext(LineRowsContext)
    // console.log('dv in txtarea', defaultValue)
    const style = {
        print: 'pt-2 text-right text-sm sm:text-base leading-snug text-gray-500 whitespace-pre-wrap',
    }
    const { setValue, control } = useFormContext()
    const errorFlg = !!errorText
    const subTextFlg = !!subText
    const [sizeFlg, setSizeFlg] = useState(false)
    const [isPaste, setIsPaste] = useState(false)
    const normalStyleTextArea = `
        p-2 rounded-lg  text-lg sm:text-xl border border-gray-400 bg-gray-200 appearance-none whitespace-pre-wrap
    `
    const activeStyleTextArea = `
        w-full lg:w-3/5 h-5/6 p-4 rounded-lg border-2 border-gray-500
        sm:text-2xl sm:text-4xl leading-relaxed sm:leading-loose text-gray-700
        bg-gray-100 whitespace-pre-wrap
    `
    const sizedStyle = sizeFlg ? activeStyleTextArea : normalStyleTextArea
    const sizeFlgToggle = () => {
        if (expandSize) setSizeFlg(!sizeFlg)
    }
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const pasteText = useCallback(() => {
        const json = localStorage.getItem('pastCaseData')
        if (json) {
            const obj = JSON.parse(json)
            const deployedObj = { ...obj[11], ...obj[12], ...obj[20], ...obj[40], ...obj[50] }
            // console.log(obj, deployedObj)
            // console.log(deployedObj[name])
            const isProp = name in deployedObj && Boolean(deployedObj[name])
            // console.log(isProp)
            if (isProp) {
                const pasteData = () => {
                    setShowDialog({ ...showDialog, show: false })
                    setValue(name, deployedObj[name])
                    setIsPaste(true)
                }
                const hideDialog = () => {
                    setShowDialog({ ...showDialog, show: false })
                }
                setShowDialog({
                    ...showDialog,
                    show: true,
                    message: '保存した内容を貼り付けます。\nよろしいですか？',
                    doYes: pasteData,
                    doNo: hideDialog,
                    textYes: 'はい',
                    textNo: 'いいえ',
                })
            }
        }
    }, [name, setValue, showDialog])
    // const { control } = useFormContext()
    // const textWatch = useWatch({ control, name: name, defaultValue: '' })
    // useEffect(() => {
    //     console.log('textWatch', textWatch)
    // }, [textWatch])
    const textWatch = useWatch({ control, name: name, defaultValue: defaultValue })
    const [dispForPrint, setDispForPrint] = useState({ count: 0, limitCount: '' })
    useEffect(() => {
        // console.log(textWatch.length)
        // if (printDisplay) console.log(printDisplay)
        if (printDisplay.display) {
            const limitCount = printDisplay.countByLine * printDisplay.lines
            const regex = /\r\n|\n/g
            const count = textWatch ? textWatch.replace(regex, '').length : 0
            setDispForPrint({ count: count, limitCount: limitCount })
        }
    }, [textWatch, printDisplay])
    return (
        <div className='flex flex-col mb-4 ' id={`${id ? id : name}_parent`}>
            <Dialog showDialog={showDialog} />
            {/* <input  className='w-8 h-8' type='checkbox' onClick={() => setSizeFlg(!sizeFlg)} checked={sizeFlg} readOnly /> */}
            <div className='flex flex-row items-end mb-2'>
                <label htmlFor={name} className='text-lg sm:text-2xl text-gray-600'>
                    {label}
                </label>
                {isRequired && <BadgeRequire />}
            </div>
            {sizeFlg ? (
                <div
                    className='inset-0 w-screen h-screen fixed flex flex-col px-4 pb-4 pt-8 bg-opacity-50 bg-gray-800 z-50'
                    onClick={(e) => {
                        if (e.target.name !== name) {
                            setSizeFlg(!sizeFlg)
                        } else {
                        }
                    }}
                >
                    <XCircleIcon
                        className='
                            absolute top-4 right-2 w-12 h-12 sm:w-16 sm:h-16 text-gray-500 bg-white rounded-full cursor-pointer
                        '
                        onClick={sizeFlgToggle}
                    />
                    <textarea className={sizedStyle} type='text' {...register} placeholder={placeholder} spellCheck='false' defaultValue={defaultValue} />
                    {printDisplay.display && <p className='text-right text-gray-50 text-2xl sm:text-3xl'>{`${dispForPrint.count}文字 (${dispForPrint.limitCount}文字まで推奨)`}</p>}
                </div>
            ) : (
                <textarea
                    type='text'
                    className={sizedStyle}
                    id={id ? id : name}
                    {...register}
                    placeholder={placeholder}
                    onClick={sizeFlgToggle}
                    // rows={rows}
                    spellCheck='false'
                    defaultValue={defaultValue}
                />
            )}
            {errorFlg && (
                <span className='flex items-center justify-end mb-2 text-right text-sm font-bold text-red-400' spellCheck='false'>
                    <ExclamationCircleIcon className='w-4 h-4' />
                    {errorText}
                </span>
            )}
            {subTextFlg && <p className='py-2 text-sm sm:text-base leading-snug text-gray-700 whitespace-pre-wrap'>{subText}</p>}
            {printDisplay.display && <p className={style.print}>{`${dispForPrint.count}文字 (${dispForPrint.limitCount}文字まで推奨)`}</p>}
            {pasTable ? (
                isPaste ? (
                    <div className='flex flex-row items-end justify-end mt-2 text-teal-600' onClick={() => setIsPaste(false)}>
                        <p className='text-sm pr-1'>貼り付けました</p>
                        <CheckCircleIcon className='w-6 h-6' />
                    </div>
                ) : (
                    <div className='flex flex-row items-end justify-end mt-2 text-gray-500' onClick={pasteText}>
                        <p className='text-sm'>貼り付け</p>
                        <ClipboardIcon className='w-6 h-6' />
                    </div>
                )
            ) : (
                ''
            )}
        </div>
    )
}
