import { useEffect, useCallback } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import ja from 'date-fns/locale/ja'
import { CalendarIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { dateFormToData } from '../func/CommonFunc'
import { BadgeRequire } from '../atom'

export default function InputDate({ name, label, control, startDate, placeholder, subText, isRequired, errorText, className }) {
    const errorFlg = !!errorText
    const subTextFlg = !!subText
    const classNameFlg = !!className
    registerLocale('ja', ja)
    //西暦=>和暦変換
    const eraHandler = (yearNow) => {
        const generate = (era, startYear) => {
            let yearDsp = yearNow - startYear + 1
            if (yearDsp === 1) {
                yearDsp = '元'
            } else {
                yearDsp = ('00' + yearDsp).slice(-2)
            }
            return `${era}${yearDsp}`
        }
        if (yearNow >= 2019) {
            return generate('令和', 2019)
        }
        if (yearNow >= 1989) {
            return generate('平成', 1989)
        }
        if (yearNow >= 1926) {
            return generate('昭和', 1926)
        }
        if (yearNow >= 1912) {
            return generate('大正', 1912)
        }
        if (yearNow >= 1868) {
            return generate('明治', 1868)
        }
    }
    // カレンダーに表示する最初の西暦（明治元年となる1868を指定）
    const startYear = 1868
    // カレンダーに表示する未来の年数
    const futureListUp = 51
    //上で指定した年数の配列を生成
    const years = Array.from({ length: getYear(new Date()) - startYear + futureListUp }, (v, k) => k + startYear).reverse()
    const months = Array.from(new Array(12)).map((v, i) => String(i + 1))
    const normalFormStyle = classNameFlg
        ? className
        : `
        flex flex-row items-center
        p-2 rounded-lg  text-lg sm:text-xl border border-gray-400 bg-gray-200 appearance-none
        focus-within:outline-none focus-within:bg-white focus-within:ring-2 focus-within:ring-blue-800 focus-within:border-transparent
    `
    const errorFormStyle = `${normalFormStyle} ring-2 ring-red-500 forcus:none`
    const formStyle = !errorFlg ? normalFormStyle : errorFormStyle
    const dateWatch = useWatch({ control, name: name, defaultValue: '' })
    const eraYear = useCallback((year) => {
        const eraHandler = (yearNow) => {
            const generate = (era, startYear) => {
                let yearDsp = yearNow - startYear + 1
                if (yearDsp === 1) {
                    yearDsp = '元'
                } else {
                    yearDsp = ('00' + yearDsp).slice(-2)
                }
                return `${era}${yearDsp}`
            }
            if (yearNow >= 2019) {
                return generate('令和', 2019)
            }
            if (yearNow >= 1989) {
                return generate('平成', 1989)
            }
            if (yearNow >= 1926) {
                return generate('昭和', 1926)
            }
            if (yearNow >= 1912) {
                return generate('大正', 1912)
            }
            if (yearNow >= 1868) {
                return generate('明治', 1868)
            }
        }
        return eraHandler(year)
    }, [])
    const warekiDisplay = useCallback(() => {
        // console.log(`${name} changed:`, dateWatch)
        const dateStr = dateFormToData(dateWatch)
        const warekiYear = eraYear(Number(dateStr.slice(0, 4)))
        const dateGui = `${warekiYear}年${dateStr.slice(4, 6)}月${dateStr.slice(6)}日`
        if (!!dateWatch) {
            return (
                <>
                    <p className='pt-1 pr-1 text-right text-sm text-gray-500'>{dateGui}</p>
                </>
            )
        }
    }, [dateWatch, eraYear])
    // useEffect(() => {
    //     console.log(`${name} changed:`, dateWatch)
    // }, [dateWatch, name])
    return (
        <div className='flex flex-col' id={`${name}_parent`}>
            <div className='flex flex-row items-end mb-2'>
                <label htmlFor={name} className='text-lg sm:text-2xl text-gray-600'>
                    {label}
                </label>
                {isRequired && <BadgeRequire />}
            </div>
            <div className='mb-4 flex flex-col'>
                <div className={formStyle}>
                    <CalendarIcon className='w-6 h-6 text-gray-500' />
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={startDate ? startDate : ''}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <ReactDatePicker
                                // wrapperClassName='datePicker'
                                className='
                                datePicker pl-4 border-0 outline-none bg-white bg-opacity-0
                            '
                                selected={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                dateFormat='yyyy/MM/dd'
                                locale='ja'
                                onFocus={(e) => e.target.blur()}
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode='select'
                                todayButton='今日の日付を選択'
                                placeholderText={placeholder}
                                renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
                                    return (
                                        <div className='m-2 flex justify-center'>
                                            {/* 前月ボタン */}
                                            <span className='cursor-pointer px-2 text-2xl font-bold text-gray-500' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {'<'}
                                            </span>
                                            {/* 年の部分 */}
                                            <select className='border-r-4' value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option} ({eraHandler(option)}) 年
                                                    </option>
                                                ))}
                                            </select>
                                            {/* 月の部分 */}
                                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}月
                                                    </option>
                                                ))}
                                            </select>
                                            {/* 次月ボタン */}
                                            <span className='cursor-pointer px-2 text-2xl font-bold text-gray-500' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {'>'}
                                            </span>
                                        </div>
                                    )
                                }}
                            />
                        )}
                    />
                </div>
                {warekiDisplay()}
                {errorFlg && (
                    <span className='flex flex-row items-center justify-end my-2 text-right text-base font-bold text-red-400'>
                        <ExclamationCircleIcon className='w-5 h-5' />
                        <p>{errorText}</p>
                    </span>
                )}
                {subTextFlg && <p className='py-2 text-sm leading-snug text-gray-700 whitespace-pre-wrap'>{subText}</p>}
            </div>
        </div>
    )
}
