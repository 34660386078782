import { useFormContext } from 'react-hook-form'
import { InputText, InputDate, FormBlock } from '../atom'

// let PIRBlockrender = 0
export default function PersonalInfoRecept() {
    // PIRBlockrender++
    // console.log('PIRBlockrender', PIRBlockrender)
    const {
        register,
        formState: { errors },
        control,
    } = useFormContext()

    const todayDate = new Date()
    const contents = [
        <InputText label='支援のタイトル' name='shien_title' register={register('shien_title', {})} type='text' placeholder='' maxLength='500' />,
        <InputDate
            label='受付日'
            name='shokai_date'
            control={control}
            startDate={''}
            //  errorText={errors.shokai_date?.message}
            placeholder='日付を入力してください'
        />,
        <InputDate
            label='初回面談日'
            name='shokaimendan_date'
            control={control}
            startDate={todayDate}
            errorText={errors.shokaimendan_date?.message}
            placeholder='日付を入力してください'
            isRequired={true}
        />,
        <InputText label='CSW氏名' name='csw_name' register={register('csw_name', {})} type='text' placeholder='' maxLength='80' />,
    ]
    return (
        <>
            <FormBlock categoryName='受付' formItems={contents} />
        </>
    )
}
