import { Route } from 'react-router-dom'
import { useAuthContext } from './AuthProvider'
// import LoginPage from './LoginPage'
import { Login } from '../page'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const { currentUser } = useAuthContext()
    //currentUserがtrueの場合RouteComponent＝Home、falseならLoginComponentにroute
    const Component = currentUser ? RouteComponent : Login
    return (
        <>
            <Route {...rest} component={Component} />
        </>
    )
}

export default PrivateRoute
