import React, { useState, useEffect, useRef, createContext, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import clonedeep from 'lodash.clonedeep'
import { firebase, db, func } from '../func/firebase'
import { useAuthContext } from '../func'
import { inputData, checkKeyDown, insertByDispId, replaceLineCode, getCurrentDate, dataForCf, postToCf, feedBackDisplay } from '../func/CommonFunc'
import { useFbDataContext } from '../page'
import { SupportPlanDetail } from '../block'
import { Dialog, Modal, Button } from '../atom'
import { defaultValues, fieldsByCategory } from '../json'

// let SptPlanCompoRender = 0
export default function SupportPlan() {
    // SptPlanCompoRender++
    // console.log('SptPlanCompoRender', SptPlanCompoRender)
    const { currentUser, userData } = useAuthContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const { fbData, setFbData, setShowDetail, showDetail, setShowEdit } = useFbDataContext()
    const schema = yup.object().shape({})
    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: defaultValues.supportPlan,
    })
    const refDataLoad = useRef(true)
    const refShowDetail = useRef(null)
    useEffect(() => {
        // console.log('useEffect Reloaded in SupportPlan')
        // console.log('fbData in SupportPlan', fbData)
        // console.log('ref?', refDataLoad)
        const open = showDetail.index === 20
        const close = showDetail.index !== 20 && refShowDetail.current === 20
        if (refDataLoad.current && open) {
            // console.log('data input in SupportPlan')
            const dispData = { ...fbData['20'] }
            // console.log('disp20', dispData)
            inputData(dispData, methods.setValue)
            if (dispData['update_date'] !== '') {
                refDataLoad.current = false
            }
        } else if (close) {
            // console.log('close and setFbData in SupportPlan')
            // const cloneData = clonedeep(fbData)
            // console.log('cloneData in SupportPlan', cloneData)
            // const formData = methods.getValues()
            // cloneData['20'] = insertByDispId('20', formData, cloneData)
            // console.log('cloneData editted in SupportPlan', cloneData)
            // setFbData(cloneData)
            refDataLoad.current = true
        }
    }, [fbData, setFbData, methods, showDetail])
    useEffect(() => {
        // console.log('showDetail in  SupportPlan :', showDetail)
        refShowDetail.current = showDetail.index
    }, [showDetail])
    // const resetText = () => {
    //     methods.reset()
    // }
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backToShowDetail = useCallback(() => {
        const cancelEdit = () => {
            // console.log('yes')
            const pathName = history.location.pathname
            history.replace({ pathname: pathName })
            window.location.reload()
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        setShowDialog({
            ...showDialog,
            show: true,
            message: '相談の詳細・編集画面へ戻り、編集中の内容は消去します。\nよろしいですか？',
            doYes: cancelEdit,
            doNo: hideDialog,
            textYes: 'はい',
            textNo: 'いいえ',
        })
    }, [history, showDialog])
    //ユーザーデータが無い時の処理(送信ボタンを押せなくする、管理者権限閲覧時も同様)
    const [sendable, setSendable] = useState({ send: false, reasonText: '' })
    useEffect(() => {
        const isUserId = 'user_id' in userData
        const isShisetsuId = 'shisetsu_id' in userData
        // console.log({ isUserId, isShisetsuId })
        if (!isUserId) {
            console.error('userIdがありません')
            setSendable({ send: false, reasonText: 'ユーザーIDが無いため' })
        } else if (!isShisetsuId) {
            console.error('shisetsuIdがありません')
            setSendable({ send: false, reasonText: '施設IDが無いため' })
        } else if (isUserId && isShisetsuId) {
            // console.log('userData in EditConsult', userData)
            const uFacilityId = userData.shisetsu_id
            const uKengen = userData.kengen
            const isMatchFacilityId = fbData['10']['shisetsu_id'] === uFacilityId
            const isAdmin = ['1', '2'].includes(uKengen)
            // console.log({ uFacilityId, uKengen, isMatchFacilityId, isAdmin })
            if (isAdmin && !isMatchFacilityId) {
                setSendable({ send: false, reasonText: '確認用権限のため' })
            } else {
                setSendable({ send: true, reasonText: '' })
            }
        }
    }, [userData, fbData])
    const unSendable = useCallback(
        (text) => {
            const hideDialog = () => {
                setShowDialog({ ...showDialog, show: false })
            }
            const reload = () => {
                const pathName = history.location.pathname
                history.replace({ pathname: pathName })
                window.location.reload()
            }
            const message = `${text}現在送信できません\nページをリロードするか(入力内容は消去されます)\nウインドウを閉じてしばらくお待ち下さい\n待っても送信できない時はログインし直してみてください`
            setShowDialog({
                ...showDialog,
                show: true,
                message: message,
                doYes: reload,
                doNo: hideDialog,
                textYes: 'リロード',
                textNo: '閉じる',
            })
        },
        [showDialog, history]
    )
    const onSubmit = (data) => {
        const cloneData = clonedeep(data)
        // console.log('FormData', cloneData)
        const dataSptPlan = { ...fbData['20'] }
        Object.assign(dataSptPlan, cloneData)
        //マスタデータ(ユーザーID,施設ID,画面ID,処理区分)を追加
        dataSptPlan['user_id'] = userData.user_id
        dataSptPlan['shisetsu_id'] = userData.shisetsu_id
        dataSptPlan['disp_id'] = '20'
        dataSptPlan['shori_div'] = '1'
        //事例番号は消去
        // dataSptPlan['jirei_no'] = ''
        delete dataSptPlan['jirei_no']
        //改行コードを置換(画面40は日付置換と同時に行う)
        //画面20
        dataSptPlan['seikatsukankyo_shien_hitsuyo'] = replaceLineCode(dataSptPlan['seikatsukankyo_shien_hitsuyo'])
        dataSptPlan['chiryo_shien_hitsuyo'] = replaceLineCode(dataSptPlan['chiryo_shien_hitsuyo'])
        dataSptPlan['keizai_enjo_hitsuyo'] = replaceLineCode(dataSptPlan['keizai_enjo_hitsuyo'])
        dataSptPlan['kento_fukushi_seido'] = replaceLineCode(dataSptPlan['kento_fukushi_seido'])
        dataSptPlan['shakaikoken_shien_mokuhyo'] = replaceLineCode(dataSptPlan['shakaikoken_shien_mokuhyo'])
        //日付を追加,書き換え(obj=>str)
        //画面共通
        dataSptPlan['update_date'] = getCurrentDate()
        console.log('disp20 SendData', dataSptPlan)
        const response = postToCf(dataSptPlan)
        feedBackDisplay(response, setShowModal)
    }
    return (
        <div className='w-full flex flex-col items-center'>
            <Modal showModal={showModal} />
            <Dialog showDialog={showDialog} />
            <FormProvider {...methods}>
                <form className='w-full mb-4 px-2 sm:max-w-2xl sm:mx-8 flex flex-col items-center' name='SupportPlanForm' onSubmit={methods.handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
                    <SupportPlanDetail />
                    <div className='pt-6 pb-4 max-w-xl ml-auto flex flex-col items-end sm:flex-row sm:justify-end flex-wrap gap-x-4 gap-y-3'>
                        {sendable.send ? <Button type='submit' value='送信' /> : <Button type='button' value='送信' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />}
                        <Button type='button' value='キャンセル' onClick={backToShowDetail} outline={true} />
                    </div>
                </form>
                {/* <DevTool control={methods.control} /> */}
            </FormProvider>
        </div>
    )
}
