import React, { useState, useEffect } from 'react'
import { XCircleIcon } from '@heroicons/react/outline'

export default function PastCaseFullTextBlock({ fullText, setFullText }) {
    const style = {
        background: 'fixed flex justify-center items-center inset-0 w-full h-full px-8 sm:px-10 py-24 sm:pt-28 sm:pb-32 bg-opacity-50 bg-gray-500 z-40',
        container:
            'relative w-full lg:w-3/5 max-h-full overflow-y-hidden overflow-y-scroll px-4 py-8 sm:px-6 py-6 rounded-lg border-2 bg-gray-300 border-gray-500 text-gray-700 text-lg whitespace-pre-wrap',
        xCircleIcon: 'absolute top-1 right-1 sm:top-4 sm:right-2 w-8 h-8 sm:w-16 sm:h-16 text-gray-500 cursor-pointer',
        fullText: 'h-auto leading-loose',
    }
    const closeFullText = () => {
        setFullText({ ...fullText, isDislay: false })
    }
    return (
        <>
            <div
                id='fulltext_background'
                className={style.background}
                onClick={(e) => {
                    // console.log(e.target.id)
                    if (e.target.id === 'fulltext_background') {
                        closeFullText()
                    }
                }}
            >
                <div className={style.container}>
                    <XCircleIcon className={style.xCircleIcon} onClick={closeFullText} />
                    <p className={style.fullText}>{fullText.innerText}</p>
                </div>
            </div>
        </>
    )
}
