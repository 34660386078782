import { useState, useEffect, useCallback } from 'react'
import { firebase, db } from '../func/firebase'
import { Button } from '../atom'

export default function DebugCompo() {
    const [checkedData, setCheckedData] = useState({
        many: '\nなし',
        few: '\nなし',
    })
    const checkProps = useCallback(async () => {
        try {
            const targetId = document.getElementById('targetId').value
            // console.log({ targetId })
            const fbObj = {}
            const refDoc = db.collection('taishosha_id').doc(targetId)
            const snapCol = await refDoc.get()
            if (snapCol.exists) {
                const search = snapCol.data()
                fbObj['Search'] = search
                // console.log(search)
            }
            const refSubCol = refDoc.collection('disp_id')
            const snapSubCol = await refSubCol.get()
            snapSubCol.forEach((doc) => {
                // console.log(doc.id, '=>', doc.data())
                fbObj[doc.id] = doc.data()
            })
            // console.log(fbObj)
            if (!Object.keys(fbObj).length) throw new Error('対象者IDがDBに存在しません')
            const defaultObj = {
                10: {
                    shokaitaio: '',
                    shori_div: '',
                    shisetsu_id: '',
                    disp_id: '',
                    taishosha_id: '',
                    uketsuke_hoho: '',
                    kongo_mikomi: '',
                    uketsuke_date: '',
                    uketsuke_hoho_sonota: '',
                    version_no: '',
                    user_id: '',
                    update_date: '',
                    kanri_no: '',
                },
                11: {
                    kyoryokusha_name: '',
                    user_id: '',
                    setai_jokyo: '',
                    kyoryokusha_kankei: '',
                    sumai_shakuya_kbn: '',
                    seikatsu_jokyo: '',
                    disp_id: '',
                    yachin: '',
                    shien_title: '',
                    taishosha_id: '',
                    version_no: '',
                    setai_jokyo_sonota: '',
                    sumai_sonota: '',
                    shokai_date: '',
                    shokaimendan_date: '',
                    shori_div: '',
                    sumai_mochiie_loan: '',
                    tomentaio: '',
                    kazoku_list: [
                        {
                            shuro_shugaku_jokyo: '',
                            kazoku_name: '',
                            kenko_jotai: '',
                            kazoku_no: '',
                            kazoku_nenrei: '',
                            kazoku_zokugara: '',
                            biko: '',
                        },
                    ],
                    shisetsu_id: '',
                    update_date: '',
                    kanri_no: '',
                    kyoryokusha_name_kana: '',
                    csw_name: '',
                    sumai: '',
                    kyoryokusha_tel: '',
                },
                12: {
                    update_date: '',
                    iryo_hokensho: '',
                    shunyu_shishutsu_free: '',
                    kanri_no: '',
                    version_no: '',
                    shakaikankei_jokyo: '',
                    taishosha_id: '',
                    shushi_jokyo_sonota: '',
                    riyochu_seido_biko: '',
                    shushi_jokyo_list: [
                        {
                            shushi_jokyo: '',
                        },
                    ],
                    disp_id: '',
                    job: '',
                    user_id: '',
                    shisetsu_id: '',
                    shinshin_jokyo: '',
                    shori_div: '',
                    shogaiteido_kubun: '',
                    sodansha_keyword_list: [
                        {
                            sodansha_keyword: '',
                        },
                    ],
                    yokaigodo: '',
                    sonota_shogaijoho: '',
                    shogai_techo: '',
                },
                20: {
                    keizai_enjo_hitsuyo: '',
                    kanri_no: '',
                    disp_id: '',
                    user_id: '',
                    shori_div: '',
                    version_no: '',
                    kento_fukushi_seido: '',
                    shakaikoken_shien_mokuhyo: '',
                    taishosha_id: '',
                    update_date: '',
                    seikatsukankyo_shien_hitsuyo: '',
                    shisetsu_id: '',
                    chiryo_shien_hitsuyo: '',
                },
                40: {
                    user_id: '',
                    taishosha_id: '',
                    disp_id: '',
                    kanri_no: '',
                    shori_div: '',
                    update_date: '',
                    shisetsu_id: '',
                    version_no: '',
                    enjokeika_list: [
                        {
                            enjo_keika_naiyo: '',
                            enjo_date: '',
                            enjo_no: '',
                        },
                    ],
                },
                50: {
                    first_send: true,
                    renkei_kikan_gyosei_free: '',
                    konkai_shien_jikohyoka: '',
                    shien_visit_count: '',
                    kikan_tel: '',
                    disp_id: '',
                    shien_months: '',
                    update_date: '',
                    kikan_kind_gyosei: '',
                    zan_kadai: '',
                    honnin_henka: '',
                    shien_start_date: '',
                    kikan_kind_sonota: '',
                    shien_doko_count: '',
                    kikan_fax: '',
                    enjogo_seikatsu_sonota: '',
                    kanri_no: '',
                    shien_end_date: '',
                    renkei_kikan_gyosei: '',
                    shien_days: '',
                    user_id: '',
                    kikan_tantosha: '',
                    renkei_kikan_sonota: '',
                    renkei_kikan_list: [
                        {
                            renkei_kikan: '',
                        },
                    ],
                    version_no: '',
                    kikan_kind_gyosei_free: '',
                    kikan_name: '',
                    shien_total_time: '',
                    kikan_kind: '',
                    taishosha_id: '',
                    enjogo_seikatsu_list: [
                        {
                            enjogo_seikatsu: '',
                        },
                    ],
                    shuketsu_riyu: '',
                    kikan_adr: '',
                    shisetsu_id: '',
                    shori_div: '',
                },
                70: {
                    update_date: '',
                    taishosha_id: '',
                    jirei_no: '',
                    shori_div: '',
                    shisetsu_id: '',
                    disp_id: '',
                    // "version_no": "",
                    user_id: '',
                    kanri_no: '',
                },
                101: {
                    post_no: '',
                    user_id: '',
                    shokai_keiro_sonota: '',
                    birthday: '',
                    disp_id: '',
                    nenrei: '',
                    shori_div: '',
                    update_date: '',
                    tel_keitai: '',
                    kanri_no: '',
                    tel: '',
                    taishosha_name: '',
                    adr_dtl: '',
                    shokai_keiro: '',
                    shisetsu_id: '',
                    gender: '',
                    taishosha_id: '',
                    shiku_name: '',
                    shokai_keiro_gyosei: '',
                    taishosha_name_kana: '',
                    shokai_keiro_gyosei_free: '',
                    shokaisha: '',
                    shokai_naiyo: '',
                    version_no: '',
                },
                102: {
                    disp_id: '',
                    update_date: '',
                    taishosha_id: '',
                    shori_div: '',
                    sodansha_shubetsu_sonota: '',
                    version_no: '',
                    user_id: '',
                    seiho_kbn: '',
                    sodansha_shubetsu_list: [
                        {
                            sodansha_shubetsu: '',
                        },
                    ],
                    kanri_no: '',
                    shisetsu_id: '',
                },
                Search: {
                    taishosha_id: '',
                    kanri_no: '',
                    gender: '',
                    taishosha_name: '',
                    seiho_kbn: '',
                    taishosha_name_kana: '',
                    csw_name: '',
                    kongo_mikomi: '',
                    sodansha_shubetsu_sonota: '',
                    shokaimendan_date: '',
                    nenrei: '',
                    sodansha_shubetsu_list: [],
                    shiku_name: '',
                    jirei_no: '',
                    shien_title: '',
                    shisetsu_id: '',
                },
            }
            const dispIds = [10, 11, 12, 20, 40, 50, 70, 101, 102, 'Search']
            const manyTexts = []
            const fewTexts = []
            dispIds.forEach((dispId) => {
                // console.log(defaultObj[dispId])
                const fbKeys = Object.keys(fbObj[dispId])
                const defaultKeys = Object.keys(defaultObj[dispId])
                let manyKeys = fbKeys.filter((i) => defaultKeys.indexOf(i) === -1).sort()
                const fewKeys = defaultKeys.filter((i) => fbKeys.indexOf(i) === -1).sort()
                if (dispId === 'Search') {
                    if (manyKeys.length > 0) {
                        manyKeys = manyKeys.filter((v) => !v.includes('setai_jokyo'))
                        manyKeys = manyKeys.filter((v) => !v.includes('sodansha_shubetsu'))
                        manyKeys = manyKeys.filter((v) => !v.includes('sodansha_keyword'))
                        manyKeys = manyKeys.filter((v) => !v.includes('shushi_jokyo'))
                    }
                }
                if (manyKeys.length > 0) manyTexts.push(`\ndisp${dispId}: ${manyKeys.join(', ')}`)
                if (fewKeys.length > 0) fewTexts.push(`\ndisp${dispId}: ${fewKeys.join(', ')}`)
            })
            // console.log({ manyTexts, fewTexts })
            const result = { many: '\nなし', few: '\nなし' }
            if (manyTexts.length > 0 || fewTexts.length > 0) {
                if (manyTexts.length > 0) result.many = manyTexts.join('')
                if (fewTexts.length > 0) result.few = fewTexts.join('')
            }
            setCheckedData({ ...result })
        } catch (error) {
            console.error(error)
            const result = { many: error.message, few: error.message }
            setCheckedData({ ...result })
        }
    }, [])
    const [checkFac, setCheckFac] = useState('')
    const checkFacility = useCallback(async () => {
        try {
            let queryFac = db.collection('taishosha_id')
            const snapshotFac = await queryFac.orderBy('shokaimendan_date', 'desc').get()
            // const snapshotFac = await queryFac.orderBy('shokaimendan_date', 'desc').limit(100).get()
            const docsFac = []
            snapshotFac.forEach((doc) => {
                // console.log('searchedDate', doc.id, ' => ', doc.data())
                docsFac.push(doc.data())
            })
            // console.log(docsFac)
            const facIdAll = docsFac.map((v) => v['shisetsu_id'])
            const facIds = Array.from(new Set(facIdAll))
            // console.log(facIds)
            const facIdObjs = []
            facIds.forEach((id) => {
                const obj = {}
                obj['id'] = id
                const number = facIdAll.filter((ele) => ele === id).length
                obj['number'] = number
                facIdObjs.push(obj)
            })
            facIdObjs.sort((a, b) => {
                return a['number'] > b['number'] ? -1 : 1
            })
            // console.log(facIdObjs)
            let queryFacInfo = db.collectionGroup('shisetsu_id')
            const snapshotFacInfo = await queryFacInfo.orderBy('shisetsu_id').get()
            // const snapshotFacInfo = await queryFac.orderBy('shisetsu_id').limit(10).get()
            const docsFacInfo = []
            snapshotFacInfo.forEach((doc) => {
                // console.log('searchedDate', doc.id, ' => ', doc.data())
                docsFacInfo.push(doc.data())
            })
            // console.log(docsFacInfo)
            const facInfos = docsFacInfo.map((v) => ({
                shisetsu_id: v['shisetsu_id'],
                shisetsu_name: v['shisetsu_name'],
                hojin_id: v['hojin_id'],
                hojin_name: v['hojin_name'],
            }))
            // console.log(facInfos)
            const getFacInfo = (id) => {
                return facInfos.find((v) => v['shisetsu_id'] === id)
            }
            const facIdText = []
            facIdObjs.forEach((v) => {
                // console.log(v)
                // console.log(getFacInfo(v['id']))
                const facInfo = getFacInfo(v['id'])
                if (facInfo) {
                    const hojin_name = 'hojin_name' in facInfo ? facInfo['hojin_name'] : '登録なし'
                    const hojin_id = 'hojin_id' in facInfo ? facInfo['hojin_id'] : '登録なし'
                    const shisetsu_name = 'shisetsu_name' in facInfo ? facInfo['shisetsu_name'] : '登録なし'
                    facIdText.push(`${hojin_name}(${hojin_id})/${shisetsu_name}(${v['id']}) : ${v['number']}件\n`)
                } else {
                    facIdText.push(`不明(-)/不明(${v['id']}) : ${v['number']}件\n`)
                    console.log('undefined shisetsu ID', v['id'])
                }
            })
            // console.log(facIdTexts)
            setCheckFac(`全 ${docsFac.length}件中\n${facIdText.join('')}`)
        } catch (error) {
            console.error(error)
        }
    }, [])
    // const hoge = useCallback(async () => {
    //     let queryFac = db.collectionGroup('shisetsu_id')
    //     const snapshotFac = await queryFac.orderBy('shisetsu_id').get()
    //     // const snapshotFac = await queryFac.orderBy('shisetsu_id').limit(10).get()
    //     const docsFac = []
    //     snapshotFac.forEach((doc) => {
    //         // console.log('searchedDate', doc.id, ' => ', doc.data())
    //         docsFac.push(doc.data())
    //     })
    //     // console.log(docsFac)
    //     const fuga = docsFac.map((v) => ({
    //         shisetsu_id: v['shisetsu_id'],
    //         shisetsu_name: v['shisetsu_name'],
    //         hojin_id: v['hojin_id'],
    //         hojin_name: v['hojin_name'],
    //     }))
    //     console.log(fuga)
    //     // const foo = fuga.find((v) => v.shisetsu_id === '1008')
    // }, [])

    return (
        <div className='w-full h-full'>
            <div className='w-full sm:max-w-2xl pt-4 px-2 sm:mx-8 flex flex-col items-start text-base sm:text-xl text-gray-800'>
                <div className='w-full flex flex-col py-4'>
                    <p>DB(FireStore)のプロパティが過不足ないかチェック</p>
                    <div className='flex mt-4'>
                        <input id='targetId' type='text' size='10' maxLength={'20'} className='h-12 rounded-lg border-gray-500'></input>
                        <button onClick={checkProps} className='ml-4 px-4 sm:px-6 py-1 rounded-lg text-center text-md sm:text-xl bg-gray-50 text-rose-500 border-2 border-rose-500 '>
                            対象者ID
                        </button>
                    </div>
                    <div className='w-full flex flex-col mt-4 '>
                        <p className='flex break-words whitespace-pre-wrap'>{`余分なプロパティ ${checkedData.many}`}</p>
                        <p className='mt-4 flex break-words whitespace-pre-wrap'>{`足りないプロパティ ${checkedData.few}`}</p>
                    </div>
                </div>
                <div className='w-full py-4 border-t border-gray-700'>
                    <p>全相談データから施設IDごとの相談件数を表示</p>
                    <button onClick={checkFacility} className='mt-4 px-4 sm:px-6 py-1 rounded-lg text-center text-md sm:text-xl bg-gray-50 text-rose-500 border-2 border-rose-500 '>
                        件数チェック
                    </button>
                    <p className='flex break-words whitespace-pre-wrap leading-loose'>{checkFac}</p>
                </div>
                {/* <button onClick={hoge} className='mt-4 px-4 sm:px-6 py-1 rounded-lg text-center text-md sm:text-xl bg-gray-50 text-rose-500 border-2 border-rose-500 '>
                    hoge
                </button> */}
            </div>
        </div>
    )
}
