import React, { useEffect, useState } from 'react'
import { ExclamationIcon } from '@heroicons/react/outline'

export default function Dialog({ showDialog }) {
    const style = {
        container: 'justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none',
        windowHeader: 'w-full h-4 rounded-t-lg xborder-0 outline-none focus:outline-none',
        windowContainer: 'w-full relative flex flex-col border-0 rounded-lg shadow-lg  bg-gray-50 outline-none focus:outline-none',
        contentsContainer: 'px-10 py-6 flex flex-col items-center text-lg sm:text-2xl',
        exIcon: 'w-12 h-12 text-yellow-400 mb-2',
        text: 'relative flex-auto text-gray-500 whitespace-pre-wrap',
        background: 'opacity-25 fixed inset-0 z-40 bg-black',
        buttonContainer: 'w-full flex flex-row mt-6 justify-end',
        buttonOK: 'mr-4 y-1 px-4 sm:px-6 py-1 flex rounded-lg text-center text-md sm:text-2xl bg-teal-500 hover:bg-opacity-70 text-gray-50 border-2 border-teal-500 hover:border-transparent',
        buttonNG: 'y-1 px-4 sm:px-6 py-1 flex rounded-lg text-center text-md sm:text-2xl bg-gray-50 hover:bg-opacity-70 text-teal-500 border-2 border-teal-500 hover:border-transparent',
    }
    const [messageSize, SetMessageSize] = useState('')
    useEffect(() => {
        const textCount = showDialog.message?.length ? showDialog.message?.length : ''
        // console.log('文字数: ', textCount)
        if (textCount >= 50) {
            SetMessageSize('text-base sm:text-lg')
        }
    }, [showDialog])

    return (
        <>
            {showDialog.show && (
                <>
                    <div className={style.container}>
                        <div className='relative w-3/4 sm:max-w-sm'>
                            {/*content*/}
                            <div className={style.windowContainer}>
                                {/* header */}
                                {<span className={`${style.windowHeader} bg-yellow-400`}></span>}
                                {/*body*/}
                                <div className={style.contentsContainer}>
                                    <ExclamationIcon className={style.exIcon} />
                                    <div className={`${messageSize} ${style.text}`}>{showDialog.message}</div>
                                    <div className={style.buttonContainer}>
                                        <button className={style.buttonOK} onClick={() => showDialog.doYes()}>
                                            {showDialog.textYes}
                                        </button>
                                        <button className={style.buttonNG} onClick={() => showDialog.doNo()}>
                                            {showDialog.textNo}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.background}></div>
                </>
            )}
        </>
    )
}
