import { useState, useEffect, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { DocumentAddIcon, BookOpenIcon, SearchIcon, QuestionMarkCircleIcon, MailIcon, BellIcon } from '@heroicons/react/outline'
import { useAuthContext } from '../func'
import { firebase, auth, db } from '../func/firebase'
import { eraYear } from '../func/CommonFunc'
import { Button, TextArea } from '../atom'

export default function News() {
    const { userData, setUserData } = useAuthContext()
    const style = {
        container: 'w-full px-6 pt-2 pb-4 flex flex-col items-center text-2xl sm:text-3xl',
        navList: 'mt-8 flex flex-col justify-center items-start',
        navItem: 'py-5 flex flex-row justify-center items-center',
        navIcon: 'mr-2 w-8 h-8 sm:w-10 sm:h-10 text-gray-800',
        newsContainer: 'w-full mt-4 p-4 sm:w-1/2 flex flex-col justify-center items-center bg-gray-200 border border-gray-400 rounded-lg sm:rounded-xl',
        loaderIcon: 'animate-spin h-8 w-8 sm:w-10 sm:h-10 my-4 border-4 border-teal-500 rounded-full ',
        newsHeader: 'w-full py-1 flex items-end text-xl sm:text-2xl text-teal-600',
        newsIcon: 'mr-2 w-6 h-6 sm:w-8 sm:h-8',
        newsTopicContainer: 'w-full pt-2 pb-1 flex flex-col border-b border-gray-300 text-sm sm:text-base text-gray-700 whitespace-pre-wrap',
        newsTopicHeader: 'w-full flex flex-row items-center text-gray-500',
        newsTopicTag: 'ml-2 px-2 bg-gray-300 rounded text-xs text-gray-500',
        newsTopicTitle: 'w-full py-1 flex flex-row text-base',
    }
    const sendApi = useCallback(async () => {
        try {
            const json = document.getElementById('api').value
            const sendData = JSON.parse(json)
            console.log('request body:', sendData)
            const functions = firebase.functions('asia-northeast2')
            const cfFunc = functions.httpsCallable('ConductAxiosTest')
            const res = await cfFunc(sendData)
            console.log('res body:', res.data)
            console.log('データ送信に成功しました')
        } catch (err) {
            console.error('Error:', err)
            console.log('データ送信に失敗しました')
            // throw new Error(error)
        }
    }, [])

    const dateToStr = useCallback((date) => {
        //日付文字列をオブジェクト化
        if (!isNaN(new Date(date))) {
            const stringToDate = new Date(date)
            const year = stringToDate.getFullYear().toString()
            const month = stringToDate.getMonth() + 1
            const day = stringToDate.getDate()
            const wareki = eraYear(year)
            const dayOfWeek = stringToDate.getDay() // 曜日(数値)
            const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeek] // 曜日(日本語表記)
            const format = `${year}/${month}/${day} (${dayOfWeekStr})`
            return format
        } else return date
    }, [])
    const [news, SetNews] = useState([])
    useEffect(() => {
        const getGas = async () => {
            try {
                const res = await fetch(process.env.REACT_APP_NEWS_GAS_URL, {
                    method: 'POST',
                    body: JSON.stringify({ req: 1 }),
                })
                const resTxt = await res.text()
                const resObj = JSON.parse(resTxt)
                // console.log(resObj)
                // const resJson = JSON.stringify(resObj)
                // console.log(resJson)
                const newsArrAll = resObj.result
                newsArrAll.sort((a, b) => (a[0] < b[0] ? 1 : -1)) //日付順にソート
                // console.log(newsArrll)
                const newsArrSliced = newsArrAll.length >= 5 ? newsArrAll.slice(0, 5) : newsArrAll
                console.log(newsArrSliced)
                newsArrSliced.forEach((ele) => (ele[0] = dateToStr(ele[0])))
                const newsArrObj = newsArrSliced.map((ele) => {
                    const obj = {}
                    obj['date'] = ele[0]
                    obj['tag'] = ele[1]
                    obj['title'] = ele[2]
                    obj['content'] = ele[3]
                    return obj
                })
                // console.log(newsArrObj)
                SetNews(newsArrObj)
            } catch (err) {
                console.error(err)
            }
        }
        getGas()
    }, [dateToStr])

    return (
        <div className='w-full h-full flex flex-col justify-center items-center text-3xl'>
            <h2>お知らせ</h2>
            <div className='w-0 h-0 bg-rose-500'></div>
        </div>
    )
}
