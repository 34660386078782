import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { InputDate, SelectBox, CheckBox, InputText, TextArea, FormBlock } from '../atom'
import { SwitchDisplay } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let SptRTBlockRender = 0
export default function SupportRecordTarget(props) {
    // SptRTBlockRender++
    // console.log('SptRTBlockRender', SptRTBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const enjogo_seikatsu_options = selectionOptions.enjogo_seikatsu_options
    // const enjogo_seikatsu_defaultValue = enjogo_seikatsu_options.map((v) => false)
    // const enjogo_seikatsu_watch = useWatch({
    //     control,
    //     name: 'enjogo_seikatsu',
    //     defaultValue: enjogo_seikatsu_defaultValue,
    // })
    // useEffect(() => {
    //     // console.log('enjogo_seikatsu_watch', enjogo_seikatsu_watch)
    //     const enjogoArg = {
    //         watchValue: enjogo_seikatsu_watch,
    //         targetName: 'enjogo_seikatsu_sonota',
    //         targetId: 'enjogo_seikatsu_sonota',
    //         checkValue: '999',
    //         // focusMethod: setFocus, //setFocusできないため省略
    //         setValueMethod: setValue,
    //     }
    //     SwitchDisplay(enjogoArg)
    //     // if (enjogo_seikatsu_watch?.includes('999')) {
    //     //     setFocus('enjogo_seikatsu_sonota')
    //     // }
    // }, [enjogo_seikatsu_watch, setValue])
    const contents = [
        <TextArea
            label='支援前後の本人の変化'
            name='honnin_henka'
            id='honnin_henka'
            register={register('honnin_henka', {})}
            // errorText={errors.honnin_henka?.message}
            placeholder='タッチすると拡大表示します'
            // subText='※家族状況・居所・収入・就労等'
            // isRequired={true}
            expandSize={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
        <CheckBox
            label='援助後の生活'
            name='enjogo_seikatsu'
            id='enjogo_seikatsu'
            options={enjogo_seikatsu_options}
            // errorText={errors.enjogo_seikatsu?.message}
            // isRequired={true}
        />,
        <InputText
            label='援助後の生活-その他'
            name='enjogo_seikatsu_sonota'
            id='enjogo_seikatsu_sonota'
            register={register('enjogo_seikatsu_sonota', {})}
            type='text'
            // errorText={errors.enjogo_seikatsu_sonota_etc?.message}
            placeholder=''
            maxLength='100'
        />,
    ]
    return (
        <>
            <FormBlock categoryName='対象者' formItems={contents} />
        </>
    )
}
