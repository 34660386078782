import fb from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/analytics'

//テスト環境
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_API_KEY_DEV,
//     authDomain: process.env.REACT_APP_Auth_Domain_DEV,
//     projectId: process.env.REACT_APP_Project_Id_DEV,
//     storageBucket: process.env.REACT_APP_Storage_Bucket_DEV,
//     messagingSenderId: process.env.REACT_APP_Messaging_Sender_Id_DEV,
//     appId: process.env.REACT_APP_App_Id_DEV,
// }

//本番環境
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_PROD,
    authDomain: process.env.REACT_APP_Auth_Domain_PROD,
    projectId: process.env.REACT_APP_Project_Id_PROD,
    storageBucket: process.env.REACT_APP_Storage_Bucket_PROD,
    messagingSenderId: process.env.REACT_APP_Messaging_Sender_Id_PROD,
    appId: process.env.REACT_APP_App_Id_PROD,
    measurementId: process.env.REACT_APP_Measurement_Id,
}

// Initialize firebase
// const app = firebase.initializeApp(firebaseConfig)
export const firebase = !fb.apps.length ? fb.initializeApp(firebaseConfig) : fb.app()
export const storage = firebase.storage()
export const db = firebase.firestore()
// export const func = firebase.functions().useEmulator('localhost', 5001)
export const func = firebase.functions()
export const auth = firebase.auth()
