import { useEffect, useState, useCallback } from 'react'
import { MenuIcon } from '@heroicons/react/solid'
import { useLocation, useHistory } from 'react-router'
import { Dialog } from '../atom'

export default function Header({ isOpen, setIsOpen }) {
    const location = useLocation()
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backHome = useCallback(() => {
        const backToHome = () => {
            setShowDialog({ ...showDialog, show: false })
            history.push('/')
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        const path = location.pathname
        if (path !== '/') {
            setShowDialog({
                ...showDialog,
                show: true,
                message: 'トップページへ戻ります。\nよろしいですか？',
                doYes: backToHome,
                doNo: hideDialog,
                textYes: 'はい',
                textNo: 'いいえ',
            })
        }
    }, [history, showDialog, location.pathname])
    const [pageTitle, SetPageTitle] = useState('')
    useEffect(() => {
        // console.log('location in Header: ', location.search)
        const displayEditConsult = () => {
            const query = new URLSearchParams(location.search)
            const dispId = query.get('disp')
            if (dispId === '10') {
                SetPageTitle(`相談の詳細・編集 - 相談受付`)
            } else if (dispId === '11') {
                SetPageTitle(`相談の詳細・編集 - 相談受付（個人情報）`)
            } else if (dispId === '12') {
                SetPageTitle(`相談の詳細・編集 - 相談受付（相談内容）`)
            } else if (dispId === '20') {
                SetPageTitle(`相談の詳細・編集 - 支援計画`)
            } else if (dispId === '40') {
                SetPageTitle(`相談の詳細・編集 - 支援経過`)
            } else if (dispId === '50') {
                SetPageTitle(`相談の詳細・編集 - 支援終結記録`)
            } else {
                SetPageTitle(`相談の詳細・編集`)
            }
        }
        const displayPath = () => {
            const path = location.pathname
            if (path === '/') {
                SetPageTitle('ホーム')
            } else if (path === '/newconsult') {
                SetPageTitle('新規相談受付')
            } else if (path === '/consulthistory') {
                SetPageTitle('相談履歴')
            } else if (path === '/pastcasesearch') {
                SetPageTitle('過去事例検索')
            } else if (path.includes('/usermanual')) {
                SetPageTitle('アプリの使い方')
            } else if (path.includes('/contact')) {
                SetPageTitle('お問い合わせ')
            } else if (path.includes('/debug')) {
                SetPageTitle('デバッグ')
            } else if (path.includes('/editconsult')) {
                displayEditConsult()
            } else {
                SetPageTitle('')
            }
        }
        displayPath()
    }, [location])
    return (
        <>
            <Dialog showDialog={showDialog} />
            <header className='w-full flex flex-row justify-center border-b border-gray-400'>
                <div className='w-full sm:max-w-2xl flex flex-row justify-between items-center px-2 sm:mx-8'>
                    <div id='navOpen' className='text-center sm:text-lg text-gray-600' onClick={() => setIsOpen(true)}>
                        <MenuIcon className='w-8 h-8 sm:w-12 sm:h-12' />
                    </div>
                    <div className='text-sm'>{pageTitle}</div>
                    <span onClick={backHome}>
                        <img alt='logo' src='/osn_logo.svg' />
                    </span>
                </div>
            </header>
        </>
    )
}
