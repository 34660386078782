import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CheckBox, InputText, TextArea, RadioButton, FormBlock } from '../atom'
import { SwitchDisplay } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let NCDBlockRender = 0
export default function NewConsultDetail(props) {
    // NCDBlockRender++
    // console.log('NCDBlockRender', NCDBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const sodansha_shubetsu_options = selectionOptions.sodansha_shubetsu_options
    const seiho_kbn_options = selectionOptions.seiho_kbn_options
    const kongo_mikomi_options = selectionOptions.kongo_mikomi_options
    const contents = [
        <CheckBox
            label='相談者種別(複数選択可)'
            name='sodansha_shubetsu'
            id='sodansha_shubetsu_newconsult'
            options={sodansha_shubetsu_options}
            // errorText={errors.sodansha_shubetsu?.message}
            // isRequired={true}
        />,
        <RadioButton
            label='相談者種別-生活保護'
            name='seiho_kbn'
            id='seiho_kbn_newconsult'
            register={register('seiho_kbn', {})}
            options={seiho_kbn_options}
            // errorText={errors.seiho_kbn?.message}
            // isRequired={true}
        />,
        <InputText
            label='相談者種別-その他'
            name='sodansha_shubetsu_sonota'
            id='sodansha_shubetsu_sonota_newconsult'
            register={register('sodansha_shubetsu_sonota', {})}
            type='text'
            // errorText={errors.sodansha_shubetsu_sonota_etc?.message}
            placeholder=''
            maxLength='500'
        />,
        <TextArea
            label='相談内容'
            name='shokai_naiyo'
            id='shokai_naiyo_newconsult'
            register={register('shokai_naiyo', {})}
            // errorText={errors.shokai_naiyo?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            printDisplay={{ display: true, countByLine: 30, lines: 8 }}
        />,
        <TextArea
            label='初回の対応'
            name='shokaitaio'
            register={register('shokaitaio', {})}
            // errorText={errors.shokaitaio?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            printDisplay={{ display: true, countByLine: 30, lines: 23 }}
        />,
        <RadioButton
            label='今後の見込み'
            name='kongo_mikomi'
            id='kongo_mikomi_newconsult'
            options={kongo_mikomi_options}
            register={register('kongo_mikomi', {})}
            errorText={errors.kongo_mikomi?.message}
            isRequired={true}
        />,
    ]

    const sodansha_shubetsu_defaultValue = sodansha_shubetsu_options.map((v) => false)
    const sodansha_shubetsu_watch = useWatch({
        control,
        name: 'sodansha_shubetsu',
        defaultValue: sodansha_shubetsu_defaultValue,
    })
    // console.log(sodansha_shubetsu_watch)
    useEffect(() => {
        const welfareArg = {
            watchValue: sodansha_shubetsu_watch,
            targetName: 'seiho_kbn',
            targetId: 'seiho_kbn_newconsult',
            checkValue: '009',
            // focusMethod: setFocus, //setFocusできないため省略
            setValueMethod: setValue,
        }
        // const commentArg = {
        //     watchValue: sodansha_shubetsu_watch,
        //     targetName: 'sodansha_shubetsu_sonota',
        //     targetId: 'sodansha_shubetsu_sonota_newconsult',
        //     checkValue: '999',
        //     // focusMethod: setFocus,
        //     setValueMethod: setValue,
        // }
        SwitchDisplay(welfareArg)
        // SwitchDisplay(commentArg)
        // if (sodansha_shubetsu_watch?.includes('999')) {
        //     setFocus('sodansha_shubetsu_sonota')
        // }
    }, [sodansha_shubetsu_watch, setValue, setFocus])

    return (
        <>
            <FormBlock categoryName='相談内容と対応' formItems={contents} />
        </>
    )
}
