import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SelectBox, CheckBox, InputText, TextArea, FormBlock } from '../atom'
import { SwitchDisplay } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let SptRFBlockRender = 0
export default function SupportRecordFacility(props) {
    // SptRFBlockRender++
    // console.log('SptRFBlockRender', SptRFBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const kikan_kind_options = selectionOptions.shokai_keiro_options
    const kikan_kind_gyosei_options = selectionOptions.shokai_keiro_gyosei_options
    // const kikan_kind_defaultValue = kikan_kind_options.map((v) => false)
    const kikan_kind_watch = useWatch({ control, name: 'kikan_kind', defaultValue: '' })
    // const kikan_kind_values = kikan_kind_watch ? getValues('kikan_kind') : ''
    // const renkei_gyosei_watch = useWatch({ control, name: 'kikan_kind_gyosei', defaultValue: '' })
    // const renkei_gyosei_value = renkei_gyosei_watch ? getValues('kikan_kind_gyosei') : ''
    useEffect(() => {
        // console.log(kikan_kind_values)
        // const renkei_arg = {
        //     watchValue: kikan_kind_watch,
        //     targetName: 'kikan_kind_sonota',
        //     targetId: 'kikan_kind_sonota',
        //     checkValue: '999',
        //     // focusMethod: setFocus, //setFocusできないため省略
        //     setValueMethod: setValue,
        // }
        const renkei_office_arg = {
            watchValue: kikan_kind_watch,
            targetName: 'kikan_kind_gyosei',
            targetId: 'kikan_kind_gyosei',
            checkValue: '002',
            // focusMethod: setFocus,
            setValueMethod: setValue,
        }
        const renkei_office_comment_arg = {
            watchValue: kikan_kind_watch,
            targetName: 'kikan_kind_gyosei_free',
            targetId: 'kikan_kind_gyosei_free',
            checkValue: '002',
            // focusMethod: setFocus,
            setValueMethod: setValue,
        }
        // SwitchDisplay(renkei_arg)
        SwitchDisplay(renkei_office_arg)
        SwitchDisplay(renkei_office_comment_arg)
    }, [kikan_kind_watch, setValue])
    const contents = [
        <SelectBox
            label='機関の種類'
            name='kikan_kind'
            id='kikan_kind'
            register={register('kikan_kind', {})}
            // errorText={errors.kikan_kind?.message}
            options={kikan_kind_options}
            firstOption='--- 選択してください ---'
            placeholder=''
            // isRequired={true}
        />,
        <SelectBox
            label='機関の種類-行政-担当部署'
            name='kikan_kind_gyosei'
            id='kikan_kind_gyosei'
            register={register('kikan_kind_gyosei', {})}
            options={kikan_kind_gyosei_options}
            firstOption=''
            firstValue='000'
            // isRequired={true}
            // errorText={errors.kikan_kind_gyosei?.message}
        />,
        <InputText
            type='text'
            label='機関の種類-行政-担当部署-その他'
            name='kikan_kind_gyosei_free'
            id='kikan_kind_gyosei_free'
            register={register('kikan_kind_gyosei_free', {})}
            placeholder=''
            maxLength='100'
            // errorText={errors.kikan_kind_gyosei_free?.message}
        />,
        <InputText
            label='機関の種類-その他'
            name='kikan_kind_sonota'
            id='kikan_kind_sonota'
            register={register('kikan_kind_sonota', {})}
            type='text'
            placeholder=''
            maxLength='500'
            // errorText={errors.kikan_kind_sonota?.message}
        />,
        <InputText
            type='text'
            label='機関名'
            name='kikan_name'
            id='kikan_name'
            register={register('kikan_name', {})}
            placeholder=''
            maxLength='100'
            // errorText={errors.kikan_name?.message}
        />,
        <TextArea
            label='機関住所'
            name='kikan_adr'
            register={register('kikan_adr', {})}
            // errorText={errors.kikan_adr?.message}
            // placeholder=''
            // isRequired={true}
            expandSize={false}
            printDisplay={{ display: true, countByLine: 23, lines: 1 }}
        />,
        <InputText
            type='text'
            label='機関担当者'
            name='kikan_tantosha'
            id='kikan_tantosha'
            register={register('kikan_tantosha', {})}
            placeholder=''
            maxLength='80'
            // errorText={errors.kikan_tantosha?.message}
        />,
        <InputText
            type='text'
            label='機関電話'
            name='kikan_tel'
            id='kikan_tel'
            register={register('kikan_tel', {})}
            placeholder=''
            maxLength='12'
            // errorText={errors.kikan_tel?.message}
        />,
        <InputText
            type='text'
            label='機関FAX'
            name='kikan_fax'
            id='kikan_fax'
            register={register('kikan_fax', {})}
            placeholder=''
            maxLength='12'
            // errorText={errors.kikan_fax?.message}
        />,
    ]
    return (
        <>
            <FormBlock categoryName='今後支援の中心となる機関' formItems={contents} />
        </>
    )
}
