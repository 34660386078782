import { useFormContext } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { BadgeRequire } from '../atom'

export default function InputText({
    label,
    name,
    id,
    className,
    type,
    placeholder,
    prevText,
    nextText,
    subText,
    isRequired,
    errorText,
    register,
    control,
    isReadOnly,
    width,
    maxLength,
    inputMode = 'text',
    autoComplete,
}) {
    // const { required } = useFormContext()
    const errorFlg = !!errorText
    const subTextFlg = !!subText
    const prevTextFlg = !!prevText
    const nextTextFlg = !!nextText
    const widthFlg = !!width
    const normalFormStyle = `
        p-2 rounded-lg
        bg-gray-200 border border-gray-400
        `
    const isFocusFormStyle = isReadOnly
        ? `${normalFormStyle} focus:border-gray-400 focus:ring-transparent`
        : `${normalFormStyle} focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent`
    const errorFormStyle = `${isFocusFormStyle} ring-2 ring-red-500 forcus:none`
    const formStyle = !errorFlg ? isFocusFormStyle : errorFormStyle
    return (
        <div className='flex flex-col text-gray-800' id={`${id ? id : name}_parent`}>
            <div className='flex flex-row items-end mb-2'>
                <label htmlFor={id ? id : name} className='text-lg sm:text-2xl text-gray-600'>
                    {label}
                </label>
                {isRequired && <BadgeRequire />}
            </div>
            <div className='mb-4'>
                <div className='flex flex-row items-end'>
                    {prevTextFlg && <p className='pr-2 text-lg text-gray-700'>{prevText}</p>}
                    <input
                        id={id ? id : name}
                        name={name}
                        className={widthFlg ? `${width} ${className} ${formStyle}` : `w-full ${className} ${formStyle} `}
                        type={type}
                        placeholder={placeholder}
                        control={control}
                        readOnly={isReadOnly}
                        maxLength={maxLength ? maxLength : ''}
                        inputMode={inputMode}
                        autoComplete={autoComplete ? autoComplete : 'on'}
                        {...register}
                    />
                    {nextTextFlg && <p className='pl-2 text-lg text-gray-700'>{nextText}</p>}
                </div>
                {errorFlg && (
                    <span className='flex flex-row items-center justify-end my-2 text-right text-base font-bold text-red-400'>
                        <ExclamationCircleIcon className='w-5 h-5' />
                        <p>{errorText}</p>
                    </span>
                )}
                {subTextFlg && <p className='py-2 text-sm leading-snug text-gray-700 whitespace-pre-wrap'>{subText}</p>}
            </div>
        </div>
    )
}
