import React, { useState, useEffect, useRef, createContext, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import clonedeep from 'lodash.clonedeep'
import { firebase, db, func } from '../func/firebase'
import { useAuthContext } from '../func'
import { inputData, checkKeyDown, insertByDispId, replaceLineCode, checkBoxToData, dateFormToData, getCurrentDate, dataForCf, postToCf, feedBackDisplay } from '../func/CommonFunc'
import { useFbDataContext } from '../page'
import { SupportRecordTarget, SupportRecordEnding, SupportRecordCoop, SupportRecordFacility, SupportRecordTerm } from '../block'
import { Dialog, Modal, Button } from '../atom'
import { defaultValues, selectionOptions, fieldsByCategory } from '../json'

// let SptRecordCompoRender = 0
export default function SupportRecord() {
    // SptRecordCompoRender++
    // console.log('SptRecordCompoRender', SptRecordCompoRender)
    const { currentUser, userData } = useAuthContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const { fbData, setFbData, setShowDetail, showDetail, setShowEdit } = useFbDataContext()
    const schema = yup.object().shape({
        shien_months: yup.string().matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true }),
        shien_days: yup.string().matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true }),
        shien_visit_count: yup.string().matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true }),
        shien_doko_count: yup.string().matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true }),
        shien_total_time: yup.string().matches(/^([1-9]\d*|0)$/, { message: '数字のみ入力してください', excludeEmptyString: true }),
    })
    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: defaultValues.supportRecord,
    })
    const refDataLoad = useRef(true)
    const refShowDetail = useRef(null)
    useEffect(() => {
        // console.log('useEffect Reloaded in SupportRecord')
        // console.log('fbData in SupportRecord', fbData)
        // console.log('ref?', refDataLoad)
        const open = showDetail.index === 50
        const close = showDetail.index !== 50 && refShowDetail.current === 50
        if (refDataLoad.current && open) {
            // console.log('data input in SupportRecord')
            const dispData = { ...fbData['50'] }
            // console.log('disp50', dispData)
            inputData(dispData, methods.setValue)
            if (dispData['update_date'] !== '') {
                refDataLoad.current = false
            }
        } else if (close) {
            // console.log('close and setFbData in SupportRecord')
            // const cloneData = clonedeep(fbData)
            // console.log('cloneData in SupportRecord', cloneData)
            // const formData = methods.getValues()
            // console.log('formData in SupportRecord', formData)
            // cloneData['50'] = insertByDispId('50', formData, cloneData)
            // console.log('cloneData editted in SupportRecord', cloneData)
            // setFbData(cloneData)
            refDataLoad.current = true
        }
    }, [fbData, setFbData, methods, showDetail])
    useEffect(() => {
        // console.log('showDetail in  SupportRecord :', showDetail)
        refShowDetail.current = showDetail.index
    }, [showDetail])
    // const resetText = () => {
    //     methods.reset()
    // }
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backToShowDetail = useCallback(() => {
        const cancelEdit = () => {
            // console.log('yes')
            const pathName = history.location.pathname
            history.replace({ pathname: pathName })
            window.location.reload()
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        setShowDialog({
            ...showDialog,
            show: true,
            message: '相談の詳細・編集画面へ戻り、編集中の内容は消去します。\nよろしいですか？',
            doYes: cancelEdit,
            doNo: hideDialog,
            textYes: 'はい',
            textNo: 'いいえ',
        })
    }, [history, showDialog])
    //ユーザーデータが無い時の処理(送信ボタンを押せなくする、管理者権限閲覧時も同様)
    const [sendable, setSendable] = useState({ send: false, reasonText: '' })
    useEffect(() => {
        const isUserId = 'user_id' in userData
        const isShisetsuId = 'shisetsu_id' in userData
        // console.log({ isUserId, isShisetsuId })
        if (!isUserId) {
            console.error('userIdがありません')
            setSendable({ send: false, reasonText: 'ユーザーIDが無いため' })
        } else if (!isShisetsuId) {
            console.error('shisetsuIdがありません')
            setSendable({ send: false, reasonText: '施設IDが無いため' })
        } else if (isUserId && isShisetsuId) {
            // console.log('userData in EditConsult', userData)
            const uFacilityId = userData.shisetsu_id
            const uKengen = userData.kengen
            const isMatchFacilityId = fbData['10']['shisetsu_id'] === uFacilityId
            const isAdmin = ['1', '2'].includes(uKengen)
            // console.log({ uFacilityId, uKengen, isMatchFacilityId, isAdmin })
            if (isAdmin && !isMatchFacilityId) {
                setSendable({ send: false, reasonText: '確認用権限のため' })
            } else {
                setSendable({ send: true, reasonText: '' })
            }
        }
    }, [userData, fbData])
    const unSendable = useCallback(
        (text) => {
            const hideDialog = () => {
                setShowDialog({ ...showDialog, show: false })
            }
            const reload = () => {
                const pathName = history.location.pathname
                history.replace({ pathname: pathName })
                window.location.reload()
            }
            const message = `${text}現在送信できません\nページをリロードするか(入力内容は消去されます)\nウインドウを閉じてしばらくお待ち下さい\n待っても送信できない時はログインし直してみてください`
            setShowDialog({
                ...showDialog,
                show: true,
                message: message,
                doYes: reload,
                doNo: hideDialog,
                textYes: 'リロード',
                textNo: '閉じる',
            })
        },
        [showDialog, history]
    )
    const onSubmit = (data) => {
        const cloneData = clonedeep(data)
        // console.log('FormData', cloneData)
        const dataSptRecord = { ...fbData['50'] }
        Object.assign(dataSptRecord, cloneData)
        //マスタデータ(ユーザーID,施設ID,画面ID,処理区分)を追加
        dataSptRecord['user_id'] = userData.user_id
        dataSptRecord['shisetsu_id'] = userData.shisetsu_id
        dataSptRecord['disp_id'] = '50'
        //事例番号は消去
        // dataSptRecord['jirei_no'] = ''
        delete dataSptRecord['jirei_no']
        //初回送信フラグによって処理区分を分ける,存在しなければtrue
        if ('first_send' in dataSptRecord) {
            dataSptRecord['shori_div'] = dataSptRecord['first_send'] ? '0' : '1'
        } else {
            dataSptRecord['shori_div'] = '0'
            dataSptRecord['first_send'] = true
        }
        //改行コードを置換
        dataSptRecord['honnin_henka'] = replaceLineCode(dataSptRecord['honnin_henka'])
        dataSptRecord['shuketsu_riyu'] = replaceLineCode(dataSptRecord['shuketsu_riyu'])
        dataSptRecord['zan_kadai'] = replaceLineCode(dataSptRecord['zan_kadai'])
        dataSptRecord['kikan_adr'] = replaceLineCode(dataSptRecord['kikan_adr'])
        dataSptRecord['konkai_shien_jikohyoka'] = replaceLineCode(dataSptRecord['konkai_shien_jikohyoka'])
        //日付を追加,書き換え(obj=>str)
        //画面共通
        dataSptRecord['update_date'] = getCurrentDate()
        //画面50
        dataSptRecord['shien_start_date'] = dateFormToData(dataSptRecord['shien_start_date'])
        dataSptRecord['shien_end_date'] = dateFormToData(dataSptRecord['shien_end_date'])
        //CheckBoxデータを整形 元のプロパティを消去
        //画面50
        const enjogoItems = dataSptRecord['enjogo_seikatsu']
        dataSptRecord['enjogo_seikatsu_list'] = checkBoxToData(enjogoItems, 'enjogo_seikatsu')
        delete dataSptRecord['enjogo_seikatsu']
        const renkeiItems = dataSptRecord['renkei_kikan']
        dataSptRecord['renkei_kikan_list'] = checkBoxToData(renkeiItems, 'renkei_kikan')
        delete dataSptRecord['renkei_kikan']
        console.log('disp50 SendData', dataSptRecord)
        const response = postToCf(dataSptRecord)
        feedBackDisplay(response, setShowModal)
    }
    return (
        <div className='w-full flex flex-col items-center'>
            <Modal showModal={showModal} />
            <Dialog showDialog={showDialog} />
            <FormProvider {...methods}>
                <form
                    className='w-full mb-4 px-2 sm:max-w-2xl sm:mx-8 flex flex-col items-center'
                    name='SupportRecordForm'
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    <SupportRecordTarget />
                    <SupportRecordEnding />
                    <SupportRecordCoop />
                    <SupportRecordFacility />
                    <SupportRecordTerm />
                    <div className='pt-6 pb-4 max-w-xl ml-auto flex flex-col items-end sm:flex-row sm:justify-end flex-wrap gap-x-4 gap-y-3'>
                        {sendable.send ? <Button type='submit' value='送信' /> : <Button type='button' value='送信' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />}
                        <Button type='button' value='キャンセル' onClick={backToShowDetail} outline={true} />
                    </div>
                </form>
                {/* <DevTool control={methods.control} /> */}
            </FormProvider>
        </div>
    )
}
