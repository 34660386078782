import React, { useState, useEffect, useMemo, useCallback, createContext, useContext, useRef } from 'react'
import { useLocation, useHistory } from 'react-router'
import clonedeep from 'lodash.clonedeep'
import { ChevronDownIcon, ChevronUpIcon, PencilAltIcon, XCircleIcon } from '@heroicons/react/solid'
import { PlusCircleIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { firebase, db } from '../func/firebase'
import { useAuthContext } from '../func'
import { dataToCheckBox, dateDataToForm, dateFormToData, eraYear, textTruncate, listMatch } from '../func/CommonFunc'
import { EditConsultRecept, PersonalInfo, SupportContents, SupportPlan, SupportProcess, SupportRecord } from '../container'
import { Modal, TextArea, Button } from '../atom'
import { fieldsByDispId, selectionOptions } from '../json'

const FbDataContext = createContext()
export function useFbDataContext() {
    return useContext(FbDataContext)
}

// let editConsultPageRender = 0
export function EditConsult(props) {
    // editConsultPageRender++
    // console.log('editConsultPageRender', editConsultPageRender)
    const fieldsJson = useMemo(() => fieldsByDispId, [])
    const [fbData, setFbData] = useState(fieldsJson)
    const [showDetail, setShowDetail] = useState({ index: '' })
    const [showEdit, setShowEdit] = useState({ index: '' })
    const [guiData, setGuiData] = useState({})
    const [sptProcessNo, setSptProcessNo] = useState('')
    const style = {
        editList: 'cursor-pointer w-full flex flex-col',
        editListTitle: 'flex flex-row px-2 pt-2 pb-1 sm:pt-4 sm:pb-2 bg-gray-300 border-b border-gray-400',
        editListItem: 'border-b border-gray-400',
        chevron: 'w-8 h-8 mr-2',
        targetDetailBlock: 'grid grid-cols-12 items-center mx-1 sm:mx-3 px-1 py-2 sm:py-4 border-b border-gray-300 text-sm sm:text-xl text-gray-600',
        targetDetailItemL: 'col-span-3 sm:col-span-3 pr-2 text-right',
        targetDetailItemR: 'col-span-9 sm:col-span-9 pl-2',
        targetDetailItemRMap: 'col-span-9 sm:col-span-9 pl-2 flex flex-row',
        postCode: '',
        adr: 'pr-6 text-justify',
        mapLink: 'w-20 flex flex-col justify-end items-center ml-auto text-teal-600 underline',
        infoDetailItemBlock: 'grid grid-cols-12 items-center bg-gray-50 border-b border-gray-300 text-sm sm:text-lg text-gray-600',
        infoDetailItemL: 'col-span-4 sm:col-span-3 text-right my-2 pr-2 whitespace-pre-wrap',
        infoDetailItemR: 'col-span-8 sm:col-span-9 my-2 pl-4 pr-2 line-clamp-2 sm:line-clamp-3',
        infoDetailProcessTitleBlock: 'grid grid-cols-16 bg-gray-200 border-b-2 border-gray-300 text-gray-500 font-medium text-sm sm:text-lg',
        infoDetailProcessBlock: 'grid grid-cols-16 bg-gray-50 border-b border-gray-300 text-sm sm:text-lg',
        infoDetailProcess_1: 'col-span-4 sm:col-span-3 py-2 pl-4 whitespace-pre-wrap',
        infoDetailProcess_2: 'col-span-8 sm:col-span-9 py-2 pl-4',
        infoDetailProcess_3: 'col-span-4 sm:col-span-4 flex flex-col justify-center items-end py-2 pr-4',
        infoEditButton: 'w-full px-4 py-3 flex flex-row justify-end items-end bg-gray-50 text-teal-600 text-base sm:text-lg',
    }
    const codeAndDisplayForMatch = useCallback((obj) => {
        const object = {}
        obj.map((item) => (object[item.code] = item['display']))
        return object
    }, [])
    const shikuNameMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['shiku_name_options']), [codeAndDisplayForMatch])
    const shubetsuMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['sodansha_shubetsu_options']), [codeAndDisplayForMatch])
    const kongoMikomiMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['kongo_mikomi_options']), [codeAndDisplayForMatch])
    const keiroMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['shokai_keiro_options']), [codeAndDisplayForMatch])
    const sumaiMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['sumai_options']), [codeAndDisplayForMatch])
    const shakuyaMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['sumai_shakuya_kbn_options']), [codeAndDisplayForMatch])
    const loanMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['sumai_mochiie_loan_options']), [codeAndDisplayForMatch])
    const keywordMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['sodansha_keyword_options']), [codeAndDisplayForMatch])
    const gyoseiMatch = useMemo(() => codeAndDisplayForMatch(selectionOptions['shokai_keiro_gyosei_options']), [codeAndDisplayForMatch])

    const id = props.match.params.id
    // console.log('props', props)
    const history = useHistory()
    // console.log('history in EditConsult', history)
    const { currentUser, userData } = useAuthContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    useEffect(() => {
        const getData = async () => {
            try {
                //AuthProviderからのユーザー情報をチェック(リロード時は空)
                const isUdataEmpty = () => {
                    const isNotEmpty = !!Object.keys(userData).length
                    // console.log({ isNotEmpty })
                    return new Promise((resolve, reject) => {
                        if (isNotEmpty) resolve(userData)
                        else reject('userDataが読み込めていません')
                    })
                }
                const uData = await isUdataEmpty()
                const uFacilityId = uData.shisetsu_id
                const uKengen = uData.kengen
                const isAdmin = ['1', '2'].includes(uKengen)
                //DB取得
                const refDoc = db.collection('taishosha_id').doc(id)
                const refSubCol = refDoc.collection('disp_id')
                const snapSubCol = await refSubCol.get()
                const docsObj = {}
                snapSubCol.forEach((doc) => {
                    // console.log(doc.id, '=>', doc.data())
                    docsObj[doc.id] = doc.data()
                })
                // console.log('get data reloaded')
                const dFacilityId = docsObj['10']['shisetsu_id']
                // console.log(dFacilityId, facilityId)
                if (dFacilityId !== uFacilityId && !isAdmin) {
                    throw new Error('施設IDが一致しないためドキュメントを参照できません')
                }
                //DBデータをフォーム入力用に加工
                const editFbData = () => {
                    //相談者種別データリストをcheckbox用データに整形
                    const refShubetsu = selectionOptions['sodansha_shubetsu_options']
                    const dataShubetsu = docsObj['102']['sodansha_shubetsu_list']
                    // console.log('sodansha_shubetsu_list', docsObj['102']['sodansha_shubetsu_list'])
                    docsObj['102']['sodansha_shubetsu'] = dataToCheckBox(refShubetsu, dataShubetsu)
                    delete docsObj['102']['sodansha_shubetsu_list']
                    const refKeyword = selectionOptions['sodansha_keyword_options']
                    const dataKeyword = docsObj['12']['sodansha_keyword_list']
                    docsObj['12']['sodansha_keyword'] = dataToCheckBox(refKeyword, dataKeyword)
                    delete docsObj['12']['sodansha_keyword_list']
                    const refShushi = selectionOptions['shushi_jokyo_options']
                    const dataShushi = docsObj['12']['shushi_jokyo_list']
                    docsObj['12']['shushi_jokyo'] = dataToCheckBox(refShushi, dataShushi)
                    delete docsObj['12']['shushi_jokyo_list']
                    if (Array.isArray(docsObj['50']['enjogo_seikatsu_list'])) {
                        const refEnjogo = selectionOptions['enjogo_seikatsu_options']
                        const dataEnjogo = docsObj['50']['enjogo_seikatsu_list'].map((v) => (v['enjogo_seikatsu'] ? v['enjogo_seikatsu'] : v))
                        docsObj['50']['enjogo_seikatsu'] = dataToCheckBox(refEnjogo, dataEnjogo)
                    }
                    delete docsObj['50']['enjogo_seikatsu_list']
                    if (Array.isArray(docsObj['50']['renkei_kikan_list'])) {
                        const refRenkei = selectionOptions['shokai_keiro_options']
                        const dataRenkei = docsObj['50']['renkei_kikan_list'].map((v) => (v['renkei_kikan'] ? v['renkei_kikan'] : v))
                        docsObj['50']['renkei_kikan'] = dataToCheckBox(refRenkei, dataRenkei)
                    }
                    delete docsObj['50']['renkei_kikan_list']
                    //画面11-家族データをform用に整形
                    const familyDataList = docsObj['11']['kazoku_list']
                    const familyDataObj = {}
                    familyDataList.forEach((value, index) => {
                        familyDataObj[`kazoku_no_${index + 1}`] = value['kazoku_no']
                        familyDataObj[`kazoku_name_${index + 1}`] = value['kazoku_name']
                        familyDataObj[`kazoku_zokugara_${index + 1}`] = value['kazoku_zokugara']
                        familyDataObj[`kazoku_nenrei_${index + 1}`] = value['kazoku_nenrei']
                        familyDataObj[`shuro_shugaku_jokyo_${index + 1}`] = value['shuro_shugaku_jokyo']
                        familyDataObj[`kenko_jotai_${index + 1}`] = value['kenko_jotai']
                        familyDataObj[`biko_${index + 1}`] = value['biko']
                    })
                    Object.assign(docsObj['11'], familyDataObj)
                    //画面12- formname重複避け用にデータを複製(「相談者種別」「相談者種別-その他」「生保区分」)
                    docsObj['102']['sodansha_shubetsu_sptc'] = docsObj['102']['sodansha_shubetsu']
                    docsObj['102']['sodansha_shubetsu_sonota_sptc'] = docsObj['102']['sodansha_shubetsu_sonota']
                    docsObj['102']['seiho_kbn_sptc'] = docsObj['102']['seiho_kbn']
                    //画面12-「要介護度」「障害程度区分」が""なら"001"を代入
                    if (docsObj['12']['yokaigodo'] === '') docsObj['12']['yokaigodo'] = '001'
                    if (docsObj['12']['shogaiteido_kubun'] === '') docsObj['12']['shogaiteido_kubun'] = '001'
                    // console.log('familyDataList', familyDataList)
                    delete docsObj['11']['kazoku_list']
                    //日付文字列をObjectに整形
                    docsObj['10']['uketsuke_date'] = dateDataToForm(docsObj['10']['uketsuke_date'])
                    docsObj['101']['birthday'] = dateDataToForm(docsObj['101']['birthday'])
                    docsObj['11']['shokai_date'] = dateDataToForm(docsObj['11']['shokai_date'])
                    docsObj['11']['shokaimendan_date'] = dateDataToForm(docsObj['11']['shokaimendan_date'])
                    docsObj['50']['shien_start_date'] = dateDataToForm(docsObj['50']['shien_start_date'])
                    docsObj['50']['shien_end_date'] = dateDataToForm(docsObj['50']['shien_end_date'])

                    //画面40を整形
                    const sptProcess = docsObj['40']['enjokeika_list']
                    // const nums = docsObj['40']['enjokeika_list'].map((v) => v['enjo_no'])
                    // const isEmpty = nums.includes('')
                    const isEmpty = sptProcess.length === 1 && sptProcess[0]['enjo_no'] === ''
                    // console.log({ sptProcess, isEmpty })
                    const supportList = []
                    if (!isEmpty && Array.isArray(docsObj['40']['enjokeika_list'])) {
                        docsObj['40']['enjokeika_list'].forEach((value) => {
                            if (!!value['enjo_no']) {
                                //日付をフォーム用に整形
                                value['enjo_date'] = dateDataToForm(value['enjo_date'])
                                supportList.push(value)
                            }
                        })
                        //日付順にソート(古い順)
                        supportList.sort((a, b) => a['enjo_date'] - b['enjo_date'])
                        // console.log('enjokeika_list', supportList)
                    } else if (isEmpty) {
                        const emptyObj = {
                            enjo_no: '',
                            enjo_date: '',
                            enjo_keika_naiyo: '',
                        }
                        supportList.push(emptyObj)
                    } else {
                    }
                    docsObj['40']['enjokeika_list'] = supportList
                }
                editFbData()
                // console.log('fbData in Parent:', docsObj)
                setFbData(docsObj)
                // console.log('fbdata reset')
            } catch (error) {
                // console.error('editConsultPage/getDbError:', error)
                // console.log(error.name, error.message)
                if (error.message === '施設IDが一致しないためドキュメントを参照できません') {
                    console.error('editConsultPage/getDbError:', error)
                    const closeModal = () => {
                        setShowModal({ show: false })
                        history.goBack()
                    }
                    setShowModal({
                        show: true,
                        text: '対象者情報を読み込めません',
                        errorText: error.message ? error.message : '',
                        state: 'rejected',
                        doOk: closeModal,
                    })
                } else if (error.name === 'TypeError' && error.message.includes('shisetsu_id')) {
                    console.error('editConsultPage/getDbError:', error)
                    const closeModal = () => {
                        setShowModal({ show: false })
                        history.goBack()
                    }
                    setShowModal({
                        show: true,
                        text: '対象者情報がDBに存在しません',
                        errorText: error.message ? error.message : '',
                        state: 'rejected',
                        doOk: closeModal,
                    })
                } else {
                    console.error('editConsultPage/getDbError:', error)
                }
            }
        }
        getData()
        // console.log(id)
    }, [id, history, userData])
    useEffect(() => {
        if (Object.keys(fbData).length > 0) {
            const cloneFbData = clonedeep(fbData)
            cloneFbData['10'] = { ...cloneFbData['10'], ...cloneFbData['101'], ...cloneFbData['102'] }
            cloneFbData['11'] = { ...cloneFbData['11'], ...cloneFbData['101'] }
            cloneFbData['12'] = { ...cloneFbData['12'], ...cloneFbData['102'] }
            delete cloneFbData['101']
            delete cloneFbData['102']
            cloneFbData['10']['sodansha_shubetsu'] = listMatch(cloneFbData['10']['sodansha_shubetsu'], shubetsuMatch)
            cloneFbData['10']['kongo_mikomi'] = kongoMikomiMatch[cloneFbData['10']['kongo_mikomi']]
            if (cloneFbData['11']['sumai'] === '001') {
                const shakuya = cloneFbData['11']['sumai_shakuya_kbn'] ? shakuyaMatch[cloneFbData['11']['sumai_shakuya_kbn']] : ''
                cloneFbData['11']['sumai'] = `${sumaiMatch[cloneFbData['11']['sumai']]}(${shakuya})`
            } else if (cloneFbData['11']['sumai'] === '002') {
                const loan = cloneFbData['11']['sumai_mochiie_loan'] ? loanMatch[cloneFbData['11']['sumai_mochiie_loan']] : ''
                cloneFbData['11']['sumai'] = `${sumaiMatch[cloneFbData['11']['sumai']]}(${loan})`
            } else if (cloneFbData['11']['sumai'] === '999') {
                const sonota = cloneFbData['11']['sumai_sonota'] ? cloneFbData['11']['sumai_sonota'] : ''
                cloneFbData['11']['sumai'] = `${sumaiMatch[cloneFbData['11']['sumai']]}(${sonota})`
            } else {
                cloneFbData['11']['sumai'] = sumaiMatch[cloneFbData['11']['sumai']]
            }

            const gyoseiStr = cloneFbData['11']['shokai_keiro_gyosei'] ? ` -${gyoseiMatch[cloneFbData['11']['shokai_keiro_gyosei']]}` : ''
            const gyoseiSonota = cloneFbData['11']['shokai_keiro_gyosei_free'] ? `(${cloneFbData['11']['shokai_keiro_gyosei_free']})` : ''
            cloneFbData['11']['shokai_keiro'] = `${keiroMatch[cloneFbData['11']['shokai_keiro']]} ${gyoseiStr} ${gyoseiSonota}`
            cloneFbData['12']['sodansha_keyword'] = listMatch(cloneFbData['12']['sodansha_keyword'], keywordMatch)
            const startDateStr = dateFormToData(cloneFbData['50']['shien_start_date'])
            const endDateStr = dateFormToData(cloneFbData['50']['shien_end_date'])
            const startDateWareki = startDateStr ? `${eraYear(startDateStr.slice(0, 4))}(${startDateStr.slice(0, 4)})年${startDateStr.slice(4, 6)}月${startDateStr.slice(6)}日` : ''
            const endDateWareki = endDateStr ? `${eraYear(endDateStr.slice(0, 4))}(${endDateStr.slice(0, 4)})年${endDateStr.slice(4, 6)}月${endDateStr.slice(6)}日` : ''
            // console.log({ startDateStr, endDateStr, startDateWareki, endDateWareki })
            cloneFbData['50']['shien_term'] = `${startDateWareki}～\n${endDateWareki}`
            // console.log(cloneFbData['40']['enjokeika_list'])
            const supportList = []
            cloneFbData['40']['enjokeika_list'].forEach((v, i) => {
                const supportItem = cloneFbData['40']['enjokeika_list'][i]
                const isDisplay = supportItem['enjo_display']
                const flgDisplay = isDisplay ? isDisplay : false
                //enjo_display(削除フラグ)がfalse,undefinedの時
                if (flgDisplay) {
                    const dateStr = dateFormToData(supportItem['enjo_date'])
                    const warekiYear = eraYear(Number(dateStr.slice(0, 4)))
                    const warekiStr = `${warekiYear}年\n(${dateStr.slice(0, 4)}年)\n${dateStr.slice(4, 6)}月${dateStr.slice(6)}日`
                    supportItem['enjo_date'] = dateStr ? warekiStr : '-'
                    const contentsStr = supportItem['enjo_keika_naiyo']
                    supportItem['enjo_keika_naiyo'] = textTruncate(contentsStr, 30)
                    supportList.push(supportItem)
                }
            })
            cloneFbData['40']['enjokeika_list'] = supportList
            setGuiData(cloneFbData)
            // console.log('guiData', cloneFbData)
        }
    }, [fbData, keiroMatch, keywordMatch, kongoMikomiMatch, shubetsuMatch, sumaiMatch, gyoseiMatch, loanMatch, shakuyaMatch])

    const value = { fbData, setFbData, setShowDetail, showDetail, setShowEdit }
    const detailDisplay = useCallback(
        (index) => {
            if (index !== showEdit.index) {
                if (index !== showDetail.index) {
                    setShowDetail({ ...showDetail, index: index })
                } else {
                    setShowDetail({ ...showDetail, index: '' })
                }
            }
        },
        [showDetail, showEdit]
    )
    const editDisplay = useCallback(
        (index) => {
            if (index !== setShowEdit.index) {
                setShowEdit({ ...showEdit, index: index })
                const pathName = history.location.pathname
                const searchParam = `?disp=${index}`
                history.replace({ pathname: pathName, search: searchParam })
            } else {
                setShowEdit({ ...showEdit, index: '' })
            }
        },
        [showEdit, history]
    )
    useEffect(() => {
        // console.log('showEdit', showEdit)
        if (showEdit.index === '') {
            const pathName = history.location.pathname
            // console.log({ history, pathName })
            history.replace({ pathname: pathName })
        }
    }, [showEdit, history])
    // const scrollToDispTop = useCallback(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth',
    //     })
    //     console.log('scrolled')
    // }, [])
    // const location = useLocation()
    // const history = useHistory()
    // const hoge = () => {
    //     console.log('location in EditConsult: ', location)
    //     history.push('#testing')
    // }
    //コード番号で記録されている項目をGUI表示名に変換する処理
    const notReqInput = useCallback((data) => {
        return data ? data : '記載なし'
    }, [])
    const splitDateStr = useCallback((date) => {
        const dateStr = typeof date === 'object' ? dateFormToData(date) : ''
        // const date = new Date(dateStr.slice(0, 4), dateStr.slice(4, 6), dateStr.slice(6))
        const eraHandler = (yearNow) => {
            const generate = (era, startYear) => {
                let yearDsp = yearNow - startYear + 1
                if (yearDsp === 1) {
                    yearDsp = '元'
                } else {
                    yearDsp = ('00' + yearDsp).slice(-2)
                }
                return `${era}${yearDsp}`
            }
            if (yearNow >= 2019) {
                return generate('令和', 2019)
            }
            if (yearNow >= 1989) {
                return generate('平成', 1989)
            }
            if (yearNow >= 1926) {
                return generate('昭和', 1926)
            }
            if (yearNow >= 1912) {
                return generate('大正', 1912)
            }
            if (yearNow >= 1868) {
                return generate('明治', 1868)
            }
        }
        const warekiYear = eraHandler(Number(dateStr.slice(0, 4)))
        // const options = { era: 'long' }
        // const warekiYear = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', options).format(date)
        if (dateStr) {
            return {
                kanji: `${warekiYear}(${dateStr.slice(0, 4)})年${dateStr.slice(4, 6)}月${dateStr.slice(6)}日`,
                slash: `${dateStr.slice(0, 4)}/${dateStr.slice(4, 6)}/${dateStr.slice(6)}`,
            }
        } else {
            return { kanji: '―', slash: '―' }
        }
    }, [])
    const calcAge = useCallback((date) => {
        const todayDate = new Date()
        const birthDayStr = typeof date === 'object' ? dateFormToData(date) : ''
        const birthYear = Number(birthDayStr.slice(0, 4))
        const birthMonth = birthDayStr.slice(4, 6)
        const birthDay = birthDayStr.slice(6)
        let age = todayDate.getFullYear() - birthYear
        const thisYearBirthDay = new Date(todayDate.getFullYear(), birthMonth, birthDay)
        if (todayDate < thisYearBirthDay) {
            age--
        }
        return age
    }, [])
    const mapsSelector = async (address) => {
        const ua = window.navigator.userAgent.toLowerCase()
        if (ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1 || ua.indexOf('ipod') !== -1) {
            window.open('maps://maps.google.com/maps?q=' + address)
        } else {
            window.open('https://maps.google.com/maps?q=' + address)
        }
    }
    const setSupportProcessNo = useCallback((num) => {
        setSptProcessNo(num)
    }, [])
    return (
        <>
            <Modal showModal={showModal} />
            <FbDataContext.Provider value={value}>
                <div className='w-full h-full flex flex-col items-center'>
                    {/* <Button onClick={hoge} value='Loc' outline={true} /> */}
                    <div className='w-full sm:max-w-2xl pt-4 px-2 sm:mx-8'>
                        <h2 className=' pl-1 text-xl sm:text-3xl text-gray-600 font-medium border-l-4 sm:border-l-8 border-rose-400'>相談の詳細・編集</h2>
                        <ul className='w-full my-4 bg-gray-100 rounded-lg sm:rounded-3xl border border-gray-400'>
                            <li className={style.targetDetailBlock}>
                                <p className={style.targetDetailItemL}>対象者</p>
                                <p className={style.targetDetailItemR}>{`${fbData['101']['taishosha_name']} (${fbData['101']['taishosha_name_kana']})`}</p>
                            </li>
                            <li className={style.targetDetailBlock}>
                                <p className={style.targetDetailItemL}>住所</p>
                                <div className={style.targetDetailItemRMap}>
                                    <div>
                                        <p className={style.postCode}>{`〒${notReqInput(fbData['101']['post_no'])}`}</p>
                                        <p className={style.adr}>{`大阪府${shikuNameMatch[fbData['101']['shiku_name']]}${fbData['101']['adr_dtl']}`}</p>
                                    </div>
                                    <div className={style.mapLink} onClick={() => mapsSelector(`大阪府${shikuNameMatch[fbData['101']['shiku_name']]}${fbData['101']['adr_dtl']}`)}>
                                        <LocationMarkerIcon className='w-6 h-6 sm:w-8 sm:h-8 text-rose-400' />
                                        <p className='sm:text-2xl'>地図</p>
                                    </div>
                                </div>
                            </li>
                            <li className={style.targetDetailBlock}>
                                <p className={style.targetDetailItemL}>TEL</p>
                                <p className={style.targetDetailItemR}>
                                    <a className={!!fbData['101']['tel'] ? 'text-teal-600 underline' : 'text-gray-400'} href={`tel:${notReqInput(fbData['101']['tel'])}`}>
                                        {notReqInput(fbData['101']['tel'])}
                                    </a>
                                    {`　`}
                                    <a className={!!fbData['101']['tel_keitai'] ? 'text-teal-600 underline' : 'text-gray-400'} href={`tel:${notReqInput(fbData['101']['tel_keitai'])}`}>
                                        {notReqInput(fbData['101']['tel_keitai'])}
                                    </a>
                                </p>
                            </li>
                            <li className={style.targetDetailBlock}>
                                <p className={style.targetDetailItemL}>生年月日</p>
                                <p className={style.targetDetailItemR}>{`${splitDateStr(fbData['101']['birthday']).kanji}  ${fbData['101']['nenrei']}歳`}</p>
                            </li>
                        </ul>
                        <ul className='w-full flex flex-col mt-6 text-xl sm:text-2xl text-gray-700 text-left'>
                            <li className={showEdit.index === '' ? style.editList : showEdit.index === 10 ? style.editList : 'hidden'}>
                                <div className={style.editListTitle} onClick={() => detailDisplay(10)}>
                                    {showEdit.index === 10 ? '' : showDetail.index === 10 ? <ChevronUpIcon className={style.chevron} /> : <ChevronDownIcon className={style.chevron} />}
                                    <p>相談受付</p>
                                </div>
                                <div className={showDetail.index === 10 ? style.editListItem : 'hidden'}>
                                    <ul className={showEdit.index === 10 ? 'hidden' : ''}>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>紹介者</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['10']['shokaisha']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>相談者種別</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['10']['sodansha_shubetsu']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>相談内容</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['10']['shokai_naiyo']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>初回の対応</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['10']['shokaitaio']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>今後の見込み</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['10']['kongo_mikomi']}</p>
                                        </li>
                                        <li className={style.infoEditButton} onClick={() => editDisplay(10)}>
                                            <PencilAltIcon className='w-8 h-8' />
                                            <p>編集する</p>
                                        </li>
                                    </ul>
                                    {showEdit.index === 10 ? <EditConsultRecept /> : ''}
                                </div>
                            </li>
                            <li className={showEdit.index === '' ? style.editList : showEdit.index === 11 ? style.editList : 'hidden'}>
                                <div className={style.editListTitle} onClick={() => detailDisplay(11)}>
                                    {showEdit.index === 11 ? '' : showDetail.index === 11 ? <ChevronUpIcon className={style.chevron} /> : <ChevronDownIcon className={style.chevron} />}
                                    <p>相談受付（個人情報）</p>
                                </div>
                                <div className={showDetail.index === 11 ? style.editListItem : 'hidden'}>
                                    <ul className={showEdit.index === 11 ? 'hidden' : ''}>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>住まい</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['11']['sumai']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>生活状況</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['11']['seikatsu_jokyo']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>紹介経路</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['11']['shokai_keiro']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>当面の対応</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['11']['tomentaio']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>支援協力者</p>
                                            <p className={style.infoDetailItemR}>
                                                {Object.keys(guiData).length > 0 && `${guiData['11']['kyoryokusha_name']}(${guiData['11']['kyoryokusha_name_kana']})`}
                                            </p>
                                        </li>
                                        <li className={style.infoEditButton} onClick={() => editDisplay(11)}>
                                            <PencilAltIcon className='w-8 h-8' />
                                            <p>編集する</p>
                                        </li>
                                    </ul>
                                    {showEdit.index === 11 ? <PersonalInfo /> : ''}
                                </div>
                            </li>
                            <li className={showEdit.index === '' ? style.editList : showEdit.index === 12 ? style.editList : 'hidden'}>
                                <div className={style.editListTitle} onClick={() => detailDisplay(12)}>
                                    {showEdit.index === 12 ? '' : showDetail.index === 12 ? <ChevronUpIcon className={style.chevron} /> : <ChevronDownIcon className={style.chevron} />}
                                    <p>相談受付（相談内容）</p>
                                </div>
                                <div className={showDetail.index === 12 ? style.editListItem : 'hidden'}>
                                    <ul className={showEdit.index === 12 ? 'hidden' : ''}>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>相談者種別</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['10']['sodansha_shubetsu']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>相談キーワード</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['12']['sodansha_keyword']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>仕事</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['12']['job']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>月の収入/支出</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['12']['shunyu_shishutsu_free']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>心身の状況</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['12']['shinshin_jokyo']}</p>
                                        </li>
                                        <li className={style.infoEditButton} onClick={() => editDisplay(12)}>
                                            <PencilAltIcon className='w-8 h-8' />
                                            <p>編集する</p>
                                        </li>
                                    </ul>
                                    {showEdit.index === 12 ? <SupportContents /> : ''}
                                </div>
                            </li>
                            <li className={showEdit.index === '' ? style.editList : showEdit.index === 20 ? style.editList : 'hidden'}>
                                <div className={style.editListTitle} onClick={() => detailDisplay(20)}>
                                    {showEdit.index === 20 ? '' : showDetail.index === 20 ? <ChevronUpIcon className={style.chevron} /> : <ChevronDownIcon className={style.chevron} />}
                                    <p>支援計画</p>
                                </div>
                                <div className={showDetail.index === 20 ? style.editListItem : 'hidden'}>
                                    <ul className={showEdit.index === 20 ? 'hidden' : ''}>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>生活環境支援</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['20']['seikatsukankyo_shien_hitsuyo']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>治療支援</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['20']['chiryo_shien_hitsuyo']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>経済的援助</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['20']['keizai_enjo_hitsuyo']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>利用を検討する福祉制度</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['20']['kento_fukushi_seido']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>社会貢献事業としての支援目標</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['20']['shakaikoken_shien_mokuhyo']}</p>
                                        </li>
                                        <li className={style.infoEditButton} onClick={() => editDisplay(20)}>
                                            <PencilAltIcon className='w-8 h-8' />
                                            <p>編集する</p>
                                        </li>
                                    </ul>
                                    {showEdit.index === 20 ? <SupportPlan /> : ''}
                                </div>
                            </li>
                            <li className={showEdit.index === '' ? style.editList : showEdit.index === 40 ? style.editList : 'hidden'}>
                                <div className={style.editListTitle} onClick={() => detailDisplay(40)}>
                                    {showEdit.index === 40 ? '' : showDetail.index === 40 ? <ChevronUpIcon className={style.chevron} /> : <ChevronDownIcon className={style.chevron} />}
                                    <p>支援経過</p>
                                </div>
                                <div className={showDetail.index === 40 ? style.editListItem : 'hidden'}>
                                    <ul className={showEdit.index === 40 ? 'hidden' : ''}>
                                        <li className={style.infoDetailProcessTitleBlock}>
                                            <p className={style.infoDetailProcess_1}>日付</p>
                                            <p className={style.infoDetailProcess_2}>支援経過内容</p>
                                            <p className={style.infoDetailProcess_3}>編集する</p>
                                        </li>
                                        {Object.keys(guiData).length > 0 &&
                                            guiData['40']['enjokeika_list'].map((v) => (
                                                <li key={`${v['enjo_no']}`} className={style.infoDetailProcessBlock}>
                                                    <p className={style.infoDetailProcess_1}>{v['enjo_date']}</p>
                                                    <p className={style.infoDetailProcess_2}>{v['enjo_keika_naiyo']}</p>
                                                    <div
                                                        className={style.infoDetailProcess_3}
                                                        onClick={() => {
                                                            editDisplay(40)
                                                            setSupportProcessNo(v['enjo_no'])
                                                        }}
                                                    >
                                                        <PencilAltIcon className='w-6 h-6 text-teal-600' />
                                                        <p className='pt-1 text-teal-600'>編集</p>
                                                    </div>
                                                </li>
                                            ))}
                                        <li
                                            className={style.infoEditButton}
                                            onClick={() => {
                                                editDisplay(40)
                                                setSupportProcessNo('add')
                                            }}
                                        >
                                            <PlusCircleIcon className='w-8 h-8' />
                                            <p>追加する</p>
                                        </li>
                                    </ul>
                                    {showEdit.index === 40 ? <SupportProcess number={sptProcessNo} /> : ''}
                                </div>
                            </li>
                            <li className={showEdit.index === '' ? style.editList : showEdit.index === 50 ? style.editList : 'hidden'}>
                                <div className={style.editListTitle} onClick={() => detailDisplay(50)}>
                                    {showEdit.index === 50 ? '' : showDetail.index === 50 ? <ChevronUpIcon className={style.chevron} /> : <ChevronDownIcon className={style.chevron} />}
                                    <p>支援終結記録</p>
                                </div>
                                <div className={showDetail.index === 50 ? style.editListItem : 'hidden'}>
                                    <ul className={showEdit.index === 50 ? 'hidden' : ''}>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>支援前後の本人の変化</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['50']['honnin_henka']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>支援終結理由</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['50']['shuketsu_riyu']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>残された課題</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['50']['zan_kadai']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>支援期間</p>
                                            <p className={`whitespace-pre-wrap ${style.infoDetailItemR}`}>{Object.keys(guiData).length > 0 && guiData['50']['shien_term']}</p>
                                        </li>
                                        <li className={style.infoDetailItemBlock}>
                                            <p className={style.infoDetailItemL}>支援の自己評価</p>
                                            <p className={style.infoDetailItemR}>{Object.keys(guiData).length > 0 && guiData['50']['konkai_shien_jikohyoka']}</p>
                                        </li>
                                        <li className={style.infoEditButton} onClick={() => editDisplay(50)}>
                                            <PencilAltIcon className='w-8 h-8' />
                                            <p>編集する</p>
                                        </li>
                                    </ul>
                                    {showEdit.index === 50 ? <SupportRecord /> : ''}
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <div className={showEdit.index !== '' ? 'w-full px-4 pb-8 flex justify-end' : 'hidden'}>
                        <Button onClick={backToShowDetail} value='キャンセル' outline={true} />
                    </div> */}
                </div>
            </FbDataContext.Provider>
        </>
    )
}
