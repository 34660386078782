import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SwitchDisplay } from '../func/CommonFunc'
import { PersonalInfoFamily } from '../container'
import { InputDate, SelectBox, InputText, RadioButton, TextArea, FormBlock } from '../atom'
import { selectionOptions } from '../json'

// let PISBlockrender = 0
export default function PersonalInfoStatus() {
    // PISBlockrender++
    // console.log('PISBlockrender', PISBlockrender)
    const {
        register,
        formState: { errors },
        control,
        getValues,
        setValue,
    } = useFormContext()
    const gender_options = selectionOptions.gender_options
    const setai_jokyo_options = selectionOptions['setai_jokyo_options']
    const sumai_options = selectionOptions['sumai_options']
    const sumai_shakuya_kbn_options = selectionOptions['sumai_shakuya_kbn_options']
    const sumai_mochiie_loan_options = selectionOptions['sumai_mochiie_loan_options']

    const contents = [
        <SelectBox
            label='世帯状況'
            name='setai_jokyo'
            register={register('setai_jokyo', {})}
            options={setai_jokyo_options}
            firstOption='--- 選択してください ---'
            placeholder=''
            // errorText={errors.setai_jokyo?.message}
        />,
        <InputText
            type='text'
            label='世帯状況（その他）'
            name='setai_jokyo_sonota'
            id='setai_jokyo_sonota_personal'
            register={register('setai_jokyo_sonota', {})}
            placeholder=''
            maxLength='13'
            // errorText={errors.setai_jokyo_sonota?.message}
        />,
        <PersonalInfoFamily />,
        <RadioButton
            label='住まい'
            name='sumai'
            id='sumai_personal'
            options={sumai_options}
            register={register('sumai', {})}
            // errorText={errors.sumai?.message}
        />,
        <RadioButton
            label='借家'
            name='sumai_shakuya_kbn'
            id='sumai_shakuya_kbn_personal'
            options={sumai_shakuya_kbn_options}
            register={register('sumai_shakuya_kbn', {})}
            // errorText={errors.sumai_shakuya_kbn?.message}
        />,
        <RadioButton
            label='持家'
            name='sumai_mochiie_loan'
            id='sumai_mochiie_loan_personal'
            options={sumai_mochiie_loan_options}
            register={register('sumai_mochiie_loan', {})}
            // errorText={errors.sumai_mochiie_loan?.message}
        />,
        <InputText type='text' label='住まい（その他）' name='sumai_sonota' id='sumai_sonota_personal' register={register('sumai_sonota', {})} placeholder='' maxLength='500' />,
        <InputText type='text' label='家賃月額' name='yachin' register={register('yachin', {})} nextText='円' placeholder='' inputMode='numeric' errorText={errors.yachin?.message} />,
        <TextArea
            label='生活状況'
            name='seikatsu_jokyo'
            register={register('seikatsu_jokyo', {})}
            // errorText={errors.seikatsu_jokyo?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 46, lines: 5 }}
        />,
    ]
    // const setaiJokyoOptionWatch = useWatch({ control, name: 'setai_jokyo', defaultValue: '' })
    // useEffect(() => {
    //     const setaiJokyoArg = {
    //         watchValue: setaiJokyoOptionWatch,
    //         targetName: 'setai_jokyo_sonota',
    //         targetId: 'setai_jokyo_sonota_personal',
    //         checkValue: '999',
    //         // focusMethod: setFocus,
    //         setValueMethod: setValue,
    //     }
    //     SwitchDisplay(setaiJokyoArg)
    // }, [setaiJokyoOptionWatch, setValue])
    const sumaiOptionWatch = useWatch({ control, name: 'sumai', defaultValue: '' })
    useEffect(() => {
        // console.log(sumaiOptionWatch)
        const shakuyaArg = {
            watchValue: sumaiOptionWatch,
            targetName: 'sumai_shakuya_kbn',
            targetId: 'sumai_shakuya_kbn_personal',
            checkValue: '001',
            setValueMethod: setValue,
        }
        const mochiieArg = {
            watchValue: sumaiOptionWatch,
            targetName: 'sumai_mochiie_loan',
            targetId: 'sumai_mochiie_loan_personal',
            checkValue: '002',
            setValueMethod: setValue,
        }
        // const sumaiSonotaArg = {
        //     watchValue: sumaiOptionWatch,
        //     targetName: 'sumai_sonota',
        //     targetId: 'sumai_sonota_personal',
        //     checkValue: '999',
        //     setValueMethod: setValue,
        // }
        SwitchDisplay(shakuyaArg)
        SwitchDisplay(mochiieArg)
        // SwitchDisplay(sumaiSonotaArg)
    }, [sumaiOptionWatch, setValue])
    return (
        <>
            <FormBlock categoryName='生活状況' formItems={contents} />
        </>
    )
}
