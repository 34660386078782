import React, { useState, useEffect, useRef, createContext } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { withRouter } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Div100vh from 'react-div-100vh'
import { firebase, db, func, auth } from '../func/firebase'
import { useAuthContext } from '../func'
import { Button } from '../atom'
import { LoginForm } from '../block'

// let LoginFormCompoRender = 0
export const Login = (props) => {
    // LoginFormCompoRender++
    // console.log('LoginFormCompoRender', LoginFormCompoRender)
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const schema = yup.object().shape({
        uid: yup
            .string()
            .required('ログインIDは必須項目です')
            .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, '半角英数字/記号を入力してください'),
        password: yup
            .string()
            .required('パスワードは必須項目です')
            .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/, '半角英数字/記号を入力してください'),
    })
    const methods = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        // defaultValues: { uid: '', password: '' },
    })
    const { login } = useAuthContext()
    // const resetText = () => {
    //     methods.reset()
    // }
    const onSubmit = (data) => {
        // const dataJson = JSON.stringify(data)
        // console.log({ data, dataJson })
        login(data.uid, data.password, props.history)
    }
    // console.log(methods.errors)
    const test = async () => {
        try {
            // const id = 'asukayao'
            // const snapshot = await db.collectionGroup('shisetsu_id').where('login_id', '==', id).get()
            // // const docs = []
            // snapshot.forEach((doc) => {
            //     console.log(doc.id, ' => ', doc.data())
            //     // docs.push(doc.data())
            // })
            const sendData = { uid: 'hoge', password: 'hoge' }
            const functions = firebase.functions('asia-northeast2')
            const cfFunc = functions.httpsCallable('Auth')
            cfFunc(sendData)
                .then((body) => {
                    console.log('res body:', body)
                    console.log('Create Token Success')
                })
                .catch((error) => {
                    console.log('Create Token Error:', error)
                    throw new Error(error)
                })
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Div100vh>
            <div
                className='
            w-full
            flex flex-col items-center
        '
            >
                <div className='w-full flex flex-col items-center'>
                    <div className='my-3 sm:my-6'>
                        <img className='w-24 h-24 sm:w-32 sm:h-32' alt='logo' src='/osn_logo.svg' />
                    </div>
                    <div className='w-72 sm:w-2/3 my-1 px-2 flex justify-center'>
                        <h2 className='font-bold text-gray-700 text-2xl sm:text-4xl leading-10 whitespace-pre'>{`大阪しあわせネットワーク\n支援アプリ`}</h2>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form
                        className='sm:max-w-2xl grid mt-4 sm:mt-8 mb-6 sm:mb-8 mx-10 sm:mx-8 px-8 pt-6 pb-4 bg-gray-50 rounded-lg sm:rounded-xl border border-gray-400'
                        name='LoginForm'
                        onSubmit={methods.handleSubmit(onSubmit)}
                    >
                        <LoginForm />
                        <div className='py-2 place-self-end'>
                            {/* <Button type='button' value='リセット' onClick={resetText} outline={true} /> */}
                            {/* <Button type='button' value='Login Test' onClick={test} outline={true} /> */}
                            <Button type='submit' value='ログイン' className='mt-4' />
                        </div>
                    </form>
                    {/* <DevTool control={methods.control} /> */}
                </FormProvider>
            </div>
        </Div100vh>
    )
}
//withRouterでラップすることで関数内でprops.historyが使えるようになる
export default withRouter(Login)
