import React from 'react'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { selectionOptions } from '../json'

export default function PersonalInfoFamily() {
    const style = {
        container: 'grid grid-cols-12 mb-4',
        label: 'col-span-12 mb-2 text-lg sm:text-2xl text-gray-600',
        roughContainer: 'col-span-12 grid grid-flow-row grid-cols-7 items-end border-b-2 border-gray-50 bg-gray-200',
        itemBoxName: 'max-w-xs grid col-span-3 items-center text-sm sm:text-lg ',
        itemBoxZokugara: 'max-w-xs grid col-span-2 items-center text-sm sm:text-lg ',
        itemBox: 'max-w-xs grid col-span-1 items-center text-sm sm:text-lg ',
        itemU: 'w-full h-full row-start-1 text-center',
        itemUText: 'pt-1 mx-1 text-xs sm:text-sm text-teal-600',
        itemD: 'flex justify-center row-start-2',
        itemDText: 'py-1 mx-1',
        Chevron: 'w-6 h-8 text-gray-500',
        detailContainer: 'col-span-12 pb-4 pl-2 pr-4 bg-gray-100',
        inputParent: 'mt-3 mb-1 grid grid-flow-row grid-cols-7 sm:grid-cols-11 items-end',
        inputLabel: 'col-span-3 sm:col-start-2 pr-2 text-sm sm:text-lg text-right break-all',
        input: 'p-0 pt-1 pl-1 text-sm sm:text-base bg-transparent border-0 border-b border-gray-400 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent',
        select: 'p-0 pt-1 pl-1 text-sm sm:text-base bg-transparent border-0 border-b border-gray-400 focus:bg-white focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent',
        error: 'text-right',
        errorText: 'text-sm sm:text-base text-rose-400',
    }
    const {
        register,
        formState: { errors },
        control,
        getValues,
    } = useFormContext()
    const [showDetail, setShowDetail] = useState({ index: '' })
    const [roughDisplay, setRoughDisplay] = useState([])
    const toggle = useCallback(
        (index) => {
            if (index !== showDetail.index) {
                setShowDetail({ ...showDetail, index: index })
            } else {
                setShowDetail({ ...showDetail, index: '' })
            }
        },
        [showDetail]
    )
    const kazoku_zokugara_options = selectionOptions['kazoku_zokugara_options']
    //コード番号で記録されている項目をGUI表示名に変換する処理
    const codeAndDisplayForMatch = useCallback((obj) => {
        const object = {}
        obj.map((item) => (object[item.code] = item['display']))
        return object
    }, [])
    const kazoku_zokugara_match = useMemo(() => codeAndDisplayForMatch(kazoku_zokugara_options), [codeAndDisplayForMatch, kazoku_zokugara_options])
    //家族データを最初に読み込んだ時、簡易表示に反映されないため、データが有った場合のみ再レンダする用
    const familyListWatch = useWatch({ control, name: 'kazoku_no_1', defaultValue: '' })
    //簡易表示用,再レンダは詳細表示のオンオフ,又はkanri_no_1が変更された時
    useEffect(() => {
        const names = (index) => {
            return [
                // `kazoku_no_${index + 1}`,
                `kazoku_name_${index + 1}`,
                `kazoku_zokugara_${index + 1}`,
                `kazoku_nenrei_${index + 1}`,
                // `shuro_shugaku_jokyo_${index + 1}`,
                // `kenko_jotai_${index + 1}`,
                // `biko_${index + 1}`,
            ]
        }
        // console.log('names:', ...names())
        const listNames = []
        Array.from({ length: 5 }).forEach((v, i) => listNames.push(...names(i)))
        // console.log('listNames', listNames)
        // console.log('test', getValues(listNames))
        const arrayChunk = ([...array], size = 1) => {
            return array.reduce((acc, value, index) => (index % size ? acc : [...acc, array.slice(index, index + size)]), [])
        }
        const chunkedValues = arrayChunk(getValues(listNames), 3)
        // console.log('chunk', arrayChunk(getValues(listNames), 3))
        const familyValues = []
        chunkedValues.forEach((v, i) => {
            const obj = {}
            obj[names(i)[0]] = v[0] ? v[0] : '-'
            obj[names(i)[1]] = v[1] ? kazoku_zokugara_match[v[1]] : '-'
            obj[names(i)[2]] = v[2] ? v[2] : '-'
            familyValues.push(obj)
        })
        // console.log('familyValues', familyValues)
        setRoughDisplay(familyValues)
        // console.log('setted familyValues')
    }, [getValues, kazoku_zokugara_match, showDetail, familyListWatch])
    const inputFamilyDetail = ({ id, name, type, className, label, width, maxLength, inputMode = 'text' }) => (
        <li>
            <div className={style.inputParent}>
                <p className={style.inputLabel}>{label}</p>
                <input id={id ? id : name} name={name} className={`${className} ${width}`} type={type} maxLength={maxLength} inputMode={inputMode} {...register(name, {})} />
            </div>
            {errors[name]?.message && (
                <div className={style.error}>
                    <p className={style.errorText}>{errors[name]?.message}</p>
                </div>
            )}
        </li>
    )
    const selectFamilyDetail = ({ id, name, className, label, options, firstOption, firstVal, width }) => (
        <li className={style.inputParent}>
            <p className={style.inputLabel}>{label}</p>
            <select id={id ? id : name} name={name} className={`${className} ${width}`} {...register(name, {})}>
                <option value={firstVal}>{firstOption}</option>
                {options.map((value, index) => (
                    <option key={index} value={value.code ? value.code : value}>
                        {value.display ? value.display : value}
                    </option>
                ))}
            </select>
        </li>
    )
    const familyList = (index) => (
        <React.Fragment key={index}>
            <ul className={style.roughContainer} onClick={() => toggle(index)}>
                <li className={style.itemBoxName}>
                    <div className={style.itemU}>
                        <p className={style.itemUText}>氏名</p>
                    </div>
                    <div className={style.itemD}>
                        <p className={style.itemDText}>{roughDisplay.length > 0 && roughDisplay[index][`kazoku_name_${index + 1}`]}</p>
                    </div>
                </li>
                <li className={style.itemBoxZokugara}>
                    <div className={style.itemU}>
                        <p className={style.itemUText}>続柄</p>
                    </div>
                    <div className={style.itemD}>
                        <p className={style.itemDText}>{roughDisplay.length > 0 && roughDisplay[index][`kazoku_zokugara_${index + 1}`]}</p>
                    </div>
                </li>
                <li className={style.itemBox}>
                    <div className={style.itemU}>
                        <p className={style.itemUText}>年齢</p>
                    </div>
                    <div className={style.itemD}>
                        <p className={style.itemDText}>{roughDisplay.length > 0 && roughDisplay[index][`kazoku_nenrei_${index + 1}`]}</p>
                    </div>
                </li>
                <li className={style.itemBox}>
                    <div className={style.itemU}>
                        <p className={style.itemUText}>詳細</p>
                    </div>
                    <div className={style.itemD}>
                        <ChevronDownIcon className={style.Chevron} />
                    </div>
                </li>
            </ul>
            <ul className={showDetail.index === index ? style.detailContainer : ' hidden'}>
                <input name={`kazoku_no_${index + 1}`} {...register(`kazoku_no_${index + 1}`, {})} className='hidden' />
                {inputFamilyDetail({ name: `kazoku_name_${index + 1}`, type: 'text', className: style.input, label: '氏名', width: 'col-span-4', maxLength: '80' })}
                {selectFamilyDetail({ name: `kazoku_zokugara_${index + 1}`, className: style.select, label: '続柄', options: kazoku_zokugara_options, width: 'col-span-4 sm:col-span-4' })}
                {inputFamilyDetail({ name: `kazoku_nenrei_${index + 1}`, type: 'text', inputMode: 'numeric', className: style.input, label: '年齢', width: 'col-span-1' })}
                {inputFamilyDetail({ name: `shuro_shugaku_jokyo_${index + 1}`, type: 'text', className: style.input, label: '就労/就学', width: 'col-span-4', maxLength: '256' })}
                {inputFamilyDetail({ name: `kenko_jotai_${index + 1}`, type: 'text', className: style.input, label: '健康状態', width: 'col-span-4', maxLength: '256' })}
                {inputFamilyDetail({ name: `biko_${index + 1}`, type: 'text', className: style.input, label: '備考(同居/別居・収入等)', width: 'col-span-4', maxLength: '256' })}
            </ul>
        </React.Fragment>
    )
    const genFamilyList = Array.from({ length: 5 }).map((v, i) => familyList(i))
    return (
        <div className={style.container}>
            <h2 className={style.label}>家族状況(5人まで入力可)</h2>
            {genFamilyList}
        </div>
    )
}
