import React, { useState, useEffect, useRef, createContext, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import clonedeep from 'lodash.clonedeep'
import { firebase, db, func } from '../func/firebase'
import { useAuthContext } from '../func'
import { inputData, checkKeyDown, insertByDispId, replaceLineCode, getCurrentDate, dateFormToData, checkBoxToData, dataForCf, postToCf, feedBackDisplay } from '../func/CommonFunc'
import { useFbDataContext } from '../page'
import { NewConsultRecept, NewConsultTarget, NewConsultIntroduction, NewConsultDetail } from '../block'
import { Dialog, Modal, Button } from '../atom'
import { defaultValues, selectionOptions } from '../json'

// let EditConsultReceptCompoRender = 0
export default function EditConsultRecept() {
    // EditConsultReceptCompoRender++
    // console.log('EditConsultReceptCompoRender', EditConsultReceptCompoRender)
    const { currentUser, userData } = useAuthContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const { fbData, setFbData, setShowDetail, showDetail, setShowEdit } = useFbDataContext()
    const schema = yup.object().shape({
        uketsuke_date: yup.string().required('受付日は必須項目です'),
        uketsuke_hoho: yup.string().required('受付方法は必須項目です'),
        taishosha_name_kana: yup
            .string()
            .required('対象者名(フリガナ)は必須項目です')
            .matches(/^[ア-ン゛゜ァ-ォャ-ョー「」。、 　]+$/, 'カタカナを入力してください'),
        taishosha_name: yup.string().required('対象者名(氏名)は必須項目です'),
        // .matches(/([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu, '漢字を入力してください'),
        gender: yup.string().required('対象者(性別)は必須項目です'),
        nenrei: yup
            .string()
            .required('年齢は必須項目です')
            .matches(/^([1-9]\d*|0)$/, '0以上の整数を入力してください')
            .max(3, '3文字以下にしてください'),
        shiku_name: yup.string().required('住所(市区町村)は必須項目です'),
        shokai_keiro: yup.string().required('紹介経路は必須項目です'),
        kongo_mikomi: yup.string().required('今後の見込みは必須項目です'),
    })
    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: defaultValues.reception,
    })
    const refDataLoad = useRef(true)
    const refShowDetail = useRef(null)
    useEffect(() => {
        // console.log('useEffect Reloaded in EditConSultRecept')
        // console.log('fbData in EditConSultRecept', fbData)
        // console.log('ref?', refDataLoad)
        const open = showDetail.index === 10
        const close = showDetail.index !== 10 && refShowDetail.current === 10
        if (refDataLoad.current && open) {
            // console.log('data input in EditConSultRecept')
            const dispData = { ...fbData['10'], ...fbData['101'], ...fbData['102'], ...fbData['70'] }
            // console.log('disp10', dispData)
            inputData(dispData, methods.setValue)
            if (dispData['update_date'] !== '') {
                refDataLoad.current = false
            }
        } else if (close) {
            // console.log('close and setFbData in EditConSultRecept')
            // const cloneData = clonedeep(fbData)
            // console.log('cloneData in EditConSultRecept', cloneData)
            // const formData = methods.getValues()
            //画面12- formname重複避け用にデータを複製(「相談者種別」「相談者種別-その他」「生保区分」)
            // formData['sodansha_shubetsu_sptc'] = formData['sodansha_shubetsu']
            // formData['sodansha_shubetsu_sonota_sptc'] = formData['sodansha_shubetsu_sonota']
            // formData['seiho_kbn_sptc'] = formData['seiho_kbn']
            // console.log('formData in EditConSultRecept', formData)
            // cloneData['10'] = insertByDispId('10', formData, cloneData)
            // cloneData['101'] = insertByDispId('101', formData, cloneData)
            // cloneData['102'] = insertByDispId('102', formData, cloneData)
            // console.log('cloneData editted in EditConSultRecept', cloneData)
            // setFbData(cloneData)
            refDataLoad.current = true
        }
    }, [fbData, setFbData, methods, showDetail])
    useEffect(() => {
        // console.log('showDetail in  EditConSultRecept :', showDetail)
        refShowDetail.current = showDetail.index
    }, [showDetail])
    //モーダルウインドウ表示チェック用
    // useEffect(() => {
    //     const closeModal = () => {
    //         setShowModal({ show: false })
    //     }
    //     setShowModal({
    //         show: true,
    //         // text: '送信中...',
    //         // state: 'pending',
    //         text: '送信できませんでした',
    //         errorText: 'ログインから6時間経過したため、ログイン有効期限を過ぎました',
    //         state: 'rejected',
    //         doOk: closeModal,
    //     })
    // }, [])
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backToShowDetail = useCallback(() => {
        const cancelEdit = () => {
            // console.log('yes')
            const pathName = history.location.pathname
            history.replace({ pathname: pathName })
            window.location.reload()
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        setShowDialog({
            ...showDialog,
            show: true,
            message: '相談の詳細・編集画面へ戻り、編集中の内容は消去します。\nよろしいですか？',
            doYes: cancelEdit,
            doNo: hideDialog,
            textYes: 'はい',
            textNo: 'いいえ',
        })
    }, [history, showDialog])
    //ユーザーデータが無い時の処理(送信ボタンを押せなくする、管理者権限閲覧時も同様)
    const [sendable, setSendable] = useState({ send: false, reasonText: '' })
    useEffect(() => {
        const isUserId = 'user_id' in userData
        const isShisetsuId = 'shisetsu_id' in userData
        // console.log({ isUserId, isShisetsuId })
        if (!isUserId) {
            console.error('userIdがありません')
            setSendable({ send: false, reasonText: 'ユーザーIDが無いため' })
        } else if (!isShisetsuId) {
            console.error('shisetsuIdがありません')
            setSendable({ send: false, reasonText: '施設IDが無いため' })
        } else if (isUserId && isShisetsuId) {
            // console.log('userData in EditConsult', userData)
            const uFacilityId = userData.shisetsu_id
            const uKengen = userData.kengen
            const isMatchFacilityId = fbData['10']['shisetsu_id'] === uFacilityId
            const isAdmin = ['1', '2'].includes(uKengen)
            // console.log({ uFacilityId, uKengen, isMatchFacilityId, isAdmin })
            if (isAdmin && !isMatchFacilityId) {
                setSendable({ send: false, reasonText: '確認用権限のため' })
            } else {
                setSendable({ send: true, reasonText: '' })
            }
        }
    }, [userData, fbData])
    const unSendable = useCallback(
        (text) => {
            const hideDialog = () => {
                setShowDialog({ ...showDialog, show: false })
            }
            const reload = () => {
                const pathName = history.location.pathname
                history.replace({ pathname: pathName })
                window.location.reload()
            }
            const message = `${text}現在送信できません\nページをリロードするか(入力内容は消去されます)\nウインドウを閉じてしばらくお待ち下さい\n待っても送信できない時はログインし直してみてください`
            setShowDialog({
                ...showDialog,
                show: true,
                message: message,
                doYes: reload,
                doNo: hideDialog,
                textYes: 'リロード',
                textNo: '閉じる',
            })
        },
        [showDialog, history]
    )
    const onSubmit = (data) => {
        // const cloneData = JSON.parse(JSON.stringify(data))
        const cloneData = clonedeep(data)
        // console.log('FormData', cloneData)
        const dataReception = { ...fbData['10'], ...fbData['101'], ...fbData['102'] }
        Object.assign(dataReception, cloneData)
        //マスタデータ(ユーザーID,施設ID,画面ID,処理区分)を追加
        dataReception['user_id'] = userData.user_id
        dataReception['shisetsu_id'] = userData.shisetsu_id
        dataReception['disp_id'] = '10'
        dataReception['shori_div'] = '1'
        //事例番号は消去
        // dataReception['jirei_no'] = ''
        delete dataReception['jirei_no']
        //改行コードを置換
        //画面10
        dataReception['shokaitaio'] = replaceLineCode(dataReception['shokaitaio'])
        //画面101
        dataReception['adr_dtl'] = replaceLineCode(dataReception['adr_dtl'])
        dataReception['shokai_naiyo'] = replaceLineCode(dataReception['shokai_naiyo'])
        //日付を追加,書き換え(obj=>str)
        //画面共通
        dataReception['update_date'] = getCurrentDate()
        //画面10
        dataReception['uketsuke_date'] = dateFormToData(dataReception['uketsuke_date'])
        //画面101
        dataReception['birthday'] = dateFormToData(dataReception['birthday'])
        //CheckBoxデータを整形 元のプロパティを消去
        //画面101
        const shubetsuItems = dataReception['sodansha_shubetsu']
        dataReception['sodansha_shubetsu_list'] = checkBoxToData(shubetsuItems, 'sodansha_shubetsu')
        delete dataReception['sodansha_shubetsu']
        //重複避けデータを削除
        delete dataReception['sodansha_shubetsu_sptc']
        delete dataReception['seiho_kbn_sptc']
        delete dataReception['sodansha_shubetsu_sonota_sptc']
        delete dataReception['todouhuken']
        console.log('disp10 SendData', dataReception)
        //デバッグ用エラーチェック
        //必須エラー
        // delete dataReception['uketsuke_date']
        //ロック処理
        // dataReception['version_no'] = '1.000'

        const response = postToCf(dataReception)
        feedBackDisplay(response, setShowModal)
    }
    return (
        <div className='w-full flex flex-col items-center'>
            <Modal showModal={showModal} />
            <Dialog showDialog={showDialog} />
            {/* <Button name='modalButton' type='button' value='モーダル表示' onClick={toggleModal} /> */}
            <FormProvider {...methods}>
                <form
                    className='w-full mb-4 px-2 sm:max-w-2xl sm:mx-8 flex flex-col items-center'
                    name='EditConsultReceptForm'
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    <NewConsultRecept />
                    <NewConsultTarget />
                    <NewConsultIntroduction />
                    <NewConsultDetail />
                    <div className='pt-6 pb-4 max-w-xl ml-auto flex flex-col items-end sm:flex-row sm:justify-end flex-wrap gap-x-4 gap-y-3'>
                        {sendable.send ? <Button type='submit' value='送信' /> : <Button type='button' value='送信' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />}
                        <Button type='button' value='キャンセル' onClick={backToShowDetail} outline={true} />
                    </div>
                </form>
                {/* <DevTool control={methods.control} /> */}
            </FormProvider>
        </div>
    )
}
