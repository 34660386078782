import { useFormContext } from 'react-hook-form'
import { InputText, InputDate, TextArea } from '../atom'
export default function SupportProcessInput() {
    const style = {
        itemBoxNo: 'hidden col-start-3 col-end-14 row-start-1 row-end-3 px-2',
        itemBoxDate: 'col-start-3 col-end-14 row-start-1 row-end-3 px-2',
        itemBoxText: 'col-start-3 col-end-14 row-start-3 row-end-7 p-2',
    }
    const {
        register,
        formState: { errors },
        control,
    } = useFormContext()
    return (
        <ul>
            <li className={style.itemBoxNo}>
                <InputText
                    label='援助No'
                    name='enjo_no'
                    id='enjo_no'
                    register={register('enjo_no', {})}
                    type='text'
                    // errorText={errors.enjo_no_etc?.message}
                    placeholder=''
                />
            </li>
            <li className={style.itemBoxDate}>
                <InputDate
                    label='日付'
                    name={`enjo_date`}
                    control={control}
                    startDate=''
                    // errorText={errors.enjo_date?.message}
                    placeholder='日付を入力'
                    // className={style.inputDate}
                    // isRequired={true}
                />
            </li>
            <li className={style.itemBoxText}>
                <TextArea
                    label='今回の支援経過内容'
                    name='enjo_keika_naiyo'
                    id='enjo_keika_naiyo'
                    register={register('enjo_keika_naiyo', {})}
                    // errorText={errors.enjo_keika_naiyo?.message}
                    placeholder='タッチで拡大表示'
                    subText=''
                    // isRequired={true}
                    expandSize={true}
                    pasTable={true}
                />
            </li>
        </ul>
    )
}
