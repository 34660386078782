import { useFormContext } from 'react-hook-form'
import { TextArea, FormBlock } from '../atom'

// let SptPDBlockRender = 0
export default function SupportPlanDetail(props) {
    // SptPDBlockRender++
    // console.log('SptPDBlockRender', SptPDBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const contents = [
        <TextArea
            label='生活環境面に関する支援の必要性'
            name='seikatsukankyo_shien_hitsuyo'
            id='seikatsukankyo_shien_hitsuyo'
            register={register('seikatsukankyo_shien_hitsuyo', {})}
            // errorText={errors.seikatsukankyo_shien_hitsuyo?.message}
            placeholder='タッチすると拡大表示します'
            subText='※家族状況・居所・収入・就労等'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
        <TextArea
            label='身体的・精神的治療に関する支援の必要性'
            name='chiryo_shien_hitsuyo'
            id='chiryo_shien_hitsuyo'
            register={register('chiryo_shien_hitsuyo', {})}
            // errorText={errors.chiryo_shien_hitsuyo?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
        <TextArea
            label='経済的援助の必要性'
            name='keizai_enjo_hitsuyo'
            id='keizai_enjo_hitsuyo'
            register={register('keizai_enjo_hitsuyo', {})}
            // errorText={errors.keizai_enjo_hitsuyo?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
        <TextArea
            label='今後利用を検討すべき福祉制度'
            name='kento_fukushi_seido'
            id='kento_fukushi_seido'
            register={register('kento_fukushi_seido', {})}
            // errorText={errors.kento_fukushi_seido?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
        <TextArea
            label='社会貢献事業としての支援目標'
            name='shakaikoken_shien_mokuhyo'
            id='shakaikoken_shien_mokuhyo'
            register={register('shakaikoken_shien_mokuhyo', {})}
            // errorText={errors.shakaikoken_shien_mokuhyo?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 43, lines: 6 }}
        />,
    ]
    return (
        <>
            <FormBlock categoryName='支援計画' formItems={contents} />
        </>
    )
}
