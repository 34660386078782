import React, { useState, useEffect, useMemo, useCallback, createContext, useContext, useRef } from 'react'
import { useLocation } from 'react-router'
import Div100vh from 'react-div-100vh'
import clonedeep from 'lodash.clonedeep'
import { ChevronDownIcon, ChevronUpIcon, XCircleIcon } from '@heroicons/react/solid'
import { ClipboardCopyIcon, ClipboardCheckIcon } from '@heroicons/react/outline'
import { eraYear, codeToDisplay, genderMatch, ageToString, listMatch, dateFormToData, dataToCheckBox } from '../func/CommonFunc'
import { PastCaseCopyBlock, PastCaseFullTextBlock } from '../atom'
import { selectionOptions } from '../json'

export default function PastCaseModal({ targetData, setTargetData }) {
    const shikuNameMatch = useMemo(() => codeToDisplay(selectionOptions.shiku_name_options), [])
    const setaiMatch = useMemo(() => codeToDisplay(selectionOptions.setai_jokyo_options), [])
    const keiroMatch = useMemo(() => codeToDisplay(selectionOptions.shokai_keiro_options), [])
    const gyoseiMatch = useMemo(() => codeToDisplay(selectionOptions.shokai_keiro_gyosei_options), [])
    const sumaiMatch = useMemo(() => codeToDisplay(selectionOptions.sumai_options), [])
    const shakuyaMatch = useMemo(() => codeToDisplay(selectionOptions.sumai_shakuya_kbn_options), [])
    const loanMatch = useMemo(() => codeToDisplay(selectionOptions.sumai_mochiie_loan_options), [])
    const shubetsuMatch = useMemo(() => codeToDisplay(selectionOptions.sodansha_shubetsu_options), [])
    const keywordMatch = useMemo(() => codeToDisplay(selectionOptions.sodansha_keyword_options), [])
    const shushiMatch = useMemo(() => codeToDisplay(selectionOptions.shushi_jokyo_options), [])
    const hokenShoMatch = useMemo(() => codeToDisplay(selectionOptions.iryo_hokensho_options), [])
    const techoMatch = useMemo(() => codeToDisplay(selectionOptions.shogai_techo_options), [])
    const kaigodoMatch = useMemo(() => codeToDisplay(selectionOptions.yokaigodo_options), [])
    const shogaiteidoMatch = useMemo(() => codeToDisplay(selectionOptions.shogaiteido_kubun_options), [])
    const enjogoMatch = useMemo(() => codeToDisplay(selectionOptions.enjogo_seikatsu_options), [])
    const style = {
        background: 'fixed flex justify-end inset-0 w-full h-full px-2 sm:px-4 py-4 sm:pt-8 sm:pb-12 bg-opacity-50 bg-gray-800 z-10',
        xCircleIcon: 'absolute top-1 right-1 sm:top-4 sm:right-2 w-12 h-12 sm:w-16 sm:h-16 text-gray-500 bg-white rounded-full cursor-pointer',
        container: 'w-full lg:w-3/5 overflow-y-hidden overflow-y-scroll px-2 py-2 sm:px-4 py-6 rounded-lg border-2 bg-gray-300 border-gray-500 text-gray-700 text-xl whitespace-pre-wrap',
        itemBaseContainer: 'w-full bg-gray-100 rounded-lg sm:rounded-3xl border border-gray-400',
        itemBaseBlock: 'grid grid-cols-16 items-center mx-1 sm:mx-3 px-1 py-1 sm:py-4 border-b border-gray-300 text-sm sm:text-lg text-gray-600',
        itemBaseL: 'col-span-5 sm:col-span-4 pr-2 text-right',
        itemBaseR: 'col-span-11 sm:col-span-12 pl-2',
        dispIdList: 'cursor-pointer w-full flex flex-col',
        dispIdItem: 'flex flex-row mt-4 px-1 pt-2 pb-1 sm:pt-4 sm:pb-2 bg-gray-300 border-b border-gray-400',
        itemTitleBlock: 'grid grid-cols-16 items-center bg-gray-600 border-b border-gray-300 text-sm sm:text-lg text-gray-50',
        itemDetailBlock: 'grid grid-cols-16 items-center py-1 sm:py-3 bg-gray-50 border-b border-gray-300 text-sm sm:text-lg text-gray-600',
        itemDetail1: 'col-span-5 sm:col-span-4 flex flex-col text-right my-2 pr-2 whitespace-pre-wrap',
        itemDetail2: 'col-span-9 sm:col-span-10 my-2 px-2 line-clamp-3 sm:line-clamp-4',
        itemDetail3: 'col-span-2 sm:col-span-2 pr-1 items-center text-xs sm:text-sm',
        itemDetail2_largeLine: 'col-span-9 sm:col-span-10 my-2 px-2 line-clamp-5',
        chberonIcon: 'w-8 h-8 mr-2',
        clipCopyIcon: 'w-6 h-6 sm:w-8 sm:h-8',
    }
    const [showDetail, setShowDetail] = useState({ 10: true, 11: true, 12: true, 20: true, 40: true, 50: true })
    const [fullText, setFullText] = useState({ isDislay: false, innerText: '' })
    const [guiData, setGuiData] = useState({})
    const detailDisplay = useCallback(
        (dispId) => {
            const obj = showDetail
            if (showDetail[dispId]) {
                obj[dispId] = false
                setShowDetail({ ...obj })
            } else {
                obj[dispId] = true
                setShowDetail({ ...obj })
            }
            // console.log({ showDetail, dispId })
        },
        [showDetail, setShowDetail]
    )
    // useEffect(() => {
    //     console.log('dispId Detail?', showDetail)
    // }, [showDetail])
    useEffect(() => {
        if (Object.keys(targetData.data).length > 0) {
            // console.log(targetData.data)
            const cloneFbData = clonedeep(targetData.data)
            cloneFbData['10'] = { ...cloneFbData['10'], ...cloneFbData['101'], ...cloneFbData['102'] }
            cloneFbData['11'] = { ...cloneFbData['11'], ...cloneFbData['101'] }
            cloneFbData['12'] = { ...cloneFbData['12'], ...cloneFbData['102'] }
            delete cloneFbData['101']
            delete cloneFbData['102']
            //画面11
            const dateStr = cloneFbData['11']['shokaimendan_date']
            const warekiYear = eraYear(Number(dateStr.slice(0, 4)))
            cloneFbData['11']['shokaimendan_date'] = `${warekiYear}(${dateStr.slice(0, 4)})年${dateStr.slice(4, 6)}月${dateStr.slice(6)}日`
            const gender = cloneFbData['11']['gender']
            cloneFbData['11']['gender'] = !!gender ? genderMatch(gender) : ''
            const age = cloneFbData['11']['nenrei']
            cloneFbData['11']['nenrei'] = !!age ? ageToString(Number(age)) : ''
            const setai = cloneFbData['11']['setai_jokyo']
            cloneFbData['11']['setai_jokyo'] = !!setai ? setaiMatch[setai] : ''
            const keiroStr = cloneFbData['11']['shokai_keiro'] ? `${keiroMatch[cloneFbData['11']['shokai_keiro']]}` : ''
            const gyoseiStr = gyoseiMatch[cloneFbData['11']['shokai_keiro_gyosei']] ? ` -${gyoseiMatch[cloneFbData['11']['shokai_keiro_gyosei']]}` : ''
            const gyoseiSonota = cloneFbData['11']['shokai_keiro_gyosei_free'] ? `(${cloneFbData['11']['shokai_keiro_gyosei_free']})` : ''
            cloneFbData['11']['shokai_keiro'] = `${keiroStr} ${gyoseiStr} ${gyoseiSonota}`
            if (cloneFbData['11']['sumai'] === '001') {
                const shakuya = cloneFbData['11']['sumai_shakuya_kbn'] ? shakuyaMatch[cloneFbData['11']['sumai_shakuya_kbn']] : ''
                cloneFbData['11']['sumai'] = `${sumaiMatch[cloneFbData['11']['sumai']]}(${shakuya})`
            } else if (cloneFbData['11']['sumai'] === '002') {
                const loan = cloneFbData['11']['sumai_mochiie_loan'] ? loanMatch[cloneFbData['11']['sumai_mochiie_loan']] : ''
                cloneFbData['11']['sumai'] = `${sumaiMatch[cloneFbData['11']['sumai']]}(${loan})`
            } else if (cloneFbData['11']['sumai'] === '999') {
                const sonota = cloneFbData['11']['sumai_sonota'] ? cloneFbData['11']['sumai_sonota'] : ''
                cloneFbData['11']['sumai'] = `${sumaiMatch[cloneFbData['11']['sumai']]}(${sonota})`
            } else {
                cloneFbData['11']['sumai'] = sumaiMatch[cloneFbData['11']['sumai']]
            }
            //画面12
            cloneFbData['12']['sodansha_shubetsu'] = listMatch(cloneFbData['12']['sodansha_shubetsu_list'], shubetsuMatch)
            cloneFbData['12']['sodansha_keyword'] = listMatch(cloneFbData['12']['sodansha_keyword_list'], keywordMatch)
            const shushi = listMatch(cloneFbData['12']['shushi_jokyo_list'], shushiMatch)
            const shushiEtc = cloneFbData['12']['shushi_jokyo_sonota'] ? `(${cloneFbData['12']['shushi_jokyo_sonota']})` : ''
            cloneFbData['12']['shushi_jokyo'] = `${shushi} ${shushiEtc}`
            const hokensho = cloneFbData['12']['iryo_hokensho'] ? `医療保険証 - ${hokenShoMatch[cloneFbData['12']['iryo_hokensho']]}\n` : ''
            const techo = cloneFbData['12']['shogai_techo'] ? `障がい者手帳 - ${techoMatch[cloneFbData['12']['shogai_techo']]}\n` : ''
            const kaigodo = cloneFbData['12']['yokaigodo'] ? `要介護度 - ${kaigodoMatch[cloneFbData['12']['yokaigodo']]}\n` : ''
            const shogaiteido = cloneFbData['12']['shogaiteido_kubun'] ? `障がい程度区分 - ${shogaiteidoMatch[cloneFbData['12']['shogaiteido_kubun']]}\n` : ''
            const shogaiEtc = cloneFbData['12']['sonota_shogaijoho'] ? `その他 (${cloneFbData['12']['sonota_shogaijoho']})` : ''
            // console.log('riyo seido', { hokensho, techo, kaigodo, shogaiteido, shogaiEtc })
            cloneFbData['12']['riyo_seido'] = `${hokensho}${techo}${kaigodo}${shogaiteido}${shogaiEtc}`
            //画面40
            const cloneSupportList = cloneFbData['40']['enjokeika_list']
            if (Array.isArray(cloneSupportList) && cloneSupportList.length > 0) {
                //日付順にソート(古い順)
                cloneSupportList.sort((a, b) => a['enjo_date'] - b['enjo_date'])
                const supportList = []
                cloneSupportList.forEach((value, index) => {
                    if (value['enjo_display'] === true) {
                        const dateStr = value['enjo_date']
                        const warekiYear = eraYear(Number(dateStr.slice(0, 4)))
                        const warekiStr = `${warekiYear}年\n(${dateStr.slice(0, 4)}年)\n${dateStr.slice(4, 6)}月${dateStr.slice(6)}日`
                        value['enjo_date'] = warekiStr
                        supportList.push(value)
                    }
                })
                // console.log({ supportList })
                cloneFbData['40']['enjokeika_list'] = supportList
            } else {
                cloneFbData['40']['enjokeika_list'] = [
                    {
                        enjo_no: '',
                        enjo_date: '',
                        enjo_keika_naiyo: '',
                    },
                ]
            }
            //画面50
            if (Array.isArray(cloneFbData['50']['enjogo_seikatsu_list'])) {
                const refEnjogo = selectionOptions['enjogo_seikatsu_options']
                const dataEnjogo = cloneFbData['50']['enjogo_seikatsu_list'].map((v) => v['enjogo_seikatsu'])
                const enjogoList = dataToCheckBox(refEnjogo, dataEnjogo)
                const enjogoStr = listMatch(enjogoList, enjogoMatch)
                const enjogoEtc = cloneFbData['50']['enjogo_seikatsu_sonota'] ? ` (${cloneFbData['50']['enjogo_seikatsu_sonota']})` : ''
                cloneFbData['50']['enjogo_seikatsu'] = `${enjogoStr}${enjogoEtc}`
            } else {
                cloneFbData['50']['enjogo_seikatsu'] = ''
            }

            if (Array.isArray(cloneFbData['50']['renkei_kikan_list'])) {
                const refRenkei = selectionOptions['shokai_keiro_options']
                const dataRenkei = cloneFbData['50']['renkei_kikan_list'].map((v) => v['renkei_kikan'])
                const renkeiList = dataToCheckBox(refRenkei, dataRenkei)
                const renkeiStr = listMatch(renkeiList, keiroMatch)
                const renkeiEtc = renkeiList.includes('999') ? `(${cloneFbData['50']['renkei_kikan_sonota']})` : ''
                // console.log({ renkeiList, renkeiStr, renkeiEtc })
                cloneFbData['50']['renkei_kikan'] = `${renkeiStr}${renkeiEtc}`
            } else {
                cloneFbData['50']['renkei_kikan'] = ''
            }

            const kikanStr = cloneFbData['50']['kikan_kind'] ? keiroMatch[cloneFbData['50']['kikan_kind']] : ''
            const kikanGyoseiStr = gyoseiMatch[cloneFbData['50']['kikan_kind_gyosei']] ? ` -${gyoseiMatch[cloneFbData['50']['kikan_kind_gyosei']]}` : ''
            const kikanGyoseiSonota = cloneFbData['50']['kikan_kind_gyosei_free'] ? `(${cloneFbData['50']['kikan_kind_gyosei_free']})` : ''
            // console.log({ kikanStr, kikanGyoseiStr, kikanGyoseiSonota })
            cloneFbData['50']['kikan_str'] = `${kikanStr} ${kikanGyoseiStr} ${kikanGyoseiSonota}`
            const startDateStr = cloneFbData['50']['shien_start_date']
            const endDateStr = cloneFbData['50']['shien_end_date']
            const startDateWareki = startDateStr ? `${eraYear(startDateStr.slice(0, 4))}(${startDateStr.slice(0, 4)})年${startDateStr.slice(4, 6)}月${startDateStr.slice(6)}日` : ''
            const endDateWareki = endDateStr ? `${eraYear(endDateStr.slice(0, 4))}(${endDateStr.slice(0, 4)})年${endDateStr.slice(4, 6)}月${endDateStr.slice(6)}日` : ''
            const termMonth = cloneFbData['50']['shien_months'] ? `${cloneFbData['50']['shien_months']}ヶ月と` : ''
            const termDay = cloneFbData['50']['shien_days'] ? `${cloneFbData['50']['shien_days']}日` : ''
            // console.log({ startDateStr, endDateStr, startDateWareki, endDateWareki })
            cloneFbData['50']['shien_term'] = `${startDateWareki}～\n${endDateWareki}\n${termMonth}${termDay}`
            // console.log(cloneFbData)
            setGuiData({ ...cloneFbData })
        }
    }, [
        targetData,
        setTargetData,
        setaiMatch,
        keiroMatch,
        gyoseiMatch,
        sumaiMatch,
        shakuyaMatch,
        loanMatch,
        shubetsuMatch,
        enjogoMatch,
        hokenShoMatch,
        kaigodoMatch,
        keywordMatch,
        shogaiteidoMatch,
        shushiMatch,
        techoMatch,
    ])
    // useEffect(() => {
    //     if (Object.keys(guiData).length > 0) {
    // console.log(guiData['10']['taishosha_id'], guiData)
    //     }
    // }, [guiData])
    const location = useLocation()
    useEffect(() => {
        // console.log('Location in PastCaseModal', location)
        const query = new URLSearchParams(location.search)
        const dispId = query.get('disp')
        if (dispId) {
            // console.log('dispId in PastCaseModal', dispId, typeof dispId)
            if (dispId !== '10') {
                const obj = { 10: false, 11: false, 12: false, 20: false, 40: false, 50: false }
                obj[dispId] = true
                setShowDetail({ ...obj })
            }
        }
    }, [location])
    const displayToggle = useCallback(() => {
        setTargetData({ ...targetData, display: false })
    }, [targetData, setTargetData])
    const displayFullText = useCallback(
        (text) => {
            setFullText({ ...fullText, isDislay: true, innerText: text })
        },
        [fullText]
    )
    return (
        <Div100vh>
            {fullText.isDislay && <PastCaseFullTextBlock fullText={fullText} setFullText={setFullText} />}
            <div
                id='pastcase_target_background'
                className={style.background}
                onClick={(e) => {
                    // console.log(e.target.id)
                    if (e.target.id === 'pastcase_target_background') {
                        displayToggle()
                    } else {
                    }
                }}
            >
                <XCircleIcon className={style.xCircleIcon} onClick={displayToggle} />
                <div className={style.container}>
                    <ul className={style.dispIdList}>
                        <li>
                            <ul className={style.itemBaseContainer}>
                                <li className={style.itemBaseBlock}>
                                    <p className={style.itemBaseL}>支援タイトル</p>
                                    <p className={style.itemBaseR}>{Object.keys(guiData).length > 0 && guiData['11']['shien_title']}</p>
                                </li>
                                <li className={style.itemBaseBlock}>
                                    <p className={style.itemBaseL}>初回面談日</p>
                                    <p className={style.itemBaseR}>{Object.keys(guiData).length > 0 && guiData['11']['shokaimendan_date']}</p>
                                </li>
                                <li className={style.itemBaseBlock}>
                                    <p className={style.itemBaseL}>対象者</p>
                                    <p className={style.itemBaseR}>
                                        {Object.keys(guiData).length > 0 && guiData['11']['gender']}
                                        {Object.keys(guiData).length > 0 && guiData['11']['nenrei']}
                                        {Object.keys(guiData).length > 0 ? (guiData['11']['shiku_name'] ? `  ${shikuNameMatch[guiData['11']['shiku_name']]}` : '') : ''}
                                    </p>
                                </li>
                                <li className={style.itemBaseBlock}>
                                    <p className={style.itemBaseL}>世帯状況</p>
                                    <p className={style.itemBaseR}>{Object.keys(guiData).length > 0 && guiData['11']['setai_jokyo']}</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={style.dispIdItem} onClick={() => detailDisplay(11)}>
                                {showDetail['11'] ? <ChevronUpIcon className={style.chberonIcon} /> : <ChevronDownIcon className={style.chberonIcon} />}
                                <p>相談受付(個人情報)</p>
                            </div>
                            <ul className={showDetail['11'] ? '' : 'hidden'}>
                                <li className={style.itemTitleBlock}>
                                    <div className={style.itemDetail1}>項目</div>
                                    <p className={style.itemDetail2}>内容</p>
                                    <p className={style.itemDetail3}>コピーする</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>住まい</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['11']['sumai']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>生活状況</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['11']['seikatsu_jokyo'])}>
                                        {Object.keys(guiData).length > 0 && guiData['11']['seikatsu_jokyo']}
                                    </p>
                                    <PastCaseCopyBlock dispId='11' field='seikatsu_jokyo' value={Object.keys(guiData).length > 0 && guiData['11']['seikatsu_jokyo']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>紹介経路</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['11']['shokai_keiro']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>当面の対応</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['11']['tomentaio'])}>
                                        {Object.keys(guiData).length > 0 && guiData['11']['tomentaio']}
                                    </p>
                                    <PastCaseCopyBlock dispId='11' field='tomentaio' value={Object.keys(guiData).length > 0 && guiData['11']['tomentaio']} />
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={style.dispIdItem} onClick={() => detailDisplay(12)}>
                                {showDetail['12'] ? <ChevronUpIcon className={style.chberonIcon} /> : <ChevronDownIcon className={style.chberonIcon} />}

                                <p>相談受付(相談内容)</p>
                            </div>
                            <ul className={showDetail['12'] ? '' : 'hidden'}>
                                <li className={style.itemTitleBlock}>
                                    <div className={style.itemDetail1}>項目</div>
                                    <p className={style.itemDetail2}>内容</p>
                                    <p className={style.itemDetail3}>コピーする</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>相談者種別</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['12']['sodansha_shubetsu']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'相談\nキーワード'}</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['12']['sodansha_keyword']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>収支状況</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['12']['shushi_jokyo']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'利用中の\n医療/介護/福祉\n制度'}</div>
                                    <p className={style.itemDetail2_largeLine}>{Object.keys(guiData).length > 0 && guiData['12']['riyo_seido']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <p className={style.itemDetail1}>心身の状況</p>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['12']['shinshin_jokyo'])}>
                                        {Object.keys(guiData).length > 0 && guiData['12']['shinshin_jokyo']}
                                    </p>
                                    <PastCaseCopyBlock dispId='12' field='shinshin_jokyo' value={Object.keys(guiData).length > 0 && guiData['12']['shinshin_jokyo']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <p className={style.itemDetail1}>{'社会関係の\n状況'}</p>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['12']['shakaikankei_jokyo'])}>
                                        {Object.keys(guiData).length > 0 && guiData['12']['shakaikankei_jokyo']}
                                    </p>
                                    <PastCaseCopyBlock dispId='12' field='shakaikankei_jokyo' value={Object.keys(guiData).length > 0 && guiData['12']['shakaikankei_jokyo']} />
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={style.dispIdItem} onClick={() => detailDisplay(20)}>
                                {showDetail['20'] ? <ChevronUpIcon className={style.chberonIcon} /> : <ChevronDownIcon className={style.chberonIcon} />}
                                <p>支援計画</p>
                            </div>
                            <ul className={showDetail['20'] ? '' : 'hidden'}>
                                <li className={style.itemTitleBlock}>
                                    <div className={style.itemDetail1}>項目</div>
                                    <p className={style.itemDetail2}>内容</p>
                                    <p className={style.itemDetail3}>コピーする</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'生活環境支援\n必要性'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['20']['seikatsukankyo_shien_hitsuyo'])}>
                                        {Object.keys(guiData).length > 0 && guiData['20']['seikatsukankyo_shien_hitsuyo']}
                                    </p>
                                    <PastCaseCopyBlock dispId='20' field='seikatsukankyo_shien_hitsuyo' value={Object.keys(guiData).length > 0 && guiData['20']['seikatsukankyo_shien_hitsuyo']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'治療支援\n必要性'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['20']['chiryo_shien_hitsuyo'])}>
                                        {Object.keys(guiData).length > 0 && guiData['20']['chiryo_shien_hitsuyo']}
                                    </p>
                                    <PastCaseCopyBlock dispId='20' field='chiryo_shien_hitsuyo' value={Object.keys(guiData).length > 0 && guiData['20']['chiryo_shien_hitsuyo']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'経済的援助\n必要性'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['20']['keizai_enjo_hitsuyo'])}>
                                        {Object.keys(guiData).length > 0 && guiData['20']['keizai_enjo_hitsuyo']}
                                    </p>
                                    <PastCaseCopyBlock dispId='20' field='keizai_enjo_hitsuyo' value={Object.keys(guiData).length > 0 && guiData['20']['keizai_enjo_hitsuyo']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'利用検討\n福祉制度'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['20']['kento_fukushi_seido'])}>
                                        {Object.keys(guiData).length > 0 && guiData['20']['kento_fukushi_seido']}
                                    </p>
                                    <PastCaseCopyBlock dispId='20' field='kento_fukushi_seido' value={Object.keys(guiData).length > 0 && guiData['20']['kento_fukushi_seido']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'支援目標'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['20']['shakaikoken_shien_mokuhyo'])}>
                                        {Object.keys(guiData).length > 0 && guiData['20']['shakaikoken_shien_mokuhyo']}
                                    </p>
                                    <PastCaseCopyBlock dispId='20' field='shakaikoken_shien_mokuhyo' value={Object.keys(guiData).length > 0 && guiData['20']['shakaikoken_shien_mokuhyo']} />
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={style.dispIdItem} onClick={() => detailDisplay(40)}>
                                {showDetail['40'] ? <ChevronUpIcon className={style.chberonIcon} /> : <ChevronDownIcon className={style.chberonIcon} />}
                                <p>支援経過</p>
                            </div>
                            <ul className={showDetail['40'] ? '' : 'hidden'}>
                                <li className={style.itemTitleBlock}>
                                    <div className={style.itemDetail1}>日付</div>
                                    <p className={style.itemDetail2}>内容</p>
                                    <p className={style.itemDetail3}>コピーする</p>
                                </li>
                                {Object.keys(guiData).length > 0 ? (
                                    guiData['40']['enjokeika_list'].length > 0 ? (
                                        guiData['40']['enjokeika_list'].map((value) => (
                                            <li key={`${value['enjo_no']}_container`} className={style.itemDetailBlock}>
                                                <div className={style.itemDetail1}>{value['enjo_date']}</div>
                                                <p className={style.itemDetail2} onClick={() => displayFullText(value['enjo_keika_naiyo'])}>
                                                    {value['enjo_keika_naiyo']}
                                                </p>
                                                <PastCaseCopyBlock dispId='40' field='enjo_keika_naiyo' value={value['enjo_keika_naiyo']} />
                                            </li>
                                        ))
                                    ) : (
                                        <li className={style.itemDetailBlock}>
                                            <div className={style.itemDetail1}></div>
                                            <p className={style.itemDetail2}></p>
                                        </li>
                                    )
                                ) : (
                                    ''
                                )}
                            </ul>
                        </li>
                        <li>
                            <div className={style.dispIdItem} onClick={() => detailDisplay(50)}>
                                {showDetail['50'] ? <ChevronUpIcon className={style.chberonIcon} /> : <ChevronDownIcon className={style.chberonIcon} />}
                                <p>支援終結記録</p>
                            </div>
                            <ul className={showDetail['50'] ? '' : 'hidden'}>
                                <li className={style.itemTitleBlock}>
                                    <div className={style.itemDetail1}>項目</div>
                                    <p className={style.itemDetail2}>内容</p>
                                    <p className={style.itemDetail3}>コピーする</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'援助後の生活'}</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['50']['enjogo_seikatsu']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'支援終結理由'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['50']['shuketsu_riyu'])}>
                                        {Object.keys(guiData).length > 0 && guiData['50']['shuketsu_riyu']}
                                    </p>
                                    <PastCaseCopyBlock dispId='50' field='shuketsu_riyu' value={Object.keys(guiData).length > 0 && guiData['50']['shuketsu_riyu']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'残された課題'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['50']['zan_kadai'])}>
                                        {Object.keys(guiData).length > 0 && guiData['50']['zan_kadai']}
                                    </p>
                                    <PastCaseCopyBlock dispId='50' field='zan_kadai' value={Object.keys(guiData).length > 0 && guiData['50']['zan_kadai']} />
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'連携した機関'}</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['50']['renkei_kikan']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'今後の支援\n中心機関'}</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['50']['kikan_str']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'支援期間'}</div>
                                    <p className={style.itemDetail2}>{Object.keys(guiData).length > 0 && guiData['50']['shien_term']}</p>
                                </li>
                                <li className={style.itemDetailBlock}>
                                    <div className={style.itemDetail1}>{'今回の支援\n自己評価'}</div>
                                    <p className={style.itemDetail2} onClick={() => displayFullText(guiData['50']['konkai_shien_jikohyoka'])}>
                                        {Object.keys(guiData).length > 0 && guiData['50']['konkai_shien_jikohyoka']}
                                    </p>
                                    <PastCaseCopyBlock dispId='50' field='konkai_shien_jikohyoka' value={Object.keys(guiData).length > 0 && guiData['50']['konkai_shien_jikohyoka']} />
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </Div100vh>
    )
}
