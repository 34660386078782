import { useFormContext } from 'react-hook-form'
import { TextArea, FormBlock } from '../atom'

// let PINBlockrender = 0
export default function PersonalInfoInteraction(props) {
    // PINBlockrender++
    // console.log('PINBlockrender', PINBlockrender)
    const {
        register,
        formState: { errors },
    } = useFormContext()
    const contents = [
        <TextArea
            label='当面の対応'
            name='tomentaio'
            id='tomentaio_personal'
            register={register('tomentaio', {})}
            // errorText={errors.tomentaio?.message}
            placeholder='タッチすると拡大表示します'
            expandSize={true}
            pasTable={true}
            printDisplay={{ display: true, countByLine: 30, lines: 5 }}
        />,
    ]
    return (
        <>
            <FormBlock categoryName='当面の対応' formItems={contents} />
        </>
    )
}
