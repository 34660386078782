import { useFormContext } from 'react-hook-form'
import { InputDate, InputText, RadioButton, FormBlock } from '../atom'
import { selectionOptions } from '../json'

// let NCRBlockRender = 0
export default function NewConsultRecept(props) {
    // NCRBlockRender++
    // console.log('NCRBlockRender', NCRBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const todayDate = new Date()
    const uketsuke_hoho_options = selectionOptions.uketsuke_hoho_options
    const contents = [
        <InputText
            label='事例番号'
            name='jirei_no'
            register={register('jirei_no', {})}
            type='text'
            // errorText={errors.jirei_no_etc?.message}
            placeholder=''
            isReadOnly={true}
        />,
        <InputDate
            label='受付日(紹介日)'
            name='uketsuke_date'
            control={control}
            startDate={todayDate}
            errorText={errors.uketsuke_date?.message}
            placeholder='日付を入力してください'
            isRequired={true}
        />,
        <RadioButton
            label='受付方法'
            name='uketsuke_hoho'
            id='uketsuke_hoho_newconsult'
            register={register('uketsuke_hoho', {})}
            options={uketsuke_hoho_options}
            errorText={errors.uketsuke_hoho?.message}
            isRequired={true}
        />,
        <InputText
            label='受付方法(その他)'
            name='uketsuke_hoho_sonota'
            id='uketsuke_hoho_sonota_newconsult'
            register={register('uketsuke_hoho_sonota', {})}
            type='text'
            placeholder='入力してください'
            maxLength='10'
            // errorText={errors.uketsuke_hoho_sonota?.message}
        />,
    ]
    // const uketsuke_hohoWatch = useWatch({ control, name: 'uketsuke_hoho', defaultValue: '' })
    // console.log('uketsuke_hohoWatch', uketsuke_hohoWatch)
    // useEffect(() => {
    //     const receptionArg = {
    //         watchValue: uketsuke_hohoWatch,
    //         targetName: 'uketsuke_hoho_sonota',
    //         targetId: 'uketsuke_hoho_sonota_newconsult',
    //         checkValue: '999',
    //         setValueMethod: setValue,
    //     }
    //     SwitchDisplay(receptionArg)
    // }, [uketsuke_hohoWatch, setValue, setFocus])
    return (
        <>
            <FormBlock categoryName='受付' formItems={contents} />
        </>
    )
}
