import React, { useState, useEffect, useRef, createContext } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { firebase, db, func, auth } from '../func/firebase'
import { useAuthContext } from '../func'
import { Modal, Button } from '../atom'
import { ContactForm } from '../block'

export default function Contact() {
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const { userData } = useAuthContext()
    const schema = yup.object().shape({
        name: yup
            .string()
            .required('氏名欄は必須項目です')
            .matches(/^([^0-9]*)$/, '数字は使用できません'),
        shisetsu: yup.string().required('施設欄は必須項目です'),
        email: yup
            .string()
            // .required('メールアドレスは必須項目です')
            .lowercase()
            .email('正しいメールアドレスを指定してください。'),
        content: yup.string().required('お問い合わせ内容は必須項目です'),
    })
    const methods = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        // defaultValues: { uid: '', password: '' },
    })
    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            methods.setValue('shisetsu', userData.shisetsu_name)
        }
    }, [methods, userData])

    const onSubmit = (data) => {
        // const dataJson = JSON.stringify(data)
        // console.log({ data, dataJson })
        const postToCf = () => {
            const sendData = data
            if (Object.keys(userData).length > 0) {
                sendData['shisetsu_id'] = userData.shisetsu_id
                sendData['user_id'] = userData.user_id
            }
            const functions = firebase.functions('asia-northeast2')
            const mailFunc = functions.httpsCallable('SendMail')
            const response = mailFunc(data)
                .then((res) => {
                    const msg = res.data.message
                    console.log('res from SendMailFunc:', res)
                    console.log('res Message', msg)
                    return msg
                })
                .catch((error) => {
                    console.error('SendMailFunc Error:', error)
                    return new Error(error)
                })
            return response
        }
        const feedBackFromFb = async () => {
            //モーダルウインドウComponent(進行状況を示す)にプロパティを渡して表示
            setShowModal({
                show: true,
                text: '送信中...',
                state: 'pending',
            })
            await postToCf()
                .then((res) => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            // const flg = res === 'FirestoreWriteOK'
                            const flg = true
                            // console.log('res judge', flg)
                            if (flg) {
                                // console.log('res is OK')
                                resolve(res)
                            } else {
                                // console.error('res is NG')
                                reject(res)
                            }
                        }, 1500)
                    })
                })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        console.log('送信完了')
                        setShowModal({
                            show: true,
                            text: '送信しました',
                            state: 'fulfilled',
                        })
                        resolve()
                        // reject()
                    })
                })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            setShowModal({
                                show: false,
                            })
                            resolve()
                            // reject()
                        }, 1500)
                    })
                })
                .catch((error) => {
                    console.error('res is NG :', error)
                    setShowModal({
                        show: true,
                        text: '送信できませんでした',
                        errorText: error,
                        state: 'rejected',
                    })
                    setTimeout(() => {
                        setShowModal({
                            show: false,
                        })
                        // onFocus()
                    }, 5000)
                })
        }
        // postToCf()
        feedBackFromFb()
    }
    return (
        <div className='w-full sm:max-w-2xl'>
            <Modal showModal={showModal} />
            <div className='flex flex-col items-center'>
                {/* <FormProvider {...methods}>
                    <form className='w-full grid mb-2 sm:mb-4 mx-10 sm:mx-8 px-8 pt-1 pb-2' name='ContactForm' onSubmit={methods.handleSubmit(onSubmit)}>
                        <ContactForm />
                        <div className='py-2 place-self-end'>
                            <Button type='submit' value='送信' className='mt-4' />
                        </div>
                    </form>
                </FormProvider> */}
                <div className='w-full'>
                    <div className='mt-4 sm:mt-8 mb-4 sm:mb-6 mx-10 sm:mx-8 px-4 pt-6 pb-4 rounded-lg border border-gray-400 bg-gray-50 text-gray-700'>
                        <p className='pb-2 text-2xl sm:text-3xl font-bold border-b border-gray-500'>お問い合わせ(TEL)</p>
                        <p className='p-2 text-sm sm:text-xl whitespace-pre-wrap'>{`社会福祉法人 大阪府社会福祉協議会\n施設福祉部 社会貢献推進室`}</p>
                        <a className='p-2 text-sm sm:text-xl text-teal-600 underline' href={`tel:06-6762-9488`}>
                            06-6762-9488
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
