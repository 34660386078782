import PencilIconO from '@heroicons/react/outline/PencilIcon'
import PencilIconS from '@heroicons/react/solid/PencilIcon'
import SearchIconO from '@heroicons/react/outline/SearchIcon'
import SearchIconS from '@heroicons/react/solid/SearchIcon'
import InformationCircleIconO from '@heroicons/react/outline/InformationCircleIcon'
import InformationCircleIconS from '@heroicons/react/solid/InformationCircleIcon'

export default function Footer({ tabIndex, setTabIndex }) {
    const styleDefaultTabPanel = `
        max-h-10 sm:max-h-20 h-full flex flex-row flex-1 justify-center items-center
        pt-1 break-all border-t-4 border-opacity-0
        text-center font-bold text-gray-400
    `
    const styleActiveTabPanel = `
        max-h-10 sm:max-h-20 flex flex-row flex-1 justify-center items-center
        pt-1 break-all border-t-4 border-teal-400
        text-center font-bold text-teal-600
        transition duration-400 ease-in-out
    `
    const styleTabIcon = `w-6 h-6 mb-1 sm:w-10 sm:h-10`
    return (
        <>
            <footer className='w-full h-full max-h-10 sm:max-h-20 bg-gray-50 border-t border-gray-50'>
                <div className='w-full h-full row-start-3 row-span-1 flex flex-row flex-wrap bg-gray-50 shadow-lg z-50'>
                    <div className={tabIndex === 0 ? styleActiveTabPanel : styleDefaultTabPanel} onClick={() => setTabIndex(0)}>
                        {tabIndex === 0 ? <PencilIconS className={styleTabIcon} /> : <PencilIconO className={styleTabIcon} />}
                        {/* <p className='w-12 h-6 flex justify-center items-center text-sm'>入力</p> */}
                    </div>
                    <div className={tabIndex === 1 ? styleActiveTabPanel : styleDefaultTabPanel} onClick={() => setTabIndex(1)}>
                        {tabIndex === 1 ? <SearchIconS className={styleTabIcon} /> : <SearchIconO className={styleTabIcon} />}
                        {/* <p className='w-18 h-6 pl-1 flex justify-center items-center text-sm'>類似事例</p> */}
                    </div>
                    {/* <div className={tabIndex === 2 ? styleActiveTabPanel : styleDefaultTabPanel} onClick={() => setTabIndex(2)}>
                        {tabIndex === 2 ? <InformationCircleIconS className={styleTabIcon} /> : <InformationCircleIconO className={styleTabIcon} />}
                        <p className='w-18 h-6 pl-1 flex justify-center items-center text-sm'>社会資源</p>
                    </div> */}
                </div>
            </footer>
        </>
    )
}
