import { useEffect, useState, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { InputText, FormBlock } from '../atom'

// let NCMBlockRender = 0
export default function LoginForm() {
    // NCMBlockRender++
    const style = {
        passContainer:
            'flex items-center justify-between pr-2 rounded-lg text-lg sm:text-xl border border-gray-400 bg-gray-200 appearance-none focus-within:outline-none focus-within:bg-white focus-within:ring-2 focus-within:ring-blue-800 focus-within:border-transparent',
        passContainerError:
            'flex items-center justify-between pr-2 rounded-lg text-lg sm:text-xl border border-gray-400 bg-gray-200 appearance-none focus-within:outline-none focus-within:bg-white focus-within:ring-2 focus-within:ring-blue-800 focus-within:border-transparent ring-2 ring-red-500 forcus:none',
        label: 'mb-2 text-lg sm:text-2xl text-gray-600',
        pwInput: 'w-48 p-2 border-none focus:ring-0 focus:outline-none bg-opacity-0 bg-white',
        eyeIcon: 'w-6 h-6 text-gray-500',
        error: 'flex flex-row items-center justify-end my-2 text-right text-base font-bold text-red-400',
    }
    const {
        register,
        formState: { errors },
    } = useFormContext()
    const [passDisp, setPassDisp] = useState(false)

    return (
        <div className='w-64 sm:max-w-4xl flex flex-col'>
            {/* <>
                <InputText label='ログインID' name='uid' register={register('uid', {})} type='text' errorText={errors.uid?.message} placeholder='' inputMode='email' />
                <InputText label='パスワード' name='password' register={register('password', {})} type='password' errorText={errors.password?.message} placeholder='' inputMode='email' />
            </> */}
            <>
                <InputText label='ログインID' name='uid' register={register('uid', {})} type='text' errorText={errors.uid?.message} placeholder='' autoComplete='off' inputMode='email' />
                <label htmlFor='password' className={style.label}>
                    パスワード
                </label>
                <div className={!!errors.password?.message ? style.passContainerError : style.passContainer}>
                    <input
                        name='password'
                        {...register('password', {})}
                        type='text'
                        className={passDisp ? `${style.pwInput}` : `${style.pwInput} passwordMask`}
                        inputMode='email'
                        placeholder=''
                        autoComplete='off'
                    />
                    <div onClick={() => setPassDisp(!passDisp)}>{passDisp ? <EyeOffIcon className={style.eyeIcon} /> : <EyeIcon className={style.eyeIcon} />}</div>
                </div>
                {!!errors.password?.message && (
                    <span className={style.error}>
                        <ExclamationCircleIcon className='w-5 h-5' />
                        <p>{errors.password?.message}</p>
                    </span>
                )}
            </>
        </div>
    )
}
