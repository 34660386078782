import { useEffect } from 'react'
import { useFormContext, useWatch, useFormState } from 'react-hook-form'
import { InputDate, InputText } from '.'

let AIBTARender = 0
export default function AutoInputBirthdayToAge({ name, nameBirthday, nameAge }) {
    AIBTARender++
    // console.log('AIBTARender', AIBTARender)
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        setFocus,
        control,
    } = useFormContext()
    const { dirtyFields } = useFormState({ control })
    const nameBirthdayWatch = useWatch({ control, name: nameBirthday, defaultValue: '' })
    // const nameBirthdayValue = nameBirthdayWatch ? getValues(nameBirthday) : ''
    // const nameAgeValue = getValues(nameAge) ? getValues(nameAge) : ''
    // console.log(nameAgeValue, typeof nameAgeValue)
    useEffect(() => {
        const calcAge = () => {
            const todayDate = new Date()
            let age = todayDate.getFullYear() - nameBirthdayWatch.getFullYear()
            const thisYearBirthDay = new Date(todayDate.getFullYear(), nameBirthdayWatch.getMonth(), nameBirthdayWatch.getDate())
            if (todayDate < thisYearBirthDay) {
                age--
            }
            return age
        }
        const birthdayFlg = Boolean(nameBirthdayWatch) && dirtyFields[nameBirthday] === true
        // console.log('Birthday Value: ', nameBirthdayWatch)
        // console.log('Birthday is Dirty?', dirtyFields[nameBirthday])
        // console.log('Birthday Flg?', birthdayFlg)
        if (birthdayFlg) {
            setValue(nameAge, calcAge())
        }
    }, [nameBirthday, nameAge, nameBirthdayWatch, setValue, dirtyFields])
    return (
        <div id={`${name}_parent`}>
            <InputDate
                label='生年月日'
                name={nameBirthday}
                control={control}
                // startDate={new Date(1989, 0, 1)}
                placeholder='生年月日を入力してください'
                subText='※生年月日を入れると年齢が自動入力されます'
                // isRequired={true}
                errorText={errors[nameBirthday]?.message}
            />
            <InputText
                label='年齢'
                name={nameAge}
                type='text'
                register={register(nameAge, {})}
                isRequired={true}
                // isReadOnly={true}
                errorText={errors[nameAge]?.message}
                // placeholder='※直接は入力できません'
                inputMode='numeric'
            />
        </div>
    )
}
