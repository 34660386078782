import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SelectBox, CheckBox, InputText, FormBlock } from '../atom'
import { SwitchDisplay } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let SptRCBlockRender = 0
export default function SupportRecordCoop(props) {
    // SptRCBlockRender++
    // console.log('SptRCBlockRender', SptRCBlockRender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const renkei_kikan_options = selectionOptions.shokai_keiro_options
    const renkei_kikan_gyosei_options = selectionOptions.shokai_keiro_gyosei_options
    const renkei_kikan_defaultValue = renkei_kikan_options.map((v) => false)
    const renkei_kikan_watch = useWatch({
        control,
        name: 'renkei_kikan',
        defaultValue: renkei_kikan_defaultValue,
    })
    // const renkei_gyosei_watch = useWatch({ control, name: 'renkei_kikan_gyosei', defaultValue: '' })
    useEffect(() => {
        // console.log('renkei_kikan_watch', renkei_kikan_watch)
        // console.log('renkei_gyosei_watch', renkei_gyosei_watch)
        // const renkei_arg = {
        //     watchValue: renkei_kikan_watch,
        //     targetName: 'renkei_kikan_sonota',
        //     targetId: 'renkei_kikan_sonota',
        //     checkValue: '999',
        //     // focusMethod: setFocus, //setFocusできないため省略
        //     setValueMethod: setValue,
        // }
        const renkei_office_arg = {
            watchValue: renkei_kikan_watch,
            targetName: 'renkei_kikan_gyosei',
            targetId: 'renkei_kikan_gyosei',
            checkValue: '002',
            // focusMethod: setFocus,
            setValueMethod: setValue,
        }
        const renkei_office_comment_arg = {
            watchValue: renkei_kikan_watch,
            targetName: 'renkei_kikan_gyosei_free',
            targetId: 'renkei_kikan_gyosei_free',
            checkValue: '002',
            // focusMethod: setFocus,
            setValueMethod: setValue,
        }
        // SwitchDisplay(renkei_arg)
        SwitchDisplay(renkei_office_arg)
        SwitchDisplay(renkei_office_comment_arg)
    }, [renkei_kikan_watch, setValue])
    const contents = [
        <CheckBox
            label='連携した機関(複数選択可)'
            name='renkei_kikan'
            id='renkei_kikan'
            options={renkei_kikan_options}
            // errorText={errors.renkei_kikan?.message}
            // isRequired={true}
        />,
        <SelectBox
            label='連携した機関-行政-担当部署'
            name='renkei_kikan_gyosei'
            id='renkei_kikan_gyosei'
            register={register('renkei_kikan_gyosei', {})}
            options={renkei_kikan_gyosei_options}
            firstOption=''
            firstValue='000'
            // isRequired={true}
            // errorText={errors.renkei_kikan_gyosei?.message}
        />,
        <InputText
            type='text'
            label='連携した機関-行政-担当部署-その他'
            name='renkei_kikan_gyosei_free'
            id='renkei_kikan_gyosei_free'
            register={register('renkei_kikan_gyosei_free', {})}
            placeholder=''
            maxLength='100'
            // errorText={errors.renkei_kikan_gyosei_free?.message}
        />,
        <InputText
            label='連携した機関-その他'
            name='renkei_kikan_sonota'
            id='renkei_kikan_sonota'
            register={register('renkei_kikan_sonota', {})}
            type='text'
            placeholder=''
            maxLength='500'
            // errorText={errors.renkei_kikan_sonota?.message}
        />,
    ]
    return (
        <>
            <FormBlock categoryName='連携した機関' formItems={contents} />
        </>
    )
}
