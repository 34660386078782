import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SelectBox, InputText, TextArea, FormBlock } from '../atom'
import { SwitchDisplay } from '../func/CommonFunc'
import { selectionOptions } from '../json'

// let PIIBlockrender = 0
export default function PersonalInfoIntroduction(props) {
    // PIIBlockrender++
    // console.log('PIIBlockrender', PIIBlockrender)
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
        setFocus,
    } = useFormContext()
    const todayDate = new Date()
    const shokai_keiro_options = selectionOptions.shokai_keiro_options
    const shokai_keiro_gyosei_options = selectionOptions.shokai_keiro_gyosei_options
    const contents = [
        <SelectBox
            label='紹介経路'
            name='shokai_keiro'
            id='shokai_keiro_personal'
            register={register('shokai_keiro', {})}
            errorText={errors.shokai_keiro?.message}
            options={shokai_keiro_options}
            firstOption='--- 選択してください ---'
            placeholder=''
            isRequired={true}
        />,
        <SelectBox
            label='紹介経路-行政-担当部署'
            name='shokai_keiro_gyosei'
            id='shokai_keiro_gyosei_personal'
            register={register('shokai_keiro_gyosei', {})}
            options={shokai_keiro_gyosei_options}
            firstOption=''
            firstValue='000'
            // isRequired={true}
            // errorText={errors.shokai_keiro_gyosei?.message}
        />,
        <InputText
            type='text'
            label='紹介経路-行政-担当部署-その他'
            name='shokai_keiro_gyosei_free'
            id='shokai_keiro_gyosei_free_personal'
            register={register('shokai_keiro_gyosei_free', {})}
            placeholder=''
            maxLength='100'
            // errorText={errors.shokai_keiro_gyosei_free?.message}
        />,
        <InputText
            label='紹介経路-その他'
            name='shokai_keiro_sonota'
            id='shokai_keiro_sonota_personal'
            register={register('shokai_keiro_sonota', {})}
            type='text'
            placeholder=''
            maxLength='100'
            // errorText={errors.shokai_keiro_sonota?.message}
        />,
        <InputText
            label='紹介者'
            name='shokaisha'
            register={register('shokaisha', {})}
            type='text'
            // errorText={errors.shokaisha?.message}
            placeholder='氏名'
            maxLength='80'
        />,
        <TextArea
            label='紹介内容'
            name='shokai_naiyo'
            id='shokai_naiyo_personal'
            register={register('shokai_naiyo', {})}
            // errorText={errors.shokai_naiyo?.message}
            placeholder='タッチすると拡大表示します'
            // isRequired={true}
            expandSize={true}
            printDisplay={{ display: true, countByLine: 30, lines: 8 }}
        />,
    ]

    const introducerRouteWatch = useWatch({ control, name: 'shokai_keiro', defaultValue: '' })
    // const introducerOfficeWatch = useWatch({ control, name: 'shokai_keiro_gyosei', defaultValue: '' })

    // useEffect(() => {
    //     // const routeCommentArg = {
    //     //     watchValue: introducerRouteWatch,
    //     //     targetName: 'shokai_keiro_sonota',
    //     //     targetId: 'shokai_keiro_sonota_personal',
    //     //     checkValue: '999',
    //     //     // focusMethod: setFocus,
    //     //     setValueMethod: setValue,
    //     // }
    //     const officeArg = {
    //         watchValue: introducerRouteWatch,
    //         targetName: 'shokai_keiro_gyosei',
    //         targetId: 'shokai_keiro_gyosei_personal',
    //         checkValue: '002',
    //         // focusMethod: setFocus,
    //         setValueMethod: setValue,
    //     }
    //     const officeCommentArg = {
    //         watchValue: introducerRouteWatch,
    //         targetName: 'shokai_keiro_gyosei_free',
    //         targetId: 'shokai_keiro_gyosei_free_personal',
    //         checkValue: '002',
    //         // focusMethod: setFocus,
    //         setValueMethod: setValue,
    //     }
    //     // SwitchDisplay(routeCommentArg)
    //     SwitchDisplay(officeArg)
    //     SwitchDisplay(officeCommentArg)
    // }, [introducerRouteWatch, setFocus, setValue])

    return (
        <>
            <FormBlock categoryName='紹介' formItems={contents} />
        </>
    )
}
