import React, { useState, useEffect, useRef, createContext, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import clonedeep from 'lodash.clonedeep'
import { firebase, db, func } from '../func/firebase'
import { useAuthContext } from '../func'
import { inputData, checkKeyDown, insertByDispId, replaceLineCode, checkBoxToData, dateFormToData, getCurrentDate, dataForCf, postToCf, feedBackDisplay } from '../func/CommonFunc'
import { useFbDataContext } from '../page'
import { SupportContentsDetail, SupportContentsMedical, SupportContentsStatus } from '../block'
import { Dialog, Modal, Button } from '../atom'
import { defaultValues } from '../json'

// let SptContentsCompoRender = 0
export default function SupportContents() {
    // SptContentsCompoRender++
    // console.log('SptContentsCompoRender', SptContentsCompoRender)
    const { currentUser, userData } = useAuthContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const { fbData, setFbData, setShowDetail, showDetail, setShowEdit } = useFbDataContext()
    const schema = yup.object().shape({
        job: yup.string().max(500, '500文字以下にしてください'),
        shunyu_shishutsu_free: yup.string().max(500, '500文字以下にしてください'),
    })
    const methods = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: defaultValues.supportContents,
    })
    const refDataLoad = useRef(true)
    const refShowDetail = useRef(null)
    useEffect(() => {
        // console.log('useEffect Reloaded in SupportContents')
        // console.log('fbData in SupportContents', fbData)
        // console.log('ref?', refDataLoad)
        const open = showDetail.index === 12
        const close = showDetail.index !== 12 && refShowDetail.current === 12
        if (refDataLoad.current && open) {
            // console.log('data input in SupportContents')
            const dispData = { ...fbData['12'], ...fbData['102'] }
            // console.log('disp10', dispData)
            inputData(dispData, methods.setValue)
            if (dispData['update_date'] !== '') {
                refDataLoad.current = false
            }
        } else if (close) {
            // console.log('close and setFbData in SupportContents')
            // const cloneData = clonedeep(fbData)
            // console.log('cloneData in SupportContents', cloneData)
            // const formData = methods.getValues()
            //formname重複避け用データを挿入(「相談者種別」「相談者種別-その他」「生保区分」)
            // formData['sodansha_shubetsu'] = formData['sodansha_shubetsu_sptc']
            // formData['sodansha_shubetsu_sonota'] = formData['sodansha_shubetsu_sonota_sptc']
            // formData['seiho_kbn'] = formData['seiho_kbn_sptc']
            // console.log('formData in SupportContents', formData)
            // cloneData['12'] = insertByDispId('12', formData, cloneData)
            // cloneData['102'] = insertByDispId('102', formData, cloneData)
            // console.log('cloneData editted in SupportContents', cloneData)
            // setFbData(cloneData)
            refDataLoad.current = true
        }
    }, [fbData, setFbData, methods, showDetail])
    useEffect(() => {
        // console.log('showDetail in  SupportContents :', showDetail)
        refShowDetail.current = showDetail.index
    }, [showDetail])
    // const resetText = () => {
    //     methods.reset()
    // }
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backToShowDetail = useCallback(() => {
        const cancelEdit = () => {
            // console.log('yes')
            const pathName = history.location.pathname
            history.replace({ pathname: pathName })
            window.location.reload()
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        setShowDialog({
            ...showDialog,
            show: true,
            message: '相談の詳細・編集画面へ戻り、編集中の内容は消去します。\nよろしいですか？',
            doYes: cancelEdit,
            doNo: hideDialog,
            textYes: 'はい',
            textNo: 'いいえ',
        })
    }, [history, showDialog])
    //ユーザーデータが無い時の処理(送信ボタンを押せなくする、管理者権限閲覧時も同様)
    const [sendable, setSendable] = useState({ send: false, reasonText: '' })
    useEffect(() => {
        const isUserId = 'user_id' in userData
        const isShisetsuId = 'shisetsu_id' in userData
        // console.log({ isUserId, isShisetsuId })
        if (!isUserId) {
            console.error('userIdがありません')
            setSendable({ send: false, reasonText: 'ユーザーIDが無いため' })
        } else if (!isShisetsuId) {
            console.error('shisetsuIdがありません')
            setSendable({ send: false, reasonText: '施設IDが無いため' })
        } else if (isUserId && isShisetsuId) {
            // console.log('userData in EditConsult', userData)
            const uFacilityId = userData.shisetsu_id
            const uKengen = userData.kengen
            const isMatchFacilityId = fbData['10']['shisetsu_id'] === uFacilityId
            const isAdmin = ['1', '2'].includes(uKengen)
            // console.log({ uFacilityId, uKengen, isMatchFacilityId, isAdmin })
            if (isAdmin && !isMatchFacilityId) {
                setSendable({ send: false, reasonText: '確認用権限のため' })
            } else {
                setSendable({ send: true, reasonText: '' })
            }
        }
    }, [userData, fbData])
    const unSendable = useCallback(
        (text) => {
            const hideDialog = () => {
                setShowDialog({ ...showDialog, show: false })
            }
            const reload = () => {
                const pathName = history.location.pathname
                history.replace({ pathname: pathName })
                window.location.reload()
            }
            const message = `${text}現在送信できません\nページをリロードするか(入力内容は消去されます)\nウインドウを閉じてしばらくお待ち下さい\n待っても送信できない時はログインし直してみてください`
            setShowDialog({
                ...showDialog,
                show: true,
                message: message,
                doYes: reload,
                doNo: hideDialog,
                textYes: 'リロード',
                textNo: '閉じる',
            })
        },
        [showDialog, history]
    )
    const onSubmit = (data) => {
        // const cloneData = JSON.parse(JSON.stringify(data))
        const cloneData = clonedeep(data)
        // console.log('FormData', cloneData)
        const dataSptContents = { ...fbData['12'], ...fbData['102'] }
        Object.assign(dataSptContents, cloneData)
        //マスタデータ(ユーザーID,施設ID,画面ID,処理区分)を追加
        dataSptContents['user_id'] = userData.user_id
        dataSptContents['shisetsu_id'] = userData.shisetsu_id
        dataSptContents['disp_id'] = '12'
        dataSptContents['shori_div'] = '1'
        //事例番号は消去
        // dataSptContents['jirei_no'] = ''
        delete dataSptContents['jirei_no']
        //formname重複避け用データを挿入(「相談者種別」「相談者種別-その他」「生保区分」)
        dataSptContents['sodansha_shubetsu'] = dataSptContents['sodansha_shubetsu_sptc']
        dataSptContents['sodansha_shubetsu_sonota'] = dataSptContents['sodansha_shubetsu_sonota_sptc']
        dataSptContents['seiho_kbn'] = dataSptContents['seiho_kbn_sptc']
        //改行コードを置換
        //画面12
        dataSptContents['job'] = replaceLineCode(dataSptContents['job'])
        dataSptContents['shunyu_shishutsu_free'] = replaceLineCode(dataSptContents['shunyu_shishutsu_free'])
        dataSptContents['riyochu_seido_biko'] = replaceLineCode(dataSptContents['riyochu_seido_biko'])
        dataSptContents['shinshin_jokyo'] = replaceLineCode(dataSptContents['shinshin_jokyo'])
        dataSptContents['shakaikankei_jokyo'] = replaceLineCode(dataSptContents['shakaikankei_jokyo'])
        //日付を追加,書き換え(obj=>str)
        //画面共通
        dataSptContents['update_date'] = getCurrentDate()
        //CheckBoxデータを整形 元のプロパティを消去
        //画面102
        const shubetsuItems = dataSptContents['sodansha_shubetsu']
        dataSptContents['sodansha_shubetsu_list'] = checkBoxToData(shubetsuItems, 'sodansha_shubetsu')
        delete dataSptContents['sodansha_shubetsu']
        //画面12
        //相談者キーワード(整形の際に0埋めをしない)
        const keyWordItems = dataSptContents['sodansha_keyword']
        dataSptContents['sodansha_keyword_list'] = checkBoxToData(keyWordItems, 'sodansha_keyword', false)
        delete dataSptContents['sodansha_keyword']
        const shushiItems = dataSptContents['shushi_jokyo']
        dataSptContents['shushi_jokyo_list'] = checkBoxToData(shushiItems, 'shushi_jokyo')
        delete dataSptContents['shushi_jokyo']
        //重複避けデータを削除
        delete dataSptContents['sodansha_shubetsu_sptc']
        delete dataSptContents['seiho_kbn_sptc']
        delete dataSptContents['seiho_kbn_shushi']
        delete dataSptContents['sodansha_shubetsu_sonota_sptc']
        delete dataSptContents['todouhuken']
        console.log('disp12 SendData', dataSptContents)
        const response = postToCf(dataSptContents)
        feedBackDisplay(response, setShowModal)
    }
    return (
        <div className='w-full flex flex-row items-center'>
            <Modal showModal={showModal} />
            <Dialog showDialog={showDialog} />
            <FormProvider {...methods}>
                <form
                    className='w-full mb-4 px-2 sm:max-w-2xl sm:mx-8 flex flex-col items-center'
                    name='SupportContentsForm'
                    onSubmit={methods.handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    <SupportContentsDetail />
                    <SupportContentsMedical />
                    <SupportContentsStatus />
                    <div className='pt-6 pb-4 max-w-xl ml-auto flex flex-col items-end sm:flex-row sm:justify-end flex-wrap gap-x-4 gap-y-3'>
                        {sendable.send ? <Button type='submit' value='送信' /> : <Button type='button' value='送信' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />}
                        <Button type='button' value='キャンセル' onClick={backToShowDetail} outline={true} />
                    </div>
                </form>
                {/* <DevTool control={methods.control} /> */}
            </FormProvider>
        </div>
    )
}
