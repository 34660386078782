import React from 'react'
import { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useForm, FormProvider, useWatch, useFieldArray, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { DevTool } from '@hookform/devtools'
import clonedeep from 'lodash.clonedeep'
import { firebase, db, func } from '../func/firebase'
import { useAuthContext } from '../func'
import { inputData, checkKeyDown, insertByDispId, getCurrentDate, dateFormToData, replaceLineCode, dataForCf, postToCf, feedBackDisplay } from '../func/CommonFunc'
import { useFbDataContext } from '../page'
import { SupportProcessInput } from '../block'
import { Dialog, Modal, Button } from '../atom'

// let SptPLCompoRender = 0
export default function SupportProcess({ number }) {
    // SptPLCompoRender++
    // console.log('SptPLCompoRender', SptPLCompoRender)
    const { currentUser, userData } = useAuthContext()
    const { fbData, setFbData, showDetail, setShowDetail, showEdit, setShowEdit } = useFbDataContext()
    const [showModal, setShowModal] = useState({
        show: false,
        text: '',
        state: '',
    })
    const methods = useForm({
        // mode: 'onBlur',
        defaultValues: {
            enjo_no: '',
            enjo_date: '',
            enjo_keika_naiyo: '',
        },
    })
    const style = {
        form: 'w-full mb-4 px-2 sm:max-w-2xl sm:mx-8 flex flex-col items-center',
        container: 'mt-4 w-full p-2 grid grid-cols-1 rounded-lg border border-gray-400 bg-gray-50',
        title: 'flex justify-start items-center text-left p-2 mb-4 border-b border-gray-500 text-2xl sm:text-3xl font-bold text-gray-700  break-all',
        label: 'col-span-12 mb-2 text-lg sm:text-2xl text-gray-600',
        listContainer: 'w-full h-40 grid grid-cols-16 grid-rows-6 mb-4 border border-gray-400 rounded-2xl bg-gray-50 ',
        ButtonContainer: 'pt-6 pb-4 max-w-xl ml-auto flex flex-col items-end sm:flex-row sm:justify-end flex-wrap gap-x-4 gap-y-3',
        itemBoxMove: 'col-start-1 row-start-1 col-span-2 row-end-7 rounded-l-2xl flex flex-col justify-between items-center bg-gray-300',
        itemBoxAddDel: 'col-start-14 row-start-1 col-span-3 row-end-7 flex flex-col justify-between items-center p-2 border-l border-gray-400 rounded-r-2xl',
        itemBoxNo: 'hidden col-start-3 col-end-14 row-start-1 row-end-3 px-2',
        itemBoxDate: 'col-start-3 col-end-14 row-start-1 row-end-3 px-2',
        itemBoxText: 'col-start-3 col-end-14 row-start-3 row-end-7 p-2',
        itemMove: 'text-xs sm:text-sm text-center',
        itemAddDel: 'text-xs sm:text-sm text-center',
        Chevron: 'w-8 h-8 sm:w-10 sm:h-10',
        Add: 'w-8 h-8 sm:w-10 sm:h-10 text-teal-600',
        Del: 'w-8 h-8 sm:w-10 sm:h-10',
        Close: 'absolute top-4 right-2 w-12 h-12 sm:w-16 sm:h-16 text-gray-500 bg-white rounded-full cursor-pointer',
        inputNo: 'w-full p-0 font-bold sm:text-2xl text-center text-gray-500 bg-transparent border-0 focus:border-0 focus:ring-0',
        textAreaNomal: 'w-full h-full pt-2 pl-1 rounded-lg text-sm sm:text-base bg-transparent border border-gray-400 appearance-none',
        textAreaLarge: 'w-full lg:w-3/5 h-5/6 p-4 rounded-lg border-2 border-gray-500 sm:text-2xl sm:text-4xl leading-relaxed sm:leading-loose text-gray-700 bg-gray-100',
        expandTextAreaParent: 'fixed flex justify-end inset-0 w-screen h-screen px-4 pb-4 pt-8 bg-opacity-50 bg-gray-800 z-50',
        inputDate:
            'w-full flex flex-row items-center p-0text-sm sm:text-lg border-0 border-b border-gray-400 appearance-none focus-within:outline-none focus-within:bg-white focus-within:ring-2 focus-within:ring-blue-800 focus-within:border-transparent',
    }
    const refDataLoad = useRef(true)
    const refShowDetail = useRef(null)
    useEffect(() => {
        // console.log('useEffect Reloaded in SupportProcess')
        // console.log('fbData in SupportProcess', fbData)
        // console.log('ref?', refDataLoad)
        const open = showDetail.index === 40
        const close = showDetail.index !== 40 && refShowDetail.current === 40
        if (refDataLoad.current && open) {
            // console.log('data input in SupportProcess')
            const dispData = { ...fbData['40'] }
            // console.log('disp40', dispData)
            // console.log('sptProcessNo', number)
            let enjoNumber = ''
            if (number === 'add') {
                const nums = fbData['40']['enjokeika_list'].map((v) => Number(v['enjo_no']))
                const maxNum = Math.max(...nums)
                // console.log({ nums, maxNum })
                enjoNumber = nums.length > 0 ? String(maxNum + 1) : '1'
            } else {
                enjoNumber = number
            }
            const index = dispData['enjokeika_list'].findIndex((ele) => ele['enjo_no'] === enjoNumber)
            const enjoData = index !== -1 ? dispData['enjokeika_list'][index] : { enjo_no: enjoNumber }
            delete enjoData['enjo_display']
            // console.log('enjoData', enjoData)
            inputData(enjoData, methods.setValue)
            if (dispData['update_date'] !== '') {
                refDataLoad.current = false
            }
        } else if (close) {
            // console.log('close and setFbData in SupportProcess')
            // const cloneData = clonedeep(fbData)
            // console.log('cloneData in SupportProcess', cloneData)
            // const formData = methods.getValues()
            // console.log('formData in SupportProcess', formData)
            // cloneData['40'] = insertByDispId('40', formData, cloneData)
            // console.log('cloneData editted in SupportProcess', cloneData)
            // setFbData(cloneData)
            refDataLoad.current = true
        }
    }, [fbData, setFbData, showDetail, methods.setValue, number])
    useEffect(() => {
        // console.log('showDetail in  SupportProcess :', showDetail)
        refShowDetail.current = showDetail.index
    }, [showDetail])
    const history = useHistory()
    const [showDialog, setShowDialog] = useState({
        show: false,
        text: '',
        doYes: '',
        doNo: '',
    })
    const backToShowDetail = useCallback(() => {
        const cancelEdit = () => {
            // console.log('yes')
            const pathName = history.location.pathname
            history.replace({ pathname: pathName })
            window.location.reload()
        }
        const hideDialog = () => {
            setShowDialog({ ...showDialog, show: false })
        }
        setShowDialog({
            ...showDialog,
            show: true,
            message: '相談の詳細・編集画面へ戻り、編集中の内容は消去します。\nよろしいですか？',
            doYes: cancelEdit,
            doNo: hideDialog,
            textYes: 'はい',
            textNo: 'いいえ',
        })
    }, [history, showDialog])
    //ユーザーデータが無い時の処理(送信ボタンを押せなくする、管理者権限閲覧時も同様)
    const [sendable, setSendable] = useState({ send: false, reasonText: '' })
    useEffect(() => {
        const isUserId = 'user_id' in userData
        const isShisetsuId = 'shisetsu_id' in userData
        // console.log({ isUserId, isShisetsuId })
        if (!isUserId) {
            console.error('userIdがありません')
            setSendable({ send: false, reasonText: 'ユーザーIDが無いため' })
        } else if (!isShisetsuId) {
            console.error('shisetsuIdがありません')
            setSendable({ send: false, reasonText: '施設IDが無いため' })
        } else if (isUserId && isShisetsuId) {
            // console.log('userData in EditConsult', userData)
            const uFacilityId = userData.shisetsu_id
            const uKengen = userData.kengen
            const isMatchFacilityId = fbData['10']['shisetsu_id'] === uFacilityId
            const isAdmin = ['1', '2'].includes(uKengen)
            // console.log({ uFacilityId, uKengen, isMatchFacilityId, isAdmin })
            if (isAdmin && !isMatchFacilityId) {
                setSendable({ send: false, reasonText: '確認用権限のため' })
            } else {
                setSendable({ send: true, reasonText: '' })
            }
        }
    }, [userData, fbData])
    const unSendable = useCallback(
        (text) => {
            const hideDialog = () => {
                setShowDialog({ ...showDialog, show: false })
            }
            const reload = () => {
                const pathName = history.location.pathname
                history.replace({ pathname: pathName })
                window.location.reload()
            }
            const message = `${text}現在送信できません\nページをリロードするか(入力内容は消去されます)\nウインドウを閉じてしばらくお待ち下さい\n待っても送信できない時はログインし直してみてください`
            setShowDialog({
                ...showDialog,
                show: true,
                message: message,
                doYes: reload,
                doNo: hideDialog,
                textYes: 'リロード',
                textNo: '閉じる',
            })
        },
        [showDialog, history]
    )
    const onSubmit = (data, shori_div) => {
        // const cloneData = JSON.parse(JSON.stringify(data))
        const cloneData = clonedeep(data)
        console.log('FormData', cloneData)
        const dataSptProcess = { ...fbData['40'] }
        Object.assign(dataSptProcess, cloneData)
        //マスタデータ(ユーザーID,施設ID,画面ID,処理区分)を追加
        dataSptProcess['user_id'] = userData.user_id
        dataSptProcess['shisetsu_id'] = userData.shisetsu_id
        dataSptProcess['disp_id'] = '40'
        dataSptProcess['shori_div'] = shori_div
        //事例番号は消去
        // dataSptProcess['jirei_no'] = ''
        delete dataSptProcess['jirei_no']
        //日付を追加,書き換え(obj=>str)
        //画面共通
        dataSptProcess['update_date'] = getCurrentDate()
        const dateStr = dateFormToData(dataSptProcess['enjo_date'])
        //改行コードを置換
        const replacedCode = replaceLineCode(dataSptProcess['enjo_keika_naiyo'])
        //既存データを照合
        const index = dataSptProcess['enjokeika_list'].findIndex((ele) => ele['enjo_no'] === number)
        if (shori_div === '0') {
            const enjoData = {}
            enjoData['enjo_no'] = cloneData['enjo_no']
            enjoData['enjo_date'] = dateStr
            enjoData['enjo_keika_naiyo'] = replacedCode
            enjoData['enjo_display'] = true
            dataSptProcess['enjokeika_list'] = [enjoData]
        } else if (shori_div === '1') {
            const enjoData = dataSptProcess['enjokeika_list'][index]
            enjoData['enjo_date'] = dateStr
            enjoData['enjo_keika_naiyo'] = replacedCode
            enjoData['enjo_display'] = true
            // console.log(enjoData)
            dataSptProcess['enjokeika_list'] = [enjoData]
        } else if (shori_div === '2') {
            const enjoData = { enjo_no: number, enjo_display: true }
            dataSptProcess['enjokeika_list'] = [enjoData]
        }
        delete dataSptProcess['enjo_no']
        delete dataSptProcess['enjo_date']
        delete dataSptProcess['enjo_keika_naiyo']
        // dataSptProcess['enjokeika_list'].forEach((v, i) => {
        //     const dateStr = dateFormToData(dataSptProcess['enjokeika_list'][i]['enjo_date'])
        //     dataSptProcess['enjokeika_list'][i]['enjo_date'] = dateStr
        //     const replacedCode = replaceLineCode(dataSptProcess['enjokeika_list'][i]['enjo_keika_naiyo'])
        //     dataSptProcess['enjokeika_list'][i]['enjo_keika_naiyo'] = replacedCode
        // })
        if (shori_div === '0' || shori_div === '1') {
            console.log('disp40 SendData', dataSptProcess)
            const response = postToCf(dataSptProcess)
            feedBackDisplay(response, setShowModal)
        } else if (shori_div === '2') {
            const deleteData = () => {
                setShowDialog({ ...showDialog, show: false })
                console.log('disp40 SendData', dataSptProcess)
                const response = postToCf(dataSptProcess)
                feedBackDisplay(response, setShowModal)
            }
            const hideDialog = () => {
                setShowDialog({ ...showDialog, show: false })
            }
            setShowDialog({
                ...showDialog,
                show: true,
                message: 'このデータを削除します。\nよろしいですか？',
                doYes: deleteData,
                doNo: hideDialog,
                textYes: 'はい',
                textNo: 'いいえ',
            })
        }
    }
    return (
        <div className='w-full flex flex-col items-center'>
            <Modal showModal={showModal} />
            <Dialog showDialog={showDialog} />
            <FormProvider {...methods}>
                <form className={style.form} name='SupportProcessForm' onKeyDown={(e) => checkKeyDown(e)}>
                    <div className={style.container}>
                        <h2 className={style.title}>支援経過</h2>
                        <SupportProcessInput />
                    </div>
                    <div className={style.ButtonContainer}>
                        {/* <Button type='button' value='リセット' onClick={resetText} outline={true} /> */}
                        {number === 'add' &&
                            (sendable.send ? (
                                <Button
                                    type='submit'
                                    onClick={methods.handleSubmit((data) => {
                                        onSubmit(data, '0')
                                    })}
                                    value='追加'
                                />
                            ) : (
                                <Button type='button' value='追加' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />
                            ))}
                        {number !== 'add' &&
                            (sendable.send ? (
                                <Button
                                    type='submit'
                                    onClick={methods.handleSubmit((data) => {
                                        onSubmit(data, '1')
                                    })}
                                    value='送信'
                                />
                            ) : (
                                <Button type='button' value='送信' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />
                            ))}
                        {number !== 'add' &&
                            (sendable.send ? (
                                <Button
                                    type='submit'
                                    onClick={methods.handleSubmit((data) => {
                                        onSubmit(data, '2')
                                    })}
                                    outline={true}
                                    value='削除'
                                />
                            ) : (
                                <Button type='button' value='削除' color='gray-400' onClick={() => unSendable(sendable.reasonText)} />
                            ))}
                        <Button type='button' value='キャンセル' onClick={backToShowDetail} outline={true} />
                    </div>
                </form>
            </FormProvider>
            {/* <DevTool control={control} /> */}
        </div>
    )
}
